import React, { useState, useMemo } from 'react';
// import './MultiCalendar.css';
import '../index.css';

const MultiCalendar = ({ onSelectDateRange, onClose }) => {
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [monthModalVisible, setMonthModalVisible] = useState(false);
    const [yearModalVisible, setYearModalVisible] = useState(false);

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];

    const totalDays = useMemo(() => daysInMonth(currentMonth, currentYear), [currentMonth, currentYear]);
    const firstDay = useMemo(() => firstDayOfMonth(currentMonth, currentYear), [currentMonth, currentYear]);

    function daysInMonth(month, year) {
        return new Date(year, month + 1, 0).getDate();
    }

    function firstDayOfMonth(month, year) {
        return new Date(year, month, 1).getDay();
    }

    const daysArray = [];
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const dayHeaders = dayNames.map((dayName, index) => (
        <div key={index} className="MC-dayHeader">{dayName}</div>
    ));

    for (let i = 1; i <= totalDays + firstDay; i++) {
        if (i <= firstDay) {
            daysArray.push(null);
        } else {
            daysArray.push(i - firstDay);
        }
    }

    const calendarDays = daysArray.map((day, index) => {
        const date = new Date(currentYear, currentMonth, day);
        const isSelectedFrom = selectedFromDate && date.getTime() === selectedFromDate.getTime();
        const isSelectedTo = selectedToDate && date.getTime() === selectedToDate.getTime();
        const isInRange = selectedFromDate && selectedToDate && date > selectedFromDate && date < selectedToDate;
        const isFutureDate = date > new Date();

        return (
            <div
                key={index}
                className={`MC-day ${isSelectedFrom ? 'MC-selectedFromDay' : ''} ${isSelectedTo ? 'MC-selectedToDay' : ''} ${isInRange ? 'MC-inRangeDay' : ''}`}
                onClick={() => handleDateSelection(day)}
            >
                <span className={`MC-dayText ${isFutureDate ? 'MC-futureDayText' : ''}`}>{day}</span>
            </div>
        );
    });

    const handleDateSelection = (day) => {
        const selected = new Date(currentYear, currentMonth, day);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (selected > currentDate) {
            return;
        }

        if (!selectedFromDate || (selectedFromDate && selectedToDate)) {
            setSelectedFromDate(selected);
            setSelectedToDate(null);
        } else if (selected < selectedFromDate) {
            setSelectedFromDate(selected);
        } else {
            setSelectedToDate(selected);
        }
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const handleOk = () => {
        let from, to;
        if (!selectedToDate) {
            from = to = formatDate(selectedFromDate);
        } else {
            from = formatDate(selectedFromDate);
            to = formatDate(selectedToDate);
        }
        onSelectDateRange(from, to);
    };

    const goToPreviousMonth = () => {
        setCurrentMonth(prevMonth => (prevMonth === 0 ? 11 : prevMonth - 1));
        setCurrentYear(prevYear => currentMonth === 0 ? prevYear - 1 : prevYear);
    };

    const goToNextMonth = () => {
        setCurrentMonth(prevMonth => (prevMonth === 11 ? 0 : prevMonth + 1));
        setCurrentYear(prevYear => currentMonth === 11 ? prevYear + 1 : prevYear);
    };

    const handleModalClose = () => {
        onClose();
    };

    const handleMonthSelect = (month) => {
        setCurrentMonth(month);
        setMonthModalVisible(false);
    };

    const handleYearSelect = (year) => {
        setCurrentYear(year);
        setYearModalVisible(false);
    };

    return (
        <div className="MC-container">
            <h2 className="MC-heading">Select From and To Date</h2>
            <div className="MC-header">
                <button onClick={goToPreviousMonth} className="MC-arrowButton">{'<'}</button>
                <button onClick={() => setMonthModalVisible(true)} className="MC-headerText">{monthNames[currentMonth]}</button>
                <button onClick={() => setYearModalVisible(true)} className="MC-headerText">{currentYear}</button>
                <button onClick={goToNextMonth} className="MC-arrowButton">{'>'}</button>
                <button onClick={handleModalClose} className="MC-closeButton">
                    <img src="/assets/close.png" alt="close" className="MC-closeIcon" />
                </button>
            </div>
            <div className="MC-dayHeadersRow">{dayHeaders}</div>
            <div className="MC-calendar">{calendarDays}</div>
            <button
                className={`MC-okButton ${!selectedFromDate ? 'MC-disabledButton' : ''}`}
                onClick={handleOk}
                disabled={!selectedFromDate}
            >
                OK
            </button>

            {/* Month Modal */}
            {monthModalVisible && (
                <div className="MC-modalOverlay" onClick={() => setMonthModalVisible(false)}>
                    <div className="MC-modalContent" onClick={e => e.stopPropagation()}>
                        <button onClick={() => setMonthModalVisible(false)} className="MC-modalCloseButton">
                            <img src="/assets/close.png" alt="close" className="MC-closeIcon" />
                        </button>
                        <ul>
                            {monthNames.map((month, index) => (
                                <li key={index} onClick={() => handleMonthSelect(index)} className="MC-modalText">{month}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {/* Year Modal */}
            {yearModalVisible && (
                <div className="MC-modalOverlay" onClick={() => setYearModalVisible(false)}>
                    <div className="MC-modalContent" onClick={e => e.stopPropagation()}>
                        <button onClick={() => setYearModalVisible(false)} className="MC-modalCloseButton">
                            <img src="/assets/close.png" alt="close" className="MC-closeIcon" />
                        </button>
                        <ul>
                            {Array.from({ length: 10 }, (_, i) => currentYear - 9 + i).map(year => (
                                <li key={year} onClick={() => handleYearSelect(year)} className="MC-modalText">{year}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiCalendar;
