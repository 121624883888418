
import React,{useState} from 'react';
import useNFPRegistrationController from '../Controllers/AuthenticationPages/NFPRegistrationController';


const NFPBulkRegistration = () => {
    const {
        
    
        handleFileUpload,
        excelFileData,
        uploadingExcelData,
        bulkMenuDivLoader,
        DataFromEventEmitter,
        error
    
        
      } = useNFPRegistrationController();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const onFileChange2 = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to array
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      };
    
      const removeFile = (indexToRemove) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
      };
    
      const onFileUpload = () => {
        if (selectedFiles.length > 0) {
          const formData = new FormData();
          selectedFiles.forEach((file, index) => formData.append(`file${index}`, file)); // Append each file
          for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value.name}`);
          }
    
          handleFileUpload(formData); // Call the upload function with the FormData
        } else {
          alert('Please select at least one file to upload.');
        }
      };
    
      const onFileChange = async (event) => {
        const files = Array.from(event.target.files); // Get the list of selected files
        setSelectedFiles(files)
        if (files.length > 0) {
    
          for (const file of files) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
    
            if (fileExtension === 'csv') {
              const data = await parseCSVFile(file);
              if (data) {
                await DataFromEventEmitter(data);
              }
            } else {
              alert(`Unsupported file format: ${file.name}`);
            }
          }
    
    
        }
    
    
        // for Multiple File one Api Service calling
        // const files = Array.from(event.target.files);
        // if (files.length > 0) {
        //   setBulkMenuDivLoader(true);
    
        //   let aggregatedData = [];
    
        //   for (const file of files) {
        //     const fileExtension = file.name.split('.').pop().toLowerCase();
    
        //     if (fileExtension === 'csv') {
        //       const data = await parseCSVFile(file);
        //       if (data) {
        //         aggregatedData = [...aggregatedData, ...data];
        //       }
        //     } else {
        //       alert(`Unsupported file format: ${file.name}`);
        //     }
        //   }
    
        //   if (aggregatedData.length > 0) {
        //     await DataFromEventEmitter(aggregatedData);
        //   }
    
        //   setBulkMenuDivLoader(false);
        // }
      };
    
      // Function to read the Excel file and trigger processing
      const parseCSVFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const text = e.target.result;
            const rows = text.split('\n').filter(row => row.trim() !== '');
            const headers = rows[0].split(',').map(header => header.trim());
    
            const jsonData = rows.slice(1).map(row => {
              const values = row.split(',').map(value => value.trim());
              let entry = {};
              headers.forEach((header, index) => {
                entry[header] = values[index] !== undefined ? values[index] : '';
              });
              return entry;
            });
    
            resolve(jsonData);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
        });
      };

  return (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // Full viewport height
        backgroundColor: '#f0f2f5' // Light background for contrast
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          padding: '20px', 
          backgroundColor: '#f8f9fa', 
          borderRadius: '8px', 
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)' 
        }}>
          <label style={{ 
            fontSize: '16px', 
            fontWeight: 'bold', 
            marginRight: '0px' 
          }}>Upload Bulk NFP'S:</label>
          
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
            <label htmlFor="file-upload" style={{
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              borderRadius: '5px',
              cursor: 'pointer',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              marginRight: '20px',
              display: 'inline-block',
              transition: 'background-color 0.3s ease'
            }}>
              Choose Files
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".csv"
              multiple
              onChange={onFileChange}
              style={{ display: 'none' }} // Hide the default input
            />
            <div style={{
              fontSize: '14px',
              color: selectedFiles ? '#28a745' : '#6c757d',
              fontStyle: selectedFiles ? 'normal' : 'italic'
            }}>
              {selectedFiles ? `${selectedFiles.length} file(s) selected` : 'No files chosen'}
            </div>
            {error && <p className="error-text">{error}</p>}
          </div>
        </div>
      </div>
      
      

  );
};

export default NFPBulkRegistration;