import React from 'react';
import { useNavigate } from 'react-router-dom';
import useNFPSuperAdminController from '../Controllers/NFPSuperAdminController';
import SAHeaderNavBar from '../../Components/SAHeaderNavBar';
import Loader from '../../Components/Loader';
import { useState } from 'react';
import Footer from '../../Components/Footer';

const NFPSuperAdminScreen = () => {
  const navigate = useNavigate();
  const [isBannerFormVisible, setBannerFormVisible] = useState(false);
  const {
    loading,
    error,
    nfpData,
    selectedStatus,
    handleStatusChange,
    handleApprovePress,
    success,
    statusLoader,
    activeIndex,
    handleDetailNFP,
  } = useNFPSuperAdminController(navigate);

  const [bannerData, setBannerData] = useState({
    nfp: '',
    title: '',
    description: '',
  });

  const handleBannerChange = (field, value) => {
    setBannerData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleBannerSubmit = () => {
    // Implement banner submission logic here
    console.log('Banner Data:', bannerData);
    setBannerFormVisible(false);
  };

  const renderNFPList = (nfpList) => {
    if (nfpList.length === 0) {
      return <p className="error-text">No Data Available</p>;
    }
    return nfpList.map((nfp, index) => (
      <div key={index} className="info-box">
        <div className="info-details" onClick={() => handleDetailNFP(nfp, selectedStatus)}>
          <h3>{nfp.organization_name}</h3>
          <p>Location: {nfp.address}</p>
        </div>
        <button
          className="approve-button"
          style={{ display: 'flex', flexDirection: 'row' }}
          onClick={() => handleApprovePress(nfp, selectedStatus, index)}
        >
          {selectedStatus === 'new' ? 'Approve' : selectedStatus === 'active' ? 'InActive' : 'Active'}
          {statusLoader && activeIndex === index && <div className="loader-s"></div>}
        </button>
      </div>
    ));
  };

  const renderFeedbackContent = () => {
    navigate('/feedback-list');
  };

  return (
    <div className='nfp-admin-screen'>
      <SAHeaderNavBar />
      <div className="header-container" style={{ marginBottom: '20px' }}>
        <div className="tabs-container" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            {['new', 'active', 'inactive'].map((status) => (
              <button
                key={status}
                className={`tab ${selectedStatus === status ? 'selected-tab' : ''}`}
                onClick={() => handleStatusChange(status)}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </button>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center',marginRight:'25px' }}>
            <h3 >Register New NFP</h3>
            <button
              style={{
                marginLeft: '10px',
                backgroundColor: '#1e90ff', 
                width: '22px', 
                height: '22px', 
                borderRadius: '50%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                color: '#fff', 
                fontSize: '24px', 
                fontWeight: 'bold', 
                cursor: 'pointer',
              }}
              onClick={() => navigate('/registration')}
            >
              +
            </button>
          </div>
        </div>
      </div>
      
      <div className="content-container">
        {loading && <div className="loader-large"></div>}
        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}
        {!loading && !error && selectedStatus !== 'feedback' && renderNFPList(nfpData[selectedStatus])}
        {!loading && !error && selectedStatus === 'feedback' && renderFeedbackContent()}
      </div>
      <Footer />
    </div>
  );
};

export default NFPSuperAdminScreen;
