import { useState, useEffect } from 'react';

import { FEEDBACK_API_URL } from '../../ApiServices/BaseURL';
import { FeedBackModel } from '../Models/FeedBackModel';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';
import { postDataLogin } from '../../ApiServices/ApiService';

const useFeedBackController = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [feedbackData, setFeedbackData] = useState([]);
  const screenName = "FeedBackScreen";

  const fetchFeedbackData = async () => {
    setLoading(true);
    setError('');
    try {
      const payload = { city: "Memphis" }; // Update this payload as per your requirements
      const response = await postDataLogin(FEEDBACK_API_URL, payload);
      if (response.statusCode === 200 && response.message.toLowerCase() === 'success') {
        const data = response.dataJ.map(item => new FeedBackModel(item));
        setFeedbackData(data);
      } else {
        setError(response.info || 'Failed to fetch feedback data.');
      }
    } catch (error) {
      setError('Error fetching feedback data.');
    //   ExceptionSendService(screenName, 'Feedback-101', error.message || 'Error fetching feedback data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  return {
    loading,
    error,
    feedbackData,
  };
};

export default useFeedBackController;
