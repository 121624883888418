import React from 'react';
import useResetPasswordController from '../../Controllers/AuthenticationPages/ResetPasswordController';
import TextArea from '../../../Components/LoginInput';
import Loader from '../../../Components/Loader';
// import './ResetPasswordScreen.css';

const ResetPasswordScreen = () => {
  const {
    email,
    tempPassword,
    newPassword,
    confirmPassword,
    error,
    loading,
    showResetPassword,
    showPassword,
    showConfirmPassword,
    handleTempPasswordChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    toggleShowResetPassword,
    toggleShowPassword,
    toggleShowConfirmPassword,
    resetPassword,
  } = useResetPasswordController();

  return (
    <div className="safe-area2">
      <div className="container">
        <img
          src="https://assets.api.uizard.io/api/cdn/stream/ade0f9fc-17ba-4731-a16a-724ba2bbb048.png"
          alt="Logo"
          className="image"
          style={{ height: 200, width: 200 }}
        />
        <h3 className="title">Reset Password</h3>
        <div className="input-container">
          <TextArea
            placeholder="Email"
            value={email}
            readOnly
            className="input"
          />
          <TextArea
            placeholder="Temporary Password"
            value={tempPassword}
            onChange={(e) => handleTempPasswordChange(e.target.value)}
            type={showResetPassword ? "text" : "password"}
            className="input"
            right={
              <button onClick={toggleShowResetPassword} className="toggle-button">
              {showResetPassword ? (
                <img src={require('../../../Assets/remove-eye.png')} alt="Hide " />
              ) : (
                <img src={require('../../../Assets/eye.png')} alt="Show " />
              )}
            </button>
            }
          />
          <TextArea
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => handleNewPasswordChange(e.target.value)}
            type={showPassword ? "text" : "password"}
            className="input"
            right={
              <button onClick={toggleShowPassword} className="toggle-button">
              {showPassword ? (
                <img src={require('../../../Assets/remove-eye.png')} alt="Hide " />
              ) : (
                <img src={require('../../../Assets/eye.png')} alt="Show " />
              )}
            </button>
            }
          />
          <TextArea
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            type={showConfirmPassword ? "text" : "password"}
            className="input"
            right={
              <button onClick={toggleShowConfirmPassword} className="toggle-button">
              {showPassword ? (
                <img src={require('../../../Assets/remove-eye.png')} alt="Hide " />
              ) : (
                <img src={require('../../../Assets/eye.png')} alt="Show " />
              )}
            </button>
            }
          />
          {error && <p className="error-text">{error}</p>}
        </div>
        <button className="submit-button" onClick={resetPassword} style={{ maxWidth: 450, marginLeft: 50 }}>
          Submit
        </button>
        <Loader loading={loading}  color={'#FFFFFF'} />
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
