import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import farever from './../Assets/farever.png';

const HeaderNavBarSignup = () => {
  const navigate = useNavigate();





  const gotoInventory = () => {
    navigate('/login');
  };


  return (
    <>
      <Navbar expand="lg" className="nav-background-login pad-4" style={{ padding: 4 }}>
        <Container fluid className='nav-container'>
          <img src={farever} className='nav-logos' alt="Farever Logo"/>
          <Navbar.Brand className='nav-heading' style={{ cursor: 'pointer' ,color: 'black',fontSize: 23,fontWeight: 700,paddingLeft: 10}}>
            FAREVER
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll" className='display-flex-nav'>
            <Nav className="mx-auto Links" style={{ maxHeight: '100px' }} >
              <Nav.Link className="" style={{ color:"black",fontWeight:'700' }}  onClick={gotoInventory}>  <span>Login</span> </Nav.Link>
              {/* <Nav.Link className="navheader-toggles-styling" onClick={gotoInventory}>  <span>Inventory</span> </Nav.Link> */}
            </Nav>
     

            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderNavBarSignup;
