import React from 'react';

const CustomCheckBox = ({ isChecked, onClick, label }) => {
  const checkboxStyle = {
    width: '16px', /* Reduced size of the checkbox */
    height: '16px',
    border: '1px solid #ccc',
    borderRadius: '3px', /* Slightly rounded corners */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px', /* Reduced space between checkbox and label */
    cursor: 'pointer',
    backgroundColor: isChecked ? '#007bff' : 'transparent', /* Background color for checked state */
    borderColor: isChecked ? '#007bff' : '#ccc' /* Border color for checked state */
  };

  const checkmarkStyle = {
    color: 'white',
    fontSize: '12px' /* Adjust size of the checkmark */
  };

  const labelStyle = {
    fontSize: '14px',
    color: '#333', /* Adjust label color */
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginRight: '15px', marginBottom: '10px' }} // Container style
      onClick={onClick}
    >
      <div style={checkboxStyle}>
        {isChecked && <span style={checkmarkStyle}>✔</span>}
      </div>
      <span style={labelStyle}>{label}</span>
    </div>
  );
};

export default CustomCheckBox;
