import { useState } from 'react';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { SIGNUP_API } from '../../../ApiServices/BaseURL';
import { validateEmail } from '../../../utils/validation';
import { useNavigate } from 'react-router-dom';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';

const LoginController = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const screenName = 'LoginPage'

  const navigate = useNavigate();

  const handleEmailChange = (text) => {
    console.log('text to enter')
    setEmail(text);
    if (!validateEmail(text)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };



  const login = async () => {
    try {
      setEmailError('');
      setError('');
      if (!email) {
        setError('Please Enter Email');
        return;
      }
      if (!new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
        setError('Please Enter Valid Email');
        return;
      }
      if (!password) {
        setError('Please Enter Password');
        return;
      }
      const payLoad = {
        "email_id": email,
        "password": password
      }
      console.log('Login Payload:', payLoad);
      setLoading(true);
      // return
      await postDataLogin(SIGNUP_API,payLoad).then(response => {
        setLoading(false);
        if (response.statusCode === 200) {
          if (response.message === 'success') {
            if (response.info === 'Change password and Login again') {
              navigate('/change-password', {state: {email: email, tempPassword: password} });
            } else {
              let userData = JSON.stringify(response.dataJ[0])
               localStorage.setItem('_FAREVER_ADMIN_', userData);
              const userRole = response.dataJ[0]?.user_role;
              const type = response.dataJ[0]?.type
              console.log("UserRole", userRole)
              console.log("Type", type)
               if (userRole === 'nfp') {
                if (type === 'admin' || type === 'user') {
                  console.log("Type if", type)
                  navigate('/dashboard')
                } else {
                  console.log("Type else", type)
                  navigate('/superadmin-dashboard');
                }
  
              } else {
                console.log("userRole  else", userRole)
                // navigation.replace('ProfileScreen');
              }
  
            }
          } else {
            setError(response.info);
          //   ExceptionSendService(screenName, 'log-101', response.info)
          }
  
        } else if (response.statusCode === 500) {
          setError(response.info);
          // ExceptionSendService(screenName, 'log-102', response.info)
  
        } else {
          setError("No Internet Connection Detected Or Connection Is Slow");
          // ExceptionSendService(screenName, 'log-103', 'No Responce')
        }
      })
   
    } catch (error) {
      setLoading(false);
      let err = String(error)
    //   ExceptionSendService(screenName, 'log-104', err)
      setError("No Internet Connection Detected Or Connection Is Slow !");
    }


  };

  return {
    email,
    password,
    emailError,
    error,
    showPassword,
    loading,
    setEmail,
    setPassword,
    setEmailError,
    setError,
    toggleShowPassword,
    handleEmailChange,
    handlePasswordChange,
    login
  };
};

export default LoginController;
