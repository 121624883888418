import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validatePassword, validateTempPassword } from '../../../utils/validation';
import { RESET_PASSWORD_API } from '../../../ApiServices/BaseURL';
import { postDataLogin } from '../../../ApiServices/ApiService';
// import axios from 'axios';
// import ExceptionSendService from '../../ApiServices/ExceptionSendService';

const useResetPasswordController = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const screenName = 'ResetPassword';

  const handleTempPasswordChange = (text) => {
    setTempPassword(text);
  };

  const handleNewPasswordChange = (text) => {
    setNewPassword(text);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
  };

  const toggleShowResetPassword = () => {
    setShowResetPassword(!showResetPassword);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const resetPassword = async () => {
    try {
      setError('');
      if (!validateTempPassword(tempPassword)) {
        setError('Reset Code must be 6 or 12 characters.');
        return;
      }
      if (!validatePassword(newPassword)) {
        setError('Password must be at least 8 characters long and include a number, an uppercase letter, and a special character.');
        return;
      }
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      const payLoad = {
        "email_id": email,
        "new_password": newPassword,
        "temp-code": tempPassword
      };
      setLoading(true);
      const response = await postDataLogin(RESET_PASSWORD_API, payLoad);
    //   const response = responseData.data;
      setLoading(false);
      if (response.statusCode === 200) {
        if (response.message === 'success') {
          navigate('/login');
        } else {
          setError(response.info);
        //   ExceptionSendService(screenName, 'ResetPass-101', response.info);
        }
      } else if (response.statusCode === 500) {
        setError(response.info);
        // ExceptionSendService(screenName, 'ResetPass-102', response.info);
      } else {
        setError("Error While Getting Response,Please Contact Admin");
        // ExceptionSendService(screenName, 'ResetPass-103', 'Error While Getting Response,Please Contact Admin');
      }
    } catch (error) {
      setLoading(false);
      const err = String(error);
    //   ExceptionSendService(screenName, 'ResetPass-104', err);
      setError("No Internet Connection Detected Or Connection Is Slow!");
    }
  };

  return {
    email,
    tempPassword,
    newPassword,
    confirmPassword,
    error,
    showResetPassword,
    showPassword,
    showConfirmPassword,
    loading,
    handleTempPasswordChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    toggleShowResetPassword,
    toggleShowPassword,
    toggleShowConfirmPassword,
    resetPassword,
  };
};

export default useResetPasswordController;
