import React, { useState, useEffect } from 'react';
import useDonationListController from '../Controllers/DonationListController';
import HeaderNavBar from '../../Components/HeaderNavBar';
import MultiCalendar from '../../Components/MultiCalendar';
import Footer from '../../Components/Footer';
// import CustomFooter from '../../components/CustomFooter';
// import './DonationListScreen.css'; // Import the CSS file for styling
import Loader from '../../Components/Loader';
import LeftArrow from '../../Assets/upload.png'
import callIcon from '../../Assets/icons/phone-call-icon.png';
import emailIcon from '../../Assets/icons/email-icon-blue.png';

import donarIcon from '../../Assets/icons/user-donar-yellow-icon.png';
import addItemIcon from '../../Assets/icons/add-item-yellow.png';

const DonationListScreen = ({ navigation }) => {
  const [nfpId, setNfpId] = useState('');
  const [dateType, setDateType] = useState('TODAY');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [expandedSection, setExpandedSection] = useState('Food'); // Default expand Food category

  useEffect(() => {
    const getNfpDetails = async () => {
      try {
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        setNfpId(admindetailsParsed.nfp_id);
      } catch (error) {
        console.error('Failed to retrieve nfp_id from localStorage:', error);
      }
    };

    getNfpDetails();
  }, []);

  const { loading, error, donations, fetchDonations, setError } = useDonationListController(nfpId, dateType, fromDate, toDate);

  const handleDateTypeChange = (type) => {
    setDateType(type);
    setDropdownVisible(false);
    setError('');
    if (type !== 'CUSTOM') {
      setFromDate('');
      setToDate('');
      fetchDonations(nfpId, type, '', '');
      setExpandedSection('Food');
      console.log("dateType", type);
    } else {
      setModalVisible(true);
      setExpandedSection('Food');
      console.log("not dateType", type);
    }
  };

  const handleDateSelection = (from, to) => {
    setFromDate(from);
    setToDate(to);
    setModalVisible(false);
  };

  const toggleSection = (section) => {
    setError('');
    setExpandedSection(prevSection => (prevSection === section ? '' : section));
  };

  const handlePhonePress = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailPress = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const formatDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [month, day, year] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
    const isPM = hours >= 12;
    const formattedHours = hours % 12 || 12; // Convert "00" hours to "12"

    return `${month}-${day}-${year} ${formattedHours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
  };

  const renderDonationItem = (item, index) => (
    <div key={index} className="info-box" >
      <div className="info-details" style={{ marginLeft: 10, width: '100%' }}>
        {item?.donar_name && <p >{`Donor Name: ${item.donar_name}`}</p>}
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
          <div>
            {item.email_id && (
              <button className="contact-container" onClick={() => handleEmailPress(item.email_id)}>
                <img src={emailIcon} alt="email icon" className="contact-icon" />
                <span className="contact-text">{item.email_id}</span>
              </button>
            )}
          </div>
          <div>
            {item.phone && (
              <button className="contact-container" onClick={() => handlePhonePress(item.phone)}>
                <img src={callIcon} alt="call icon" className="contact-icon" />
                <span className="contact-text">{item.phone}</span>

              </button>
            )}
          </div>
        </div>


        <div className='row-flex' style={{ margin: 0, padding: 2 }}>
          {item.item_name && <p className="text" style={{ margin: 0 }}>{`Item: ${item.item_name}`}</p>}
          {item.qty && <p className="" style={{ margin: 0 }}>{`Quantity: ${item.qty}`}</p>}
        </div>
        <div className='row-flex' style={{ margin: 0, padding: 2 }}>
          {item.size && <p className="" style={{ margin: 0 }}>{`Size: ${item.size}`}</p>}
          {item.delivery_mode && <p className="" style={{ margin: 0 }}>{`Delivery Mode: ${item.delivery_mode}`}</p>}
        </div>
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: 10 }}>
          {item.donation_date && <p className="" style={{ margin: 0 }}>{`Date: ${formatDateTime(item.donation_date)}`}</p>}
          {item.sub_category && <p className="" style={{ margin: 0 }}>{`Sub Category: ${item.sub_category}`}</p>}
        </div>

      </div>
      {/* <div className="info-details">
        {item.email_id && (
          <button className="contact-container" onClick={() => handleEmailPress(item.email_id)}>
            <img src={emailIcon} alt="email icon" className="contact-icon" />
            <span className="contact-text">{item.email_id}</span>
          </button>
        )}
      </div>
      <div className="info-details">
        {item.phone && (
          <button className="contact-container" onClick={() => handlePhonePress(item.phone)}>
            <img src={callIcon} alt="call icon" className="contact-icon" />
            <span className="contact-text">{item.phone}</span>
          </button>
        )}
      </div>
      <div className="info-details">
        {item.item_name && <p className="text" style={{padding:0}}>{`Item: ${item.item_name}`}</p>}
        {item.qty && <p className="text" style={{padding:0}}>{`Quantity: ${item.qty}`}</p>}
      </div>
      <div className="info-details">
        {item.size && <p className="text">{`Size: ${item.size}`}</p>}
        {item.delivery_mode && <p className="text">{`Delivery Mode: ${item.delivery_mode}`}</p>}
      </div>
      {item.donation_date && <p className="text">{`Date: ${formatDateTime(item.donation_date)}`}</p>}
      {item.sub_category && <p className="text">{`Sub Category: ${item.sub_category}`}</p>} */}
    </div>
  );

  const renderSection = (section) => {
    const sectionData = donations[section];

    return (
      <div key={section} style={{ width: '100%' }}>
        <button className="section-header" onClick={() => toggleSection(section)}>
          <span className="section-header-text">{section}</span>
          <img
            src={LeftArrow}
            alt="dropdown icon"
            className={`dropdown-icon ${expandedSection === section ? 'rotated' : ''}`}
          />
        </button>
        {expandedSection === section && (
          <div>
            {sectionData?.length > 0 ? (
              sectionData.map((item, index) => renderDonationItem(item, index))
            ) : (
              <p className="no-data-text">No Data Available</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const dateOptions = [
    { label: 'Today', value: 'TODAY' },
    { label: 'Yesterday', value: 'YESTERDAY' },
    { label: 'This Week', value: 'THIS_WEEK' },
    { label: 'This Month', value: 'THIS_MONTH' },
    { label: 'This Year', value: 'THIS_YEAR' },
    { label: 'Last 7 Days', value: 'LAST_7_DAYS' },
    { label: 'Last 30 Days', value: 'LAST_30_DAYS' },
    { label: 'Last 90 Days', value: 'LAST_90_DAYS' },
    { label: 'Custom', value: 'CUSTOM' },
  ];

  const orderedSections = ['Food', 'Clothing&Supplies', 'Housing', 'Services'];

  return (
    <>
      <HeaderNavBar />
      <div className="safe-area2">
        {/* <HeaderNavBar title="Donation List" alignment="center" /> */}
        <div className='row'>
        <div className="col-md-6 title-headings">Manage Donations</div>
        <div className="col-md-6">
          <button className="dropdown-button" onClick={() => setDropdownVisible(!dropdownVisible)} style={{ justifyContent: 'space-between', minWidth: 180 }}>
            <span className="dropdown-button-text">{dateOptions.find(option => option.value === dateType).label}</span>
            <img
              src={LeftArrow}
              alt="dropdown icon"
              className={`dropdown-icon ${dropdownVisible ? 'rotated' : ''}`}
            />
          </button></div>
        </div>

        <div className="container">

          {dropdownVisible && (
            <div className="dropdown-menu">
              {dateOptions.map(option => (
                <button
                  key={option.value}
                  className="dropdown-menu-item"
                  onClick={() => handleDateTypeChange(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
          {/* </div> */}
          {dateType === 'CUSTOM' && fromDate && toDate && (
            <p className="selected-date-text">{`${fromDate} to ${toDate}`}</p>
          )}
          {loading ? (
            <Loader color="#FFFFFF" loading={loading} />
          ) : (
            <>
              {error ? (
                <p className="error-text">{error}</p>
              ) : (
                orderedSections.map((section) => renderSection(section))
              )}
            </>
          )}
        </div>
        {modalVisible && (
          <div className="modal-overlay" onClick={() => setModalVisible(false)}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <MultiCalendar onSelectDateRange={handleDateSelection} onClose={() => setModalVisible(false)} />
            </div>
          </div>
        )}
        {/* <CustomFooter /> */}
        <Footer></Footer>
      </div>
    </>
  );
};

export default DonationListScreen;
