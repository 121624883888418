import { useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props){

    const {Component} = props;
    const navigate = useNavigate();
    // const [storedData, setstoredData] = useState('');
    const storedData = useRef(null);

    useEffect( ()=>{
              // Retrieve data from localStorage when the component mounts
            //   setstoredData(localStorage.getItem('isAdminLogin'));
            storedData.current = JSON.parse(localStorage.getItem('_FAREVER_ADMIN_',{}));
            console.log("storedData",storedData.current)
              if (storedData.current) {
                if(storedData.current.type === 'super_admin'){
                  navigate('/superadmin-dashboard')
                }else{
                  navigate('/dashboard');
                }
              
              }else{
                navigate('/login');
              }
    },[storedData,navigate]);

    return(
        <div>
            <Component />
        </div>
    )

}

export default Protected