export class NFPRegistrationModel {
  constructor(
    organization_name,
    nfp_phone,
    donation_link,
    fr_member_since,
    register_by,
    registration_date,
    zipCode,
    nfp_loc_list, 
    nfp_program_list = [], 
    food_serving_hours
  ) {
    this.organization_name = organization_name;
    this.nfp_phone = nfp_phone;
    this.donation_link = donation_link;
    this.fr_member_since = fr_member_since;
    this.register_by = register_by;
    this.registration_date = registration_date;
    this.zipCode = zipCode;
    this.nfp_loc_list = nfp_loc_list;
    this.nfp_program_list = nfp_program_list;
    this.food_serving_hours = food_serving_hours;
  }

  toNFPRegistrationPayload() {
    return {
      organization_name: this.organization_name,
      nfp_phone: this.nfp_phone,
      donation_link: this.donation_link,
      fr_member_since: this.fr_member_since,
      register_by: this.register_by,
      registration_date: this.registration_date,
      zipCode: this.zipCode,
      rating: 0, // Assuming rating is set to 0 for new registrations
      nfp_loc_list: this.nfp_loc_list.map(location => ({
        location_name: location.location_name,
        address: location.address,
        city: location.city,
        nfp_id: location.nfp_id || "",
        donating_hrs_list: location.donating_hrs_list || [],
        loc_id:location.loc_id ||''
      })),
      nfp_program_list: this.nfp_program_list.map(program => ({
        program_id: program.program_id || "",
        latitude: program.latitude,
        longitude: program.longitude,
        program_name: program.program_name,
        nfp_id: program.nfp_id || "",
        loc_id: program.loc_id || "",
        services_list: program.services_list,
        nfp_activities: program.nfp_activities || "", // Make sure this is included
        contact_person_details: program.contact_person_details,
        last_updated_by: program.last_updated_by || "",
        last_updated_food: program.last_updated_food || "",
        last_updated_housing: program.last_updated_housing || "",
        operating_hrs_list: program.operating_hrs_list,
      })),
      food_serving_hours: this.food_serving_hours
    };
  }
}
