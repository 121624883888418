
// controllers/NFPBannerController.js

import { useState, useEffect } from 'react';
import { getData,postDataLogin } from '../../ApiServices/ApiService'; // Assuming you have a GET API service
import { NFPBannerListModel } from '../Models/NFPBannerListModel';
import { NFP_STATUS_API, NFP_STATUS_UPDATE,BANNER_API} from '../../ApiServices/BaseURL';
import { useNavigate } from 'react-router-dom';

const useNFPBannerController = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [bannerList, setBannerList] = useState([]);
    const navigate = useNavigate();

    const fetchBannerData = async () => {
        setLoading(true);
        setError('');
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
            const admindetailsParsed = JSON.parse(admindetails);
            console.log("admindetailsParsed", admindetailsParsed)
            const email = admindetailsParsed.email_id;
            const city = admindetailsParsed.user_city;
        try {
          const payload = { "op": "cityBasedList", city:city};
          console.log("payload",payload)
          const response = await postDataLogin(BANNER_API, payload);
        //   const response = responseData?.data;
    
          if (response.statusCode === 200) {
            if (response.message.toLowerCase() === 'success') {
              const data = response.dataJ[0];
              const dataArray = response.dataJ && Array.isArray(response.dataJ) ? response.dataJ : [];
              console.log("data",data)
              setBannerList(dataArray)
            } else if (response.message.toLowerCase() === 'fail') {
              setError(response.info || 'Response fail');
            } else {
              setError('Unknown Exception');
            //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
            }
          } else if (response.statusCode === 500) {
            setError(response.info || 'Server error');
            // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
          } else {
            setError('Error While Getting Response,Please Contact Admin');
            // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
          }
        } catch (error) {
          setError('No Internet Connection Detected Or Connection Is Slow !');
          const err = String(error);
        //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
        } finally {
          setLoading(false);
        }
      };

      const handleBannerUpdate = async (bannerData) => {
        setLoading(true);
        setError('');
        if (!bannerData.loc_id || bannerData.loc_id === 'NA') {
            setError('Location ID is Missing');
            setLoading(false);
            return;
        }
    
        if (!bannerData.program_id || bannerData.program_id === 'NA') {
            setError('Program ID is Missing');
            setLoading(false);
            return;
        }
        const payload = {
            op: "bannerUpdate",
            banner_id: bannerData.banner_id,
            title: bannerData.title,
            description: bannerData.description,
            city: bannerData.city,
            nfp_id: bannerData.nfp_id,
            loc_id: bannerData.loc_id,
            program_id: bannerData.program_id,
            active: bannerData.active
        };
        try {
            console.log("payload",payload);
            const response = await postDataLogin(BANNER_API, payload);
          //   const response = responseData?.data;
      
            if (response.statusCode === 200) {
              if (response.message.toLowerCase() === 'success') {
                setSuccess(response.info)
                //   setTimeout(() => {
                    fetchBannerData();
                //   }, 1000);
              } else if (response.message.toLowerCase() === 'fail') {
                setError(response.info || 'Response fail');
              } else {
                setError('Unknown Exception');
              //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
              }
            } else if (response.statusCode === 500) {
              setError(response.info || 'Server error');
              // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
            } else {
              setError('Error While Getting Response,Please Contact Admin');
              // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
            }
          } catch (error) {
            setError('No Internet Connection Detected Or Connection Is Slow !');
            const err = String(error);
          //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
          } finally {
            setLoading(false);
          }
      };

    useEffect(() => {
        fetchBannerData();
    }, []);

    return {
        loading,
        error,
        bannerList,
        handleBannerUpdate,
        success
    };
};

export default useNFPBannerController;
