import { useState, useEffect, useContext } from 'react';
import { postDataLogin } from '../../ApiServices/ApiService';
import { User_REGISTRATION_API } from '../../ApiServices/BaseURL';
import { validateEmail } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import { userRegistrationModel } from '../Models/UserRegistrationModel';
import { useLocation } from 'react-router-dom';


const useUserRegistrationController = (navigation) => {
    const location = useLocation();
    const {nfp_id}=location.state || ''
    // console.log("nfp_id",nfp_id)

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [userData, setUserData] = useState({
        country_code: '+1',
        email_id: '',
        // gender_id,
        user_address: '',
        user_address2: '',
        user_city: '',
        user_name: '',
        user_phone: '',
        user_role: '',
        zip_code: '',
        type:'user'
    });

    const handleChange = (field, value) => {
        setUserData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleContactChange = (field, value) => {
        setUserData((prevData) => ({
            ...prevData,
            contact_person_details: [{
                ...prevData.contact_person_details[0],
                [field]: value,
            }],
        }));
    };

    const handleRolesOption = (event) => {
        const selectedValue = event.target.value;
        setUserData((prevData) => ({
            ...prevData,
            type: selectedValue, // Update the 'type' field with the selected value
        }));
    };




    const validateInputs = (userData) => {

        if (!userData.user_name) {
            setError('User Name is required');
            return false;
        }
        if (!userData.user_phone) {
            setError('User Phone is required');
            return false;
        }
        if (!userData.email_id) {
            setError('Email ID is required');
            return false;
        }
        if (!userData.user_address) {
            setError('User Address is required');
            return false;
        }
        // if (!userData.user_address2) {
        //     setError('User Address 2 is required');
        //     return false;
        // }
        if (!userData.user_city) {
            setError('User City is required');
            return false;
        }
        if (!userData.zip_code) {
            setError('Zip Code is required');
            return false;
        }
        if (!validateEmail(userData.email_id)) {
            setError('Please enter a valid email address');
            return false;
        }

        return true;
    };

    const handleSubmit = async (navigation) => {
        setError('');
        setSuccess('');
        if (!validateInputs(userData,)) return;
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        console.log("admindetailsParsed", admindetailsParsed)
        const NFP_ID = admindetailsParsed?.nfp_id;
        const programId =admindetailsParsed?.program_id || '';
        const locId=admindetailsParsed?.loc_id || '';
        const params = { nfp_id: NFP_ID,program_id:programId,loc_id:locId };
        console.log("NFP payload", params);
        const userPayload = new userRegistrationModel(
            userData.country_code,
            userData.email_id,
            // userData.gender,
            userData.user_address,
            userData.user_address2,
            userData.user_city,
            userData.user_name,
            userData.user_phone,
            userData.user_role,
            userData.zip_code,
            userData.type,
            NFP_ID,
            locId,
            programId
        );

        console.log('userPayload:', userPayload);
        // console.log('userPayload:', userPayload);
        // return

        setLoading(true);
        try {

            const response = await postDataLogin(User_REGISTRATION_API, userPayload);
            const userResponse = await response;
            if (userResponse.statusCode === 200) {
                if (userResponse.message === 'success') {
                    setLoading(false);
                    setSuccess('Registration successful');
                    setTimeout(() => {
                        
                            navigate('/dashboard');
                        
                    }, 1000);
                } else if (userResponse.message === 'fail') {
                    setLoading(false);
                    console.log("Else if", userResponse.info)
                    setError(userResponse.info || 'User registration failed');
                } else {
                    setLoading(false);
                    setError('Unknow Exception');
                    //   ExceptionSendService(screenName, 'regSubmit-101', response.info)
                }

            } else if (userResponse.statusCode === 500) {
                setError(response?.info)
                console.log('No Internet Connection Detected Or Connection Is Slow');
                setLoading(false);
                // ExceptionSendService(screenName, 'regSubmit-102', response.info)
            }
            else {
                setError('Error While Getting Response, Please Contact Admin')
                // ExceptionSendService(screenName, 'regSubmit-103', 'Error While Getting Response, Please Contact Admin')
                console.log('No Internet Connection Detected Or Connection Is Slow');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('No Internet Connection Detected Or Connection Is Slow catch', error);
            setError('No Internet Connection Detected Or Connection Is Slow');
            let err = String(error)
            //   ExceptionSendService(screenName, 'regSubmit-104', err)
        }
    };

    return {
        loading,
        error,
        success,
        userData,
        handleChange,
        handleContactChange,
        handleSubmit,
        handleRolesOption


    };
};

export default useUserRegistrationController;
