import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import farever from './../Assets/farever.png'


const SAHeaderNavBar = () => {
  const navigate = useNavigate();
  const aminInfo = localStorage.getItem('_FAREVER_ADMIN_',{});
  
  // console.log("aminInfo",aminInfo)


  const handleLogout = () => {

    console.log('logout');
    localStorage.clear();
    navigate('/login')
  }



  const gottoFeedback = () => {
    navigate('/feedback-list')
  }

  const gotoDashboard = () => {
    navigate("/superadmin-dashboard");
  }

  const gotoBanner = () => {
    navigate("/banner-list");
  }

  return (
    <>
      <Navbar expand="lg" className="nav-background pad-4" style={{ padding: 4 }}>
        <Container fluid className='nav-container'>
          <img src={farever} className='nav-logos' />
          <Navbar.Brand className='nav-heading' onClick={gotoDashboard} style={{ cursor: 'pointer' ,color: 'white',fontSize: 23,fontWeight: 700,paddingLeft: 10}}>Farever</Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="navbarScroll" className='navbar-icon-toggle'/> */}
          <Navbar.Collapse id="navbarScroll" className='display-flex-nav'>
            <Nav className="mx-auto Links" style={{ maxHeight: '100px' }} >
              <Nav.Link className="margin-right navheader-toggles-styling " onClick={gotoDashboard}>  <span>Dashboard</span> </Nav.Link>
              <Nav.Link className="margin-right navheader-toggles-styling " onClick={gotoBanner}>  <span>Banners</span> </Nav.Link>
              <Nav.Link className="margin-right navheader-toggles-styling " onClick={gottoFeedback}>  <span>Feedback</span> </Nav.Link>
            </Nav>

            <Nav className="" style={{ maxHeight: '100px', marginLeft: '20px' }} >
              <Nav className="Links" style={{ maxHeight: '100px', paddingRight: 20 }} >
                <Nav.Link className="margin-right navheader-toggles-styling" variant="dark" onClick={handleLogout}>  Logout</Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default SAHeaderNavBar;