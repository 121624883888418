import React from 'react';
import useFeedBackController from '../Controllers/FeedBackController';
import SAHeaderNavBar from '../../Components/SAHeaderNavBar';
import Footer from '../../Components/Footer';
import leftArrow from '../../Assets/icons/left-arrow-in-circular-icon.png'; // Assuming you have this icon

import userDonarIcon from '../../Assets/icons/user-donar-yellow-icon.png';

const FeedBackScreen = () => {
  const { loading, error, feedbackData } = useFeedBackController();

  const renderFeedbackList = () => {
    if (feedbackData.length === 0) {
      return <p className="error-text">No Feedback Available</p>;
    }
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
        {feedbackData.map((feedback, index) => (
          <div
            key={index}
            className="info-box"
            style={{
              flex: '0 1 calc(45% - 20px)', // Reduce the width to 45% minus the gap
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#fff'
            }}
          >
            <div className='info-details'>
              
            {feedback.name !== '' && (
              <h3 style={{ padding: 0, margin: 0, display: 'flex', alignItems: 'center' }}>
                <img 
                  src={userDonarIcon} 
                  alt="Icon" 
                  style={{ width: '26px', height: '26px', marginRight: '10px' }} 
                /> 
                {feedback.name}
              </h3>
            )}

                
              {feedback.email !== '' && <p style={{ padding: 0, margin: 0 }}><strong>Email:</strong> {feedback.email}</p>}
              <p style={{ padding: 0, margin: 0 }}><strong>City:</strong> {feedback?.city}</p>
               <p style={{ padding: 0, margin: 0 }}><strong>Commented On:</strong> {feedback?.feedback_date}</p>
              <p style={{ padding: 0, margin: 1 }}><strong>Comments:</strong> {feedback.comments}</p>
              <p style={{ padding: 0, margin: 0 }}><strong>Ratings:</strong> {feedback.ratings}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <SAHeaderNavBar />
      <div style={{ marginTop: 20 }}>
        {loading && <div className="loader-large"></div>}
        {error && <p className="error-text">{error}</p>}

        {!loading && !error && (
          <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
              {/* <img
                src={leftArrow}
                alt="Back"
                style={{ width: '30px', height: '30px', marginRight: '10px', cursor: 'pointer' }}
                onClick={() => window.history.back()} // Go back to the previous page
              /> */}
              <h3 style={{ margin: 0 }}>Manage Feedbacks</h3>
            </div>
            <div className="content-container">
              {renderFeedbackList()}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FeedBackScreen;
