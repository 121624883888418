// src/views/NFPPage/NFPInventoryListScreen.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useNFPInventoryListController from '../Controllers/NFPInventoryListController';
import HeaderNavBar from '../../Components/HeaderNavBar';
import Footer from '../../Components/Footer';
import foodIcon from '../../Assets/fork-and-spoon.png'
import clothesIcon from '../../Assets/casual-t-shirt-.png'
import housingIcon from '../../Assets/New-home.png'
import serviceIcon from '../../Assets/trolley.png'
import arrowIcon from '../../Assets/upload.png'
import medicalIcon from '../../Assets/first-aid-kit.png'
import jobIcon from '../../Assets/internship.png'
import hygieneIcon from '../../Assets/liquid-soap.png'
import defaultIcon from '../../Assets/icons/no-image-blue-icon.png'

const NFPInventoryListScreen = () => {
  const { groupedItems, loading, error } = useNFPInventoryListController();
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationName, latitude, longitude } = location.state || {};
  const [expandedCategories, setExpandedCategories] = useState({});
  const rotationAnim = useRef(0);
  console.log("groupedItems",groupedItems)

  useEffect(() => {
    const initialExpandedCategories = {};
    Object.keys(groupedItems).forEach(category => {
      initialExpandedCategories[category] = false;
    });
    setExpandedCategories(initialExpandedCategories);
  }, [groupedItems]);

  const toggleCategory = (category) => {
    setExpandedCategories((prevExpandedCategories) => ({
      ...prevExpandedCategories,
      [category]: !prevExpandedCategories[category],
    }));
    rotationAnim.current = !expandedCategories[category] ? 180 : 0;
  };

  const getCategoryImage = (category) => {
    switch (category.toLowerCase()) {
      case 'food':
        return foodIcon;
      case 'clothes&supplies':
        return clothesIcon;
      case 'clothes':
        return clothesIcon;
      case 'housing':
        return housingIcon;
      case 'services':
        return serviceIcon;
      case 'jobs & training':
        return jobIcon;
      case 'medical':
        return medicalIcon;
      case 'hygeine':
        return hygieneIcon;
      default:
        return defaultIcon;
    }
  };

  return (
    <>
      <HeaderNavBar />
      <div className="safe-area2">
        {loading &&  <div className="loader-fixed">
        <div className="spinner"></div>
      </div>}
        
      <div style={{ 
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center', 
  marginBottom: '20px',
  position: 'relative'
}}>
  <h2 className="title-headings" style={{ margin: 0 }}>Manage Inventory</h2>
  
  <div style={{ 
    display: 'flex', 
    alignItems: 'center', 
    position: 'absolute',
    right: 0 
  }}>
    <span 
      style={{ 
        color: '#1e90ff', 
        fontSize: '16px', 
        fontWeight: 'bold', 
        cursor: 'pointer',
        marginRight: '8px',
        textDecoration: 'underline',
      }} 
      onClick={() => navigate('/NFPInventoryAdd', { state: { latitude: latitude, longitude: longitude } })}
    >
      Add
    </span>
    <button 
      style={{ 
        backgroundColor: '#1e90ff', 
        width: '22px', 
        height: '22px', 
        borderRadius: '50%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        color: '#fff', 
        fontSize: '24px', 
        fontWeight: 'bold', 
        cursor: 'pointer',
        // marginLeft: '3px',
      }} 
      onClick={() => navigate('/NFPInventoryAdd', { state: { latitude: latitude, longitude: longitude } })}
    >
      +
    </button>
  </div>

</div>


        
        {error && <p className="error-text">{error}</p>}
        <div className="scroll-view">
          {Object.keys(groupedItems).map((category, index) => (
            <div key={index} className="category-container">
              <div className="category-header" onClick={() => toggleCategory(category)}>
                <img src={getCategoryImage(category)} className="category-image" alt={category} />
                <h3 className="category-title">{category}</h3>
                <img
                  src={arrowIcon}
                  className="arrow-image"
                  style={{ transform: `rotate(${expandedCategories[category] ? 180 : 0}deg)` }}
                  alt="toggle"
                />
              </div>
              {expandedCategories[category] && (
                <>
                  <div className="table-header" style={{color:"#3536b5",fontWeight:"bold",fontSize:"14px"}}>
                    <span className="item-name-header"> {category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? 'Program Name' : 'Item Name'}</span>
                    <span className="item-name-header"> {category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? 'Sub Category' : 'Sub Category'}</span>
                    <span className="quantity-header">{category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? 'Description' : 'Quantity'}</span>
                    <span className="action-header">Action</span>
                  </div>
                  {groupedItems[category].map((item, itemIndex) => (
                    <div
                      key={itemIndex}
                      className={`row ${itemIndex % 2 === 0 ? 'even-row' : 'odd-row'}`}
                    >
                      <span className="cell item-name-cell">{category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? item.program_name : item.item_name}</span>
                      <span className="cell item-name-cell">{item.sub_category || 'Sub Category Not Available'}</span>
                      <span className="cell quantity-cell">{category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? item.description : item.qty}</span>
                      <button
                        className="cell action-cell edit-button"
                        onClick={() => {
                          console.log("item", item, category, category);
                          navigate('/NFPInventoryUpdate', { state: { item, organizationName, category } });
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </div>
        
        <Footer />
      </div>
    </>
  );
};

export default NFPInventoryListScreen;
