export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  
  
  export const validatePassword = (password) => {
    // Password should be at least 8 characters long and include a number and a special character
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  };
  
  export const validateTempPassword = (tempPassword) => {
    const re = /^[A-Za-z0-9!@#$%^&*()_+-=]{6,12}$/;
    return re.test(tempPassword);
  };
  export const validateTime = (operatingHours) => {
    const re = /^([0-9]|[01]\d|2[0-3]):([0-5]\d)-([0-9]|[01]\d|2[0-3]):([0-5]\d)$/;
    return re.test(operatingHours)
  
  }
  export const TimeValidation = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);
  
    if (startHour < 0 || startHour >= 24 || startMinute < 0 || startMinute >= 60 ||
      endHour < 0 || endHour >= 24 || endMinute < 0 || endMinute >= 60) {
      return false;
    }
  
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
  
    return endTotalMinutes > startTotalMinutes;
  };