import { useState, useEffect, useContext } from 'react';
// import axios from '../../ApiServices/axiosService';
import { NFP_STATUS_API, NFP_STATUS_UPDATE } from '../../ApiServices/BaseURL';
import { NFPStatusModel } from '../Models/NFPSuperAdminModel';
// import { GlobalContext } from '../../Contexts/GlobalContext';
import { postDataLogin, getData } from '../../ApiServices/ApiService';

const useNFPSuperAdminController = (history) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [nfpData, setNfpData] = useState({ new: [], active: [], inactive: [] });
  const [selectedStatus, setSelectedStatus] = useState('new');
  const [success, setSuccess] = useState('');
  const [statusLoader, setStatusLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const screenName = "NFPSuperAdmin";
//   const { cityName } = useContext(GlobalContext);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        console.log("admindetailsParsed", admindetailsParsed)
        const email = admindetailsParsed?.email_id;
        const city = admindetailsParsed?.user_city

    try {
      const payload = { email_id: email,city:city };
      console.log("payload",payload)
      const response = await postDataLogin(NFP_STATUS_API, payload);
    //   const response = responseData?.data;

      if (response.statusCode === 200) {
        if (response.message.toLowerCase() === 'success') {
          const data = response.dataJ[0];
          console.log("data",data)
          const nfpData = {
            new: data.new.map(item => new NFPStatusModel(item)),
            active: data.active.map(item => new NFPStatusModel(item)),
            inactive: data.inactive.map(item => new NFPStatusModel(item)),
          };
          console.log("nfpData",nfpData)
          setNfpData(nfpData);
        } else if (response.message.toLowerCase() === 'fail') {
          setError(response.info || 'Response fail');
        } else {
          setError('Unknown Exception');
        //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
        }
      } else if (response.statusCode === 500) {
        setError(response.info || 'Server error');
        // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
      } else {
        setError('Error While Getting Response,Please Contact Admin');
        // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
      }
    } catch (error) {
      setError('No Internet Connection Detected Or Connection Is Slow !');
      const err = String(error);
    //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleDetailNFP = (nfp, currentStatus) => {
    const newStatus = currentStatus === 'new' ? 'active' : currentStatus === 'active' ? 'inactive' : 'active';
    // history.push('/NFPSuperAdminDetail', { nfp, newStatus });
  };

  const handleApprovePress = (nfp, currentStatus, index) => {
    setActiveIndex(index);
    const newStatus = currentStatus === 'new' ? 'active' : currentStatus === 'active' ? 'inactive' : 'active';
    statusUpdateService(nfp.nfp_id, newStatus,nfp.loc_id,nfp.program_id);
  };

  const statusUpdateService = async (nfp_id, newStatus,loc_id,program_id) => {
    setStatusLoader(true);
    setSuccess('');
    setError('');
    try {
      const payload = { nfp_id, nfp_status: newStatus,loc_id,program_id };
      console.log("Approve palyload",payload )
      const response = await postDataLogin(NFP_STATUS_UPDATE, payload);
      // const response = responseData?.data;

      if (response.statusCode === 200) {
        if (response.message.toLowerCase() === 'success') {
          setSuccess(response.info);
          setTimeout(() => {
            fetchData();
          }, 2000);
        } else if (response.message.toLowerCase() === 'fail') {
          setError(response.info || 'Response fail');
        } else {
          setError('Unknown Exception');
        //   ExceptionSendService(screenName, 'NFPStatus-101', response.info || 'Failed to fetch organization data. Please try again.');
        }
      } else if (response.statusCode === 500) {
        setError(response.info || 'Server error');
        // ExceptionSendService(screenName, 'NFPStatus-102', response.info || 'Internal server error. Please try again later.');
      } else {
        setError('Error While Getting Response,Please Contact Admin');
        // ExceptionSendService(screenName, 'NFPStatus-103', response.info || 'Error While Getting Response,Please Contact Admin');
      }
    } catch (error) {
      setError('No Internet Connection Detected Or Connection Is Slow !');
      const err = String(error);
    //   ExceptionSendService(screenName, 'NFPStatus-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
    } finally {
      setStatusLoader(false);
    }
  };

  return {
    loading,
    error,
    nfpData,
    selectedStatus,
    handleStatusChange,
    handleApprovePress,
    success,
    statusLoader,
    activeIndex,
    handleDetailNFP
  };
};

export default useNFPSuperAdminController;
