// Models/NFPInventoryAddModel.js
class NFPInventoryAddModel {
    constructor(op, nfp_id, item_name, qty, size, category, sub_category, last_updated, availability, last_updated_by, options, latitude, longitude, program_name, availability_time, address, description,active,info_link,loc_id,program_id) {
      this.op = op;
      this.nfp_id = nfp_id;
      this.item_name = item_name;
      this.qty = qty;
      this.size = size;
      this.category = category;
      this.sub_category = sub_category;
      this.last_updated = last_updated;
      this.availability = availability;
      this.last_updated_by = last_updated_by;
      this.options = options;
      this.latitude = latitude;
      this.longitude = longitude;
      this.program_name = program_name;
      this.availability_time = availability_time;
      this.address = address;
      this.description = description;
      this.active = active;
      this.info_link=info_link;
      this.loc_id =loc_id;
      this.program_id =program_id;
    }
  }
  
  export default NFPInventoryAddModel;
  