import React from "react";


const Footer = () => {

    return (
        <div>
            <footer style={{
                backgroundColor: 'black',
                padding: '5px',
                fontSize:'12px',
                textAlign: 'center',
                // height: '10px',
                color:'white',
                position:'fixed',
                left:0,
                bottom:0,
                width:'100%'
            }}>
                <div>© 2024 Farever. All rights reserved. Powered By Datalake-Solutions</div>
            </footer>
        </div>
    )
}

export default Footer;