import React from 'react';
import CustomDropdown from './CustomDropdown'; // Make sure this is a compatible ReactJS component

const TimeDropdown = ({ selectedHour, selectedMinute, selectedMeridiem, onHourChange, onMinuteChange, onMeridiemChange }) => {
  const hours = Array.from({ length: 12 }, (_, i) => {
    const value = i + 1;
    return { id: value < 10 ? `0${value}` : `${value}`, label: value < 10 ? `0${value}` : `${value}`, value: value < 10 ? `0${value}` : `${value}` };
  });

  const minutes = Array.from({ length: 60 }, (_, i) => {
    const value = i < 10 ? `0${i}` : i.toString();
    return { id: value, label: value, value: value };
  });

  const meridiems = [
    { id: 'AM', label: 'AM', value: 'AM' },
    { id: 'PM', label: 'PM', value: 'PM' },
  ];

  return (
    <div className="time-dropdown-container">
      <CustomDropdown
        selectedValue={selectedHour}
        onValueChange={onHourChange}
        options={hours}
        placeholder="HH"
        type="time"
      />
      <CustomDropdown
        selectedValue={selectedMinute}
        onValueChange={onMinuteChange}
        options={minutes}
        placeholder="MM"
        type="time"
      />
      <CustomDropdown
        selectedValue={selectedMeridiem}
        onValueChange={(value) => onMeridiemChange(value.toUpperCase())}
        options={meridiems}
        placeholder="AM/PM"

      />
    </div>
  );
};

export default TimeDropdown;
