import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import farever from './../Assets/farever.png';

const HeaderNavBar = () => {
  const navigate = useNavigate();
  const aminInfo = localStorage.getItem('_FAREVER_ADMIN_',{});

  const handleLogout = () => {
    console.log('logout');
    localStorage.clear();
    navigate('/login');
  };

  const Dashboard = () => {
    if (aminInfo?.type === 'super_admin') {
      navigate('/superadmin-dashboard');
    } else {
      navigate("/dashboard");
    }
  };

  const gotoInventory = () => {
    navigate('/NFPInventoryList');
  };

  const gotoUsers = () => {
    navigate('/user-registration');
  };

  const gotoDonations = () => {
    navigate('/DonationList');
  };

  const gotoDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <Navbar expand="lg" className="nav-background pad-4" style={{ padding: 4 }}>
        <Container fluid className='nav-container'>
          <img src={farever} className='nav-logos' alt="Farever Logo"/>
          <Navbar.Brand className='nav-heading' onClick={gotoDashboard} style={{ cursor: 'pointer' ,color: 'white',fontSize: 23,fontWeight: 700,paddingLeft: 10}}>
            FAREVER
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll" className='display-flex-nav'>
            <Nav className="mx-auto Links" style={{ maxHeight: '100px' }} >
              <Nav.Link className="navheader-toggles-styling" onClick={Dashboard}>  <span>Dashboard</span> </Nav.Link>
              <Nav.Link className="navheader-toggles-styling" onClick={gotoInventory}>  <span>Inventory</span> </Nav.Link>
              <Nav.Link className="navheader-toggles-styling" onClick={gotoUsers}>  <span>Users </span> </Nav.Link>
              <Nav.Link className="navheader-toggles-styling" onClick={gotoDonations}>  <span>Donations </span> </Nav.Link>
            </Nav>
            <Nav className="Links" style={{ maxHeight: '100px', paddingRight: 20 }} >
              <Nav.Link className="navheader-toggles-styling" variant="dark" onClick={handleLogout}>  Logout</Nav.Link>
            </Nav>

            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderNavBar;
