// views/NFPPage/ProdNFPRegistrationScreen.js
import React from 'react';
import { useState } from 'react';
import Loader from '../../../Components/Loader';
import CustomCheckBox from '../../../Components/CustomCheckBox';
import CustomDropdown from '../../../Components/CustomDropdown';
import DateFormart from '../../../Components/DateFormart';
import './../../../App.css'
import Calendar from '../../../Components/Calender';
import TimeDropdown from '../../../Components/TimeDropdown';
import useProdNFPRegistrationController from '../../Controllers/AuthenticationPages/ProdNFPRegistrationController';

const ProdNFPRegistrationScreen = ({ navigation }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    loading,
    error,
    success,
    nfpData,
    categories,
    selectedCategories,
    selectedCategory,
    handleCategoryChange,
    handleItemChange,
    handleChange,
    handleContactChange,
    handleServiceChange,
    handleAddOperatingHour,
    handleAddDonationHour,
    handleSubmit,
    setOperatingDay,
    setOperatingStartTime,
    setOperatingEndTime,
    setDonatingDay,
    setDonatingStartTime,
    setDonatingEndTime,
    operatingDay,
    operatingStartTime,
    operatingEndTime,
    donatingDay,
    donatingStartTime,
    donatingEndTime,
    setNfpCountryCode,
    setContactCountryCode,
    nfp_country_code,
    contact_country_code,
    timeError,
    dtimeErr,
    frOptions,
    operatingStartHour,
    setOperatingStartHour,
    operatingStartMinute,
    setOperatingStartMinute,
    operatingStartMeridiem,
    setOperatingStartMeridiem,
    operatingEndHour,
    setOperatingEndHour,
    operatingEndMinute,
    setOperatingEndMinute,
    operatingEndMeridiem,
    setOperatingEndMeridiem,

    programs,
    programName,
    setProgramName,
    programLatitude,
    setProgramLatitude,
    programLongitude,
    setProgramLongitude,
    handleAddProgram,

    handleAddLocation,
    handleFileUpload,
    excelFileData,
    uploadingExcelData,
    bulkMenuDivLoader,
    DataFromEventEmitter,
  } = useProdNFPRegistrationController(setIsDropdownOpen);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onFileChange2 = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const onFileUpload = () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file, index) => formData.append(`file${index}`, file)); // Append each file
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value.name}`);
      }

      handleFileUpload(formData); // Call the upload function with the FormData
    } else {
      alert('Please select at least one file to upload.');
    }
  };

  const onFileChange = async (event) => {
    const files = Array.from(event.target.files); // Get the list of selected files
    setSelectedFiles(files)
    if (files.length > 0) {

      for (const file of files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'csv') {
          const data = await parseCSVFile(file);
          if (data) {
            await DataFromEventEmitter(data);
          }
        } else {
          alert(`Unsupported file format: ${file.name}`);
        }
      }


    }


    // for Multiple File one Api Service calling
    // const files = Array.from(event.target.files);
    // if (files.length > 0) {
    //   setBulkMenuDivLoader(true);

    //   let aggregatedData = [];

    //   for (const file of files) {
    //     const fileExtension = file.name.split('.').pop().toLowerCase();

    //     if (fileExtension === 'csv') {
    //       const data = await parseCSVFile(file);
    //       if (data) {
    //         aggregatedData = [...aggregatedData, ...data];
    //       }
    //     } else {
    //       alert(`Unsupported file format: ${file.name}`);
    //     }
    //   }

    //   if (aggregatedData.length > 0) {
    //     await DataFromEventEmitter(aggregatedData);
    //   }

    //   setBulkMenuDivLoader(false);
    // }
  };

  // Function to read the Excel file and trigger processing
  const parseCSVFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split('\n').filter(row => row.trim() !== '');
        const headers = rows[0].split(',').map(header => header.trim());

        const jsonData = rows.slice(1).map(row => {
          const values = row.split(',').map(value => value.trim());
          let entry = {};
          headers.forEach((header, index) => {
            entry[header] = values[index] !== undefined ? values[index] : '';
          });
          return entry;
        });

        resolve(jsonData);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };




  const categoriesOptions = Object.keys(categories).map((key) => ({ id: key, label: key, value: key }));
  const frOptionsList = Object.keys(frOptions).map((key, index) => ({ id: key, label: key, value: key }))
  // console.log('frOptionsList:', frOptionsList)
  const countryCodes = [
    { id: '+1', label: 'US', value: '+1' },
    { id: '+91', label: 'India', value: '+91' },
    // Add more country codes as needed
  ];
  const handleNumber = (event) => {
    console.log('number', event)
    if (event.target.value?.length == 10) {
      event.preventDefault();
    }
  }

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(nfpData.fr_member_since || '');



  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setOperatingDay('');
    setOperatingStartHour('');
    setOperatingStartMinute('');
    setOperatingEndHour('');
    setOperatingEndMinute('');
  };

  const displayText = operatingStartHour && operatingEndHour
    ? `${operatingDay || 'Day'}: ${operatingStartHour || 'Start Time'}:${operatingStartMinute || '00'} ${operatingStartMeridiem || 'AM'} - ${operatingEndHour || 'End Time'}:${operatingEndMinute || '00'} ${operatingEndMeridiem || 'PM'}`
    : 'Select Day and Time';

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };

  const handleDateSelect = (formattedDate) => {
    setSelectedDate(formattedDate);
    handleChange('fr_member_since', formattedDate);
    setIsCalendarOpen(false);
  };
  const [selectedLocId, setSelectedLocId] = useState('');
  return (
    <div className="nfp-registration-container">

      <div className="form-container">
        <div>
          <h2 className='nfp-title'>NFP Registration PROD</h2>
        </div>
        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">NFP Name : <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter NFP Name "
              value={nfpData.organization_name}
              onChange={(e) => handleChange('organization_name', e.target.value)}
            />
          </div>
          {/* <div className='flex-auto'>
            <label className="form-label">NFP Email ID:</label>
            <input
              className="form-input"
              type="email"
              placeholder="Enter Email ID"
              value={nfpData.nfp_email}
              onChange={(e) => handleChange('nfp_email', e.target.value)}
            />
          </div> */}
          <div className='flex-auto'>
            <label className="form-label">NFP Phone: <span className="star-color">*</span></label>
            <div className="input-group form-input">
              {/* <CustomDropdown
                selectedValue={nfp_country_code}
                onValueChange={setNfpCountryCode}
                options={countryCodes}
              /> */}
              <input
                className="input-phone"
                type="number"
                pattern="/^-?\d+\.?\d*$/"
                onKeyPress={handleNumber}
                placeholder="Enter NFP Phone"
                value={nfpData.nfp_phone}
                onChange={(e) => handleChange('nfp_phone', e.target.value)}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        {/* <div className='row-display-flex'>

          <div className='flex-auto'>
            <label className="form-label">Address: <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Address"
              value={nfpData.address}
              onChange={(e) => handleChange('address', e.target.value)}
            />
          </div>
        </div> */}
        <div className='row-display-flex'>
          {/* <div className='flex-auto'>
            <label className="form-label">City: <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter City"
              value={nfpData.city}
              onChange={(e) => handleChange('city', e.target.value)}
            />
          </div> */}
          <div className='flex-auto'>

            <label className="form-label">Zip Code: <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Zip Code"
              value={nfpData.zipCode}
              onChange={(e) => handleChange('zipCode', e.target.value)}
              maxLength={6}
            />
          </div>
        </div>

        {/* <div className='row-display-flex'> */}
        {/* <div className='flex-auto'>
            <label className="form-label">Latitude: <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Latitude"
              value={nfpData.latitude}
              onChange={(e) => handleChange('latitude', e.target.value)}
            />
          </div> */}
        {/* <div className='flex-auto'>

            <label className="form-label">Longitude: <span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Longitude"
              value={nfpData.longitude}
              onChange={(e) => handleChange('longitude', e.target.value)}
              
            />
          </div> */}
        {/* </div> */}

        {/* <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">Categories:</label>
            <div className='form-input'>
              <CustomDropdown
                selectedValue={selectedCategory}
                onValueChange={handleCategoryChange}
                options={categoriesOptions}
                placeholder="Select Category"
              />
            </div>


            {selectedCategory && (
              <div className="category-section">
                <label className="form-label-2">{selectedCategory}:</label>
                <div className='cat-list-div'>
                {categories[selectedCategory].map((item) => (
                  <CustomCheckBox
                    key={item.service_id}
                    isChecked={selectedCategories[selectedCategory]?.some((i) => i.id === item.service_id)}
                    onClick={() => handleItemChange(selectedCategory, item)}
                    label={item.service_name}
                  />
                ))}
                </div>
               
              </div>
            )}

          </div>
          <div className='flex-auto'>
            <label className="form-label">Main Function Specifics:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Main Function Specifics"
              value={nfpData.nfp_activites}
              onChange={(e) => handleChange('nfp_activites', e.target.value)}
            />
          </div>
        </div> */}

        <div className='row-display-flex'>
          {/* <div className='flex-auto'>
            <label className="form-label">Volunteer Position:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Volunteer Position"
              value={nfpData.volunteer_pos}
              onChange={(e) => handleChange('volunteer_pos', e.target.value)}
            />
          </div> */}
          <div className='flex-auto'>

            <label className="form-label">Donation Link:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Donation Link"
              value={nfpData.donation_link}
              onChange={(e) => handleChange('donation_link', e.target.value)}
            />
          </div>
        </div>
        <div className='row-display-flex'>

          <div className='flex-auto'>
            <label className="form-label">Description:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Description"
              value={nfpData.volunteer_des}
              onChange={(e) => handleChange('volunteer_des', e.target.value)}
            />
          </div>
          {/* <div className='flex-auto'>
            <label className="form-label">Bank Name:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Bank Name"
              value={nfpData.bank_name}
              onChange={(e) => handleChange('bank_name', e.target.value)}
            />
          </div> */}
        </div>




        {/* <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">Account #:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Account #"
              value={nfpData.account_no}
              onChange={(e) => handleChange('account_no', e.target.value)}
            />
          </div>
          <div className='flex-auto'>
            <label className="form-label">Routing #:</label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Routing #"
              value={nfpData.routing_no}
              onChange={(e) => handleChange('routing_no', e.target.value)}
            />
          </div>
        </div> */}





        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">Register By:<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Register By"
              value={nfpData.register_by}
              onChange={(e) => handleChange('register_by', e.target.value)}
            />
          </div>
          <div className='flex-auto'>
            <label className="form-label">FarEver Membership Since:</label>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
              <input
                className="form-input"
                type="text"
                placeholder="Select Membership Since"
                value={selectedDate}
                onClick={handleCalendarOpen}
                readOnly
              />
              <img
                src={require('../../../Assets/calendar.png')}
                alt="Calendar Icon"
                className=""
                onClick={handleCalendarOpen}
                style={{ height: 40, width: 40, marginLeft: 10 }}
              />
            </div>
            {isCalendarOpen && (
              <div className="calendar-overlay">
                <Calendar
                  onSelectDate={(formattedDate) => handleDateSelect(formattedDate)}
                  onClose={handleCalendarClose}
                />
              </div>
            )}
            {/* <DateFormart nfpData={nfpData} handleChange={handleChange} /> */}
          </div>
        </div>
        <label className="form-label-2">Location Details :<span className="star-color">*</span></label>
<div className='row-display-flex'>
  <div className='flex-auto'>
    <label className="form-label">Location Name: <span className="star-color">*</span></label>
    <input
      className="form-input"
      type="text"
      placeholder="Enter Location Name"
      value={nfpData.location_name}
      onChange={(e) => handleChange('location_name', e.target.value)}
    />
  </div>
  <div className='flex-auto'>
    <label className="form-label">City: <span className="star-color">*</span></label>
    <input
      className="form-input"
      type="text"
      placeholder="Enter City"
      value={nfpData.city}
      onChange={(e) => handleChange('city', e.target.value)}
    />
  </div>
  <div className='flex-auto'>
    <label className="form-label">Address: <span className="star-color">*</span></label>
    <input
      className="form-input"
      type="text"
      placeholder="Enter Address"
      value={nfpData.address}
      onChange={(e) => handleChange('address', e.target.value)}
    />
  </div>
</div>
<div className="form-buttons" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2px' }}>
  <button 
    className="submit-button" 
    style={{ width: '10px', padding: '14px 1px', fontSize: '14px' }} 
    onClick={handleAddLocation}
  >
    Add Location
  </button>
</div>


        {nfpData.nfp_loc_list.length > 0 && (
          <div className="location-list">
            <h4>Added Locations:</h4>
            <ul>
              {nfpData.nfp_loc_list.map((location, index) => (
                <li key={index}>
                  <strong>Address:</strong> {location.address}, <strong>City:</strong> {location.city}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="program-details-container">
        <label className="form-label-2">Program Details :<span className="star-color">*</span></label>
        <CustomDropdown
            placeholder="Select Location *"
            options={nfpData.nfp_loc_list.map((location) => ({
              id: location.id, 
              label: `${location.location_name}`, // Display location name, address, and city
              value: location.id,
            }))}
            selectedValue={selectedLocId}
            onValueChange={(value) => {
              setSelectedLocId(value);  // Update selectedLocId state
              handleChange('loc_id', value);  // Optionally store it directly in `nfpData` if needed elsewhere
              console.log("selected location id", )
            }}
            style={{ 
              width: '100%', // Adjust this width as needed
              maxWidth: '300px', // Limit max width to prevent overflow (optional)
              whiteSpace: 'nowrap', 
              overflow: 'hidden', 
              textOverflow: 'ellipsis'
            }}
          />



        </div>


        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">Program Name:<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Program Name"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
            />
          </div>
        </div>

        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label">Program Latitude:<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Latitude"
              value={programLatitude}
              onChange={(e) => setProgramLatitude(e.target.value)}
            />
          </div>
          <div className='flex-auto'>
            <label className="form-label">Program Longitude:<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Longitude"
              value={programLongitude}
              onChange={(e) => setProgramLongitude(e.target.value)}
            />
          </div>
        </div>
        <label className="form-label-2">Services: <span className="star-color">*</span></label>
        <div className="checkbox-container">
          {/* <CustomCheckBox
            isChecked={nfpData.services_list.includes('Food')}
            onClick={() => handleServiceChange('Food')}
            label="Food"
          />
          <CustomCheckBox
            isChecked={nfpData.services_list.includes('Housing')}
            onClick={() => handleServiceChange('Housing')}
            label="Housing"
          />
          <CustomCheckBox
            isChecked={nfpData.services_list.includes('Clothing & Supplies')}
            onClick={() => handleServiceChange('Clothing & Supplies')}
            label="Clothing & Supplies"
          />
          <CustomCheckBox
            isChecked={nfpData.services_list.includes('Services')}
            onClick={() => handleServiceChange('Services')}
            label="Services"
          /> */}
          {frOptionsList && (
            <div className="category-section">
              {/* <label className="form-label-2">{selectedCategory}:</label> */}
              <div className='cat-list-div'>
                {frOptionsList.map((category) => (
                  <CustomCheckBox
                    key={category.id}
                    isChecked={nfpData.services_list.includes(category.value)}
                    onClick={() => handleServiceChange(category.value)}
                    label={category.label}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label-2">Operating Hours: <span className="star-color">*</span></label>
            <div className="dropdown-header-ci" onClick={toggleDropdown}>
              <span className="selected-time-text-ci">
                {displayText}
              </span>
              <img
                src={require('../../../Assets/arrow-up.png')}
                className={`arrow-icon-ci ${isDropdownOpen ? 'rotate-icon-ci' : ''}`}
                alt="Toggle Dropdown"
              />
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content-ci">
                <div className="dropdown-item-ci">
                  <label className="label">Day</label>
                  <CustomDropdown
                    selectedValue={operatingDay}
                    onValueChange={setOperatingDay}
                    options={[
                      { id: 'Mon', label: 'Mon', value: 'Mon' },
                      { id: 'Tue', label: 'Tue', value: 'Tue' },
                      { id: 'Wed', label: 'Wed', value: 'Wed' },
                      { id: 'Thu', label: 'Thu', value: 'Thu' },
                      { id: 'Fri', label: 'Fri', value: 'Fri' },
                      { id: 'Sat', label: 'Sat', value: 'Sat' },
                      { id: 'Sun', label: 'Sun', value: 'Sun' },
                    ]}
                    placeholder="Select Day"
                  />
                </div>
                <div className="dropdown-item-ci">
                  <label className="label">Start Time</label>
                  <TimeDropdown
                    selectedHour={operatingStartHour}
                    selectedMinute={operatingStartMinute}
                    selectedMeridiem={operatingStartMeridiem}
                    onHourChange={setOperatingStartHour}
                    onMinuteChange={setOperatingStartMinute}
                    onMeridiemChange={setOperatingStartMeridiem}
                  />
                </div>
                <div className="dropdown-item-ci">
                  <label className="label">End Time</label>
                  <TimeDropdown
                    selectedHour={operatingEndHour}
                    selectedMinute={operatingEndMinute}
                    selectedMeridiem={operatingEndMeridiem}
                    onHourChange={setOperatingEndHour}
                    onMinuteChange={setOperatingEndMinute}
                    onMeridiemChange={setOperatingEndMeridiem}
                  />
                </div>
                <button className="add-button" onClick={handleAddOperatingHour}>
                  Add Day
                </button>
              </div>
            )}
            {/* <div className="hour-container">
              <div className='day-box-style'>
              <CustomDropdown
                selectedValue={operatingDay}
                onValueChange={setOperatingDay}
                options={[
                  { id: 'Mon', label: 'Mon', value: 'Mon' },
                  { id: 'Tue', label: 'Tue', value: 'Tue' },
                  { id: 'Wed', label: 'Wed', value: 'Wed' },
                  { id: 'Thu', label: 'Thu', value: 'Thu' },
                  { id: 'Fri', label: 'Fri', value: 'Fri' },
                  { id: 'Sat', label: 'Sat', value: 'Sat' },
                  { id: 'Sun', label: 'Sun', value: 'Sun' },
                ]}
                placeholder="Day"
              />
              </div>
              
              <input
                className="time-input"
                type="text"
                placeholder="HH:MM"
                value={operatingStartTime}
                onChange={(e) => setOperatingStartTime(e.target.value)}
              />
              <input
                className="time-input"
                type="text"
                placeholder="HH:MM"
                value={operatingEndTime}
                onChange={(e) => setOperatingEndTime(e.target.value)}
              />
              <button className="add-button" onClick={handleAddOperatingHour}>
                Add Day
              </button>
             
            </div> */}
            {timeError && <p className="error-text">{timeError}</p>}
            {nfpData?.operating_hrs_list?.length > 0 && (
              <div>
                {nfpData.operating_hrs_list.map((item, index) => (
                  <p key={index}>
                    {item.day}: {item.start_time} - {item.end_time}
                  </p>
                ))}
              </div>
            )}

          </div>
          {/* <div className='flex-auto'>
            <label className="form-label-2">Donation Hours:</label>
            <div className="hour-container">
            <div className='day-box-style'>
              <CustomDropdown
                selectedValue={donatingDay}
                onValueChange={setDonatingDay}
                options={[
                  { id: 'Mon', label: 'Mon', value: 'Mon' },
                  { id: 'Tue', label: 'Tue', value: 'Tue' },
                  { id: 'Wed', label: 'Wed', value: 'Wed' },
                  { id: 'Thu', label: 'Thu', value: 'Thu' },
                  { id: 'Fri', label: 'Fri', value: 'Fri' },
                  { id: 'Sat', label: 'Sat', value: 'Sat' },
                  { id: 'Sun', label: 'Sun', value: 'Sun' },
                ]}
                placeholder="Day"
              />
              </div>
              <input
                className="time-input"
                type="text"
                placeholder="HH:MM"
                value={donatingStartTime}
                onChange={(e) => setDonatingStartTime(e.target.value)}
              />
              <input
                className="time-input"
                type="text"
                placeholder="HH:MM"
                value={donatingEndTime}
                onChange={(e) => setDonatingEndTime(e.target.value)}
              />
              <button className="add-button" onClick={handleAddDonationHour}>
                Add Day
              </button>
            </div>
            {dtimeErr && <p className="error-text">{dtimeErr}</p>}
            {nfpData?.donating_hrs_list?.length > 0 && (
              <div>
                {nfpData.donating_hrs_list.map((item) => (
                  <p key={item.day}>
                    {item.day}: {item.start_time} - {item.end_time}
                  </p>
                ))}
              </div>
            )}
          </div> */}
        </div>
        <label className="form-label-2">Contact Person Details :<span className="star-color">*</span></label>

        <div className='row-display-flex'>
          <div className='flex-auto'>
            <label className="form-label"> Name :<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Name"
              value={nfpData.contact_person_details[0].contact_name}
              onChange={(e) => handleContactChange('contact_name', e.target.value)}
            />
          </div>
          <div className='flex-auto'>
            <label className="form-label"> Email ID :<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="email"
              placeholder="Enter Email ID"
              value={nfpData.contact_person_details[0].contact_email}
              onChange={(e) => handleContactChange('contact_email', e.target.value)}
            />
          </div>
          <div style={{ paddingBottom: 10 }} className='flex-auto'>
            <label className="form-label">Contact Cell #: <span className="star-color">*</span></label>
            <div className="input-group form-input">
              {/* <CustomDropdown
            selectedValue={contact_country_code}
            onValueChange={setContactCountryCode}
            options={countryCodes}
          /> */}
              {/* <input
            className="input-phone"
            type="tel"
            placeholder="Enter Cell #"
            value={nfpData.contact_person_details[0].contact_phone}
            onChange={(e) => handleContactChange('contact_phone', e.target.value)}
            maxLength={10}
          /> */}
              <input className="input-phone"
                type="number"
                pattern="/^-?\d+\.?\d*$/"
                onKeyPress={handleNumber}
                placeholder="Enter Cell"
                value={nfpData.contact_person_details[0].contact_phone}
                onChange={(e) => handleContactChange('contact_phone', e.target.value)} />
            </div>
          </div>
        </div>
        <div className='row-display-flex'>

          <div className='flex-auto'>
            <label className="form-label">Main Function Specifics:<span className="star-color">*</span></label>
            <input
              className="form-input"
              type="text"
              placeholder="Enter Main Function Specifics"
              value={nfpData.nfp_activites}
              onChange={(e) => handleChange('nfp_activites', e.target.value)}
            />
          </div>
          <div className="form-buttons">
            <button className="submit-button" onClick={() => handleAddProgram(selectedLocId)}>
              Add Program
            </button>
          </div>
        </div>
        {programs.length > 0 && (
            <div className="program-list">
              <h4>Added Programs:</h4>
              {programs.map((program, index) => (
                <div key={index} style={{ marginBottom: '15px' }}>
                  <div>
                    <strong>Program Name:</strong> <span>{program.program_name}</span>
                  </div>
                  <div>
                    <strong>Latitude:</strong> <span>{program.latitude}</span>, 
                    <strong> Longitude:</strong> <span>{program.longitude}</span>
                  </div>

                  {program.services_list.length > 0 && (
                    <div>
                      <strong>Services:</strong> <span>{program.services_list.join(', ')}</span>
                    </div>
                  )}

                  {program.operating_hrs_list.length > 0 && (
                    <div>
                      <strong>Operating Hours:</strong>
                      {program.operating_hrs_list.map((hour, hourIndex) => (
                        <span key={hourIndex}>
                          {hour.day}: {hour.start_time} - {hour.end_time}{hourIndex < program.operating_hrs_list.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  )}

                  {program.contact_person_details.length > 0 && (
                    <div>
                      <strong>Contact Person Details:</strong>
                      {program.contact_person_details.map((contact, contactIndex) => (
                        <span key={contactIndex}>
                          Name: {contact.contact_name}, Email: {contact.contact_email}, Phone: {contact.contact_phone}
                          {contactIndex < program.contact_person_details.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  )}

                  <hr />
                </div>
              ))}
            </div>
          )}



        {loading && <Loader loading={loading} color={'#FFFFFF'} />}
        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}



        <div className="form-buttons">
          <button className="submit-button" onClick={() => {
            console.log('Selected Location ID:', selectedLocId); // Debugging
            handleSubmit(selectedLocId);
          }}>
            Submit
          </button>
        </div>
        <div style={{ marginTop: '15px' }}>
          {/* <label className="form-label">Upload Excel File:</label> */}
          {/* <input type="file" accept=".xlsx, .xls" onChange={onFileChange2} style={{ width: '100%', padding: '10px', marginTop: '20px',marginLeft:'20px' }} /> */}

          {/* <span className="upl" id="upload" style={{ color: '#f3f2f7' }}>
              Upload Excel File
            </span> */}
          <label className="form-label-2" >Upload Bulk NFP'S :</label>
          </div>
          {/* <input  type="file" accept=".csv" multiple onChange={onFileChange} style={{ width: '100%', padding: '10px', marginTop: '0px',marginLeft:'20px', }} />  */}
          <div style={{display:'flex',flexDirection:'row',margin:'15px'}}>
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            multiple
            onChange={onFileChange}
            style={{ display: 'none' }} // Hide the default input
          />
          <label htmlFor="file-upload" className="custom-file-upload">
            Choose Files
          </label>
          <div className="form-label">
            {selectedFiles
              ? `${selectedFiles.length} file(s) selected`
              : 'No files chosen'}
          </div>

          {/* <div>
            {selectedFiles?.length > 0 && (
              <ul>
                {selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button onClick={() => removeFile(index)} style={{ marginLeft: '10px' }}>
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div> */}
        </div>

        {/* <div className="form-buttons">
         
          <button className="submit-button" onClick={onFileUpload}>Upload Excel</button>
        </div> */}
      </div>


    </div>
  );
};

export default ProdNFPRegistrationScreen;
