import React from 'react';
import { useState,useRef } from 'react';
import Loader from '../../../Components/Loader';
import CustomCheckBox from '../../../Components/CustomCheckBox';
import CustomDropdown from '../../../Components/CustomDropdown';
import arrowRight from '../../../Assets/right-arrow.png';
import useNFPRegistrationController from '../../Controllers/AuthenticationPages/NFPRegistrationController';
import TimeDropdown from '../../../Components/TimeDropdown';
import Calendar from '../../../Components/Calender';
import arrowRightWhite from '../../../Assets/icons/arrow-icon-white.png';

import HeaderNavBarSignup from '../../../Components/HeaderNavBarSignup';
import Footer from '../../../Components/Footer';

const NFPRegistrationViewNew = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFoodDropdownOpen, setIsFoodDropdownOpen] = useState(false);
  const [selectedLocId, setSelectedLocId] = useState('');
  const nfpNameRef = useRef(null);
    const zipCodeRef = useRef(null);
    const nfpPhoneRef = useRef(null);
    const registerByRef = useRef(null);
    const locationRef = useRef(null);
    const programRef = useRef(null);
    const locationNameRef = useRef(null);
    const locationCityName = useRef(null);
    const locationAddress = useRef(null);
    const programNameRef = useRef(null);
    const programLatRef = useRef(null);
    const programLongRef = useRef(null);
    const selectLocationRef = useRef(null);
    const programServicesRef = useRef(null);
    const programOperatingHoursRef = useRef(null);
    const programContactName = useRef(null);
    const programContactEmail = useRef(null);
    const programContactCellRef = useRef(null);
    const programMainSpecificRef = useRef(null);

  const {
    loading,
    error,
    success,
    nfpData,
    categories,
    selectedCategories,
    selectedCategory,
    handleCategoryChange,
    handleItemChange,
    handleChange,
    handleContactChange,
    handleServiceChange,
    handleAddOperatingHour,
    handleAddDonationHour,
    handleSubmit,
    setOperatingDay,
    setOperatingStartTime,
    setOperatingEndTime,
    setDonatingDay,
    setDonatingStartTime,
    setDonatingEndTime,
    operatingDay,
    operatingStartTime,
    operatingEndTime,
    donatingDay,
    donatingStartTime,
    donatingEndTime,
    setNfpCountryCode,
    setContactCountryCode,
    nfp_country_code,
    contact_country_code,
    timeError,
    dtimeErr,
    frOptions,
    operatingStartHour,
    setOperatingStartHour,
    operatingStartMinute,
    setOperatingStartMinute,
    operatingStartMeridiem,
    setOperatingStartMeridiem,
    operatingEndHour,
    setOperatingEndHour,
    operatingEndMinute,
    setOperatingEndMinute,
    operatingEndMeridiem,
    setOperatingEndMeridiem,

    programs,
    programName,
    setProgramName,
    programLatitude,
    setProgramLatitude,
    programLongitude,
    setProgramLongitude,
    handleAddProgram,
    setProgramActivities,
    programActivities,

    handleAddLocation,
    handleFileUpload,
    excelFileData,
    uploadingExcelData,
    bulkMenuDivLoader,
    DataFromEventEmitter,

    handleAddServingHour,
    servingDay,
    setServingDay,
    servingStartHour,
    setServingStartHour,
    servingStartMinute,
    setServingStartMinute,
    servingStartMeridiem,
    setServingStartMeridiem,
    servingEndHour,
    setServingEndHour,
    servingEndMinute,
    setServingEndMinute,
    servingEndMeridiem,
    setServingEndMeridiem,

    handleDaySelection,
    handleDaySelectionForFood,

    fieldErrors,
    setFieldErrors,
    validateNFPDetailsInputs,
    setError,
    validateLocationInputs,
    validateProgramInputs
  } = useNFPRegistrationController(setIsDropdownOpen,setIsFoodDropdownOpen,setSelectedLocId,{ nfpNameRef, zipCodeRef, nfpPhoneRef, registerByRef, locationRef, programRef,locationNameRef,locationCityName,locationAddress,programNameRef,programLatRef,programLongRef,programServicesRef,programOperatingHoursRef,programContactName,programContactEmail,programContactCellRef,programMainSpecificRef,selectLocationRef });

  const [nfpDetails, setNfpDetails] = useState({
    name: '',
    phone: '',
    zipCode: '',
    donation_link: '',
    registered_by:''
  });

  const [locationDetails, setLocationDetails] = useState({
    locationName: '',
    city: '',
    address: '',
  });

  const [programDetails, setProgramDetails] = useState({
    programName: '',
    latitude: '',
    longitude: '',
    nfp_activities:'',
    contactName: '',
    email: '',
    phone: '',
  });

  const handleNextStep = () => {
    const isValid = validateNFPDetailsInputs();
    if (isValid) {
      setCurrentStep((prevStep) => prevStep + 1);
      setError('');
    } else {
    }
};
const handleLocationNextStep = () => {
  const isValid = validateLocationInputs();
  if (isValid) {
    setCurrentStep((prevStep) => prevStep + 1);
    setError('');
  } else {
  }
};
const handleProgramNextStep = () => {
  const isValid = validateProgramInputs();
  if (isValid) {
    setCurrentStep((prevStep) => prevStep + 1);
    setError('');
  } else {
  }
};

  const handleBackStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const steps = [
    'NFP Details',
    'Location Details',
    'Program Details',
    'Summary',
  ];
  const frOptionsList = Object.keys(frOptions).map((key, index) => ({ id: key, label: key, value: key }));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setOperatingDay('');
    setOperatingStartHour('');
    setOperatingStartMinute('');
    setOperatingEndHour('');
    setOperatingEndMinute('');
  };
  const toggleFoodServingDropdown = () => {
    setIsFoodDropdownOpen(!isFoodDropdownOpen);
    setServingDay('');
    setServingStartHour('');
    setServingEndHour('');
    setServingStartMinute('');
    setServingEndMinute('');
    setServingStartMeridiem('AM');
    setServingEndMeridiem('PM');
  };

  const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);
  const toggleDayDropdown = () => {
    setIsDayDropdownOpen(prevState => !prevState);
  };
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(nfpData.fr_member_since || '');
  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };
  const handleDateSelect = (formattedDate) => {
    setSelectedDate(formattedDate);
    handleChange('fr_member_since', formattedDate);
    setIsCalendarOpen(false);
  };
  
    const displayText = operatingStartHour && operatingEndHour
      ? `${operatingDay || 'Day'}: ${operatingStartHour || 'Start Time'}:${operatingStartMinute || '00'} ${operatingStartMeridiem || 'AM'} - ${operatingEndHour || 'End Time'}:${operatingEndMinute || '00'} ${operatingEndMeridiem || 'PM'}`
      : 'Select Day and Time';
      const displayTextForFood = servingStartHour && servingEndHour
      ? `${servingDay || 'Day'}: ${servingStartHour || 'Start Time'}:${servingStartMinute || '00'} ${servingStartMeridiem || 'AM'} - ${servingEndHour || 'End Time'}:${servingEndMinute || '00'} ${servingEndMeridiem || 'PM'}`
      : 'Select Day and Time';
  

  return (
    <>
    <HeaderNavBarSignup />
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      
      {/* Step Indicator */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1px' }}>
        {steps.map((step, index) => (
          <div key={index} style={{ textAlign: 'center', width: '25%' }}>
            <div
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: 
                  currentStep === index + 1 ? '#007bff' : // Current step is blue
                  currentStep > index ? 'green' : // Previous steps are green
                  '#ccc', // Upcoming steps are gray
                color: '#fff',
                lineHeight: '30px',
                margin: '0 auto',
                marginBottom: '10px',
                fontWeight: 'bold',
              }}
            >
              {index + 1}
            </div>
            <div style={{
              fontSize: '14px', 
              color: 
                currentStep === index + 1 ? '#007bff' : // Current step label is blue
                currentStep > index ? 'green' : // Previous steps labels are green
                '#666' // Upcoming steps labels are gray
            }}>
              {step}
            </div>
          </div>
        ))}
      </div>


      <hr style={{ borderTop: '1px solid #ccc', marginBottom: '30px' }} />
      {/* Section 1: NFP Details */}
      {currentStep === 1 && (
        <div style={{ marginBottom: '30px' }}>
          {/* <h3 style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 'bold' }}>NFP Details</h3> */}
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>NFP Name<span className="star-color">*</span></label>
            <input
              type="text"
              placeholder="Enter NFP Name"
              value={nfpData.organization_name}
              onChange={(e) => handleChange('organization_name', e.target.value)}
              style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>NFP Phone<span className="star-color">*</span></label>
              <input
                type="text"
                placeholder="Enter NFP Phone"
                value={nfpData.nfp_phone}
                onChange={(e) => handleChange('nfp_phone', e.target.value)}
                maxLength={10}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Zip Code<span className="star-color">*</span></label>
              <input
                type="text"
                placeholder="Enter Zip Code"
                value={nfpData.zipCode}
                onChange={(e) => handleChange('zipCode', e.target.value)}
                maxLength={6}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Donation Link</label>
            <input
              type="text"
              placeholder="Donation Link"
              value={nfpData.donation_link}
              onChange={(e) => handleChange('donation_link', e.target.value)}
              style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
  {/* Registered By Input */}
  <div style={{ width: '48%' }}>
    <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Registered By<span className="star-color">*</span></label>
    <input
      type="text"
      placeholder="Registered By"
      value={nfpData.register_by}
      onChange={(e) => handleChange('register_by', e.target.value)}
      style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
    />
  </div>

  {/* FarEver Membership Since Input with Calendar Icon */}
  <div style={{ width: '48%', marginTop: '4px' }}>
    <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px', }}>Farever Membership Since</label>
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '110%' }}>
      <input
        type="text"
        placeholder="Select Membership Since"
        value={selectedDate}
        onClick={handleCalendarOpen}
        readOnly
        style={{ width: 'calc(100% - 30px)', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }} // Adjusted width
      />
      <img
        src={require('../../../Assets/calendar.png')}
        alt="Calendar Icon"
        onClick={handleCalendarOpen}
        style={{ position: 'absolute', right: '18px', height: 20, width: 20, cursor: 'pointer' }}
      />
    </div>
    {isCalendarOpen && (
      <div className="calendar-overlay">
        <Calendar
          onSelectDate={(formattedDate) => handleDateSelect(formattedDate)}
          onClose={handleCalendarClose}
        />
      </div>
    )}
  </div>
</div>



          <div>
          <div className='row-display-flex'>
                      <div className='flex-auto' style={{ maxWidth: '310px' }}>
                <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px', }}>Food Serving Hours</label>
                <div 
                    onClick={toggleFoodServingDropdown}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      padding: '10px',
                      cursor: 'pointer',
                      backgroundColor: '#fff',
                      boxShadow: 'none'
                    }}
                  >
                    <span style={{ flex: 1, fontSize: '14px', color: '#333' }}>
                      {displayTextForFood}
                    </span>
                    <img
                      src={require('../../../Assets/arrow-up.png')}
                      alt="Toggle Dropdown"
                      style={{
                        width: '14px',
                        height: '10px',
                        transition: 'transform 0.3s',
                        transform: isFoodDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                      }}
                    />
                  </div>

                {isFoodDropdownOpen && (
                  <div className="dropdown-content-ci-reg">
                    <div className="dropdown-item-ci">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={toggleDayDropdown}>
                        <label className="label">Select Day</label>
                        <img
                          src={require('../../../Assets/arrow-up.png')}
                          className={`arrow-icon-ci ${isDayDropdownOpen ? 'rotate-icon-ci' : ''}`}
                          alt="Toggle Days"
                          style={{ width: '13px', height: '13px', marginRight: 10 }}
                        />
                      </div>
                      {isDayDropdownOpen && (
                        <div className="checkbox-group">
                          <div className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                            <input
                              type="checkbox"
                              id="All"
                              value="All"
                              checked={servingDay.length === 7} // If all days are selected, "All" should be checked
                              onChange={(e) => handleDaySelectionForFood('All', e.target.checked)}
                              style={{ marginRight: '10px' }} // Add space between checkbox and day label
                            />
                            <label htmlFor="All">All</label>
                          </div>
                          {[
                            { id: 'Mon', label: 'Mon', value: 'Mon' },
                            { id: 'Tue', label: 'Tue', value: 'Tue' },
                            { id: 'Wed', label: 'Wed', value: 'Wed' },
                            { id: 'Thu', label: 'Thu', value: 'Thu' },
                            { id: 'Fri', label: 'Fri', value: 'Fri' },
                            { id: 'Sat', label: 'Sat', value: 'Sat' },
                            { id: 'Sun', label: 'Sun', value: 'Sun' },
                          ].map(day => (
                            <div key={day.id} className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                              <input
                                type="checkbox"
                                id={day.id}
                                value={day.value}
                                checked={servingDay.includes(day.value)}
                                onChange={(e) => handleDaySelectionForFood(e.target.value, e.target.checked)}
                                style={{ marginRight: '10px' }} // Add space between checkbox and day label
                              />
                              <label htmlFor={day.id}>{day.label}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="dropdown-item-ci">
                      <label className="label">Start Time</label>
                      <TimeDropdown
                        selectedHour={servingStartHour}
                        selectedMinute={servingStartMinute}
                        selectedMeridiem={servingStartMeridiem}
                        onHourChange={setServingStartHour}
                        onMinuteChange={setServingStartMinute}
                        onMeridiemChange={setServingStartMeridiem}
                      />
                    </div>
                    <div className="dropdown-item-ci">
                      <label className="label">End Time</label>
                      <TimeDropdown
                        selectedHour={servingEndHour}
                        selectedMinute={servingEndMinute}
                        selectedMeridiem={servingEndMeridiem}
                        onHourChange={setServingEndHour}
                        onMinuteChange={setServingEndMinute}
                        onMeridiemChange={setServingEndMeridiem}
                      />
                    </div>
                    <button className="add-button" onClick={handleAddServingHour}>
                      Add Days
                    </button>
                  </div>
                )}
                {timeError && <p className="error-text">{timeError}</p>}
                {nfpData?.food_serving_hours?.length > 0 && (
                  <div>
                    {nfpData.food_serving_hours.map((item, index) => (
                      <p key={index}>
                        {item.day}: {item.start_time} - {item.end_time}
                      </p>
                    ))}
                  </div>
                )}
              </div>
        </div>
          </div>
          {error && <p className="error-text">{error}</p>}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            {/* <span
              style={{
                fontSize: '16px',
                color: '#000',
                cursor: 'pointer',
                marginRight: '15px',
                alignSelf: 'center',
              }}
              onClick={handleBackStep}
            >
              Back
            </span> */}
            <button
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '-19px',
              }}
              onClick={handleNextStep}
            >
              Next Step
              <img 
                src={arrowRightWhite}
                alt="arrow" 
                style={{
                  width: '16px', 
                  height: '16px', 
                  marginLeft: '10px',
                  color: '#fff'
                }}
              />
            </button>
          </div>
        </div>
      )}

      {/* Section 2: Location Details */}
      {currentStep === 2 && (
  <div style={{ marginBottom: '30px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Location Name<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter Location Name"
          value={nfpData.location_name}
          onChange={(e) => handleChange('location_name', e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>City<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter City"
          value={nfpData.city}
          onChange={(e) => handleChange('city', e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
    </div>
    <div style={{ marginBottom: '15px' }}>
      <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Address<span className="star-color">*</span></label>
      <input
        type="text"
        placeholder="Enter Address"
        value={nfpData.address}
        onChange={(e) => handleChange('address', e.target.value)}
        style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <div style={{ width: '100%' }}>
        {nfpData.nfp_loc_list.length > 0 && (
          <div className="location-list" style={{ padding: '0px 20px 20px 0px' }}>
            <h6 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>Added Locations</h6>
            {nfpData.nfp_loc_list.map((location, index) => (
              <div key={index} style={{ marginBottom: '2px', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Location Name:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{location.location_name}</span>
                </div>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Address:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{location.address}</span>
                </div>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>City:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{location.city}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginRight: '-19px',
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        
        onClick={handleAddLocation}
      >
        Add Location
      </button>
    </div>
    <hr style={{ borderTop: '1px solid #ccc', marginBottom: '5px' }} />
    {error && <p className="error-text">{error}</p>}
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      <span
        style={{
          fontSize: '16px',
          color: '#000',
          cursor: 'pointer',
          marginRight: '15px',
          alignSelf: 'center', 
        }}
        onClick={handleBackStep}
      >
        Back
      </span>
      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '-19px',
        }}
        onClick={handleLocationNextStep}
      >
        Next Step
        <img 
          src={arrowRightWhite}
          alt="arrow" 
          style={{
            width: '16px', 
            height: '16px', 
            marginLeft: '10px',
            color: '#fff'
          }}
        />
      </button>
    </div>
  </div>
)}



      {/* Section 3: Program Details */}
      {currentStep === 3 && (
  <div style={{ marginBottom: '30px' }}>
    <h3 style={{ marginBottom: '5px', fontSize: '15px', fontWeight: 'bold', color: "#007bff", textAlign: "center" }}>Program & Contact Details</h3>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Program Name<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter Program Name"
          value={programName}
          onChange={(e) => setProgramName(e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>

      <div style={{ width: '51%', marginTop: '21px',marginRight:'-19px' }}>
        <CustomDropdown
          placeholder={
            <span>
              Select Location <span style={{ color: 'red' }}>*</span>
            </span>
          }
          options={nfpData.nfp_loc_list.map((location) => ({
            id: location.id,
            label: `${location.location_name}`,
            value: location.id,
          }))}
          selectedValue={selectedLocId}
          onValueChange={(value) => {
            setSelectedLocId(value);
            handleChange('loc_id', value);
            console.log('selected location id', value);
          }}
          style={{
            width: '100%',
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '10px',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            height: '38px',
          }}
          dropdownStyle={{
            padding: '8px',
            marginTop: '0px',
          }}
        />
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Latitude<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter Latitude"
          value={programLatitude}
          onChange={(e) => setProgramLatitude(e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Longitude<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter Longitude"
          value={programLongitude}
          onChange={(e) => setProgramLongitude(e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
    </div>

    <div style={{ marginBottom: '15px' }}>
      <label className="form-label-2" ref={programServicesRef}>Services<span className="star-color">*</span></label>
      <div className="checkbox-container">
        {frOptionsList && (
          <div className="category-section">
            <div className='cat-list-div'>
              {frOptionsList.map((category) => (
                <CustomCheckBox
                  key={category.id}
                  isChecked={nfpData.services_list.includes(category.value)}
                  onClick={() => handleServiceChange(category.value)}
                  label={category.label}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Main Function<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Main Function"
          value={programActivities}
          onChange={(e) => setProgramActivities(e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>

      <div style={{ width: '48%',marginRight:'-19px' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Operating Hours<span className="star-color">*</span></label>
        <div 
          onClick={toggleDropdown}
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            cursor: 'pointer',
            backgroundColor: '#fff',
            boxShadow: 'none'
          }}
        >
          <span style={{ flex: 1, fontSize: '14px', color: '#333' }}>
            {displayText}
          </span>
          <img
            src={require('../../../Assets/arrow-up.png')}
            alt="Toggle Dropdown"
            style={{
              width: '15px',
              height: '15px',
              transition: 'transform 0.3s',
              transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </div>

        {isDropdownOpen && (
          <div className="dropdown-content-ci-reg">
            <div className="dropdown-item-ci">
              <div 
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} 
                onClick={toggleDayDropdown}
              >
                <label className="label">Select Day</label>
                <img
                  src={require('../../../Assets/arrow-up.png')}
                  className={`arrow-icon-ci ${isDayDropdownOpen ? 'rotate-icon-ci' : ''}`}
                  alt="Toggle Days"
                  style={{ width: '13px', height: '13px', marginRight: 10 }}
                />
              </div>
              {isDayDropdownOpen && (
                <div className="checkbox-group">
                  <div className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      checked={operatingDay.length === 7} 
                      onChange={(e) => handleDaySelection('All', e.target.checked)}
                      style={{ marginRight: '10px' }}
                    />
                    <label htmlFor="All">All</label>
                  </div>
                  {[
                    { id: 'Mon', label: 'Mon', value: 'Mon' },
                    { id: 'Tue', label: 'Tue', value: 'Tue' },
                    { id: 'Wed', label: 'Wed', value: 'Wed' },
                    { id: 'Thu', label: 'Thu', value: 'Thu' },
                    { id: 'Fri', label: 'Fri', value: 'Fri' },
                    { id: 'Sat', label: 'Sat', value: 'Sat' },
                    { id: 'Sun', label: 'Sun', value: 'Sun' },
                  ].map(day => (
                    <div key={day.id} className="checkbox-item" style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        id={day.id}
                        value={day.value}
                        checked={operatingDay.includes(day.value)}
                        onChange={(e) => handleDaySelection(e.target.value, e.target.checked)}
                        style={{ marginRight: '10px' }}
                      />
                      <label htmlFor={day.id}>{day.label}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="dropdown-item-ci">
              <label className="label">Start Time</label>
              <TimeDropdown
                selectedHour={operatingStartHour}
                selectedMinute={operatingStartMinute}
                selectedMeridiem={operatingStartMeridiem}
                onHourChange={setOperatingStartHour}
                onMinuteChange={setOperatingStartMinute}
                onMeridiemChange={setOperatingStartMeridiem}
              />
            </div>
            <div className="dropdown-item-ci">
              <label className="label">End Time</label>
              <TimeDropdown
                selectedHour={operatingEndHour}
                selectedMinute={operatingEndMinute}
                selectedMeridiem={operatingEndMeridiem}
                onHourChange={setOperatingEndHour}
                onMinuteChange={setOperatingEndMinute}
                onMeridiemChange={setOperatingEndMeridiem}
              />
            </div>
            <button className="add-button" onClick={handleAddOperatingHour}>
              Add Days
            </button>
          </div>
        )}
        {timeError && <p className="error-text">{timeError}</p>}
        {nfpData?.operating_hrs_list?.length > 0 && (
          <div>
            {nfpData.operating_hrs_list.map((item, index) => (
              <p key={index}>
                {item.day}: {item.start_time} - {item.end_time}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>

    <h3 style={{ marginBottom: '5px', fontSize: '15px', fontWeight: 'bold', color: "#007bff", textAlign: "center" }}>Contact Details</h3>
    <div style={{ marginBottom: '15px' }}>
      <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Contact Name<span className="star-color">*</span></label>
      <input
        type="text"
        placeholder="Enter Contact Name"
        value={nfpData.contact_person_details[0].contact_name}
        onChange={(e) => handleContactChange('contact_name', e.target.value)}
        style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Contact Email<span className="star-color">*</span></label>
        <input
          type="email"
          placeholder="Enter Contact Email"
          value={nfpData.contact_person_details[0].contact_email}
          onChange={(e) => handleContactChange('contact_email', e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
      <div style={{ width: '48%' }}>
        <label style={{ display: 'block', marginBottom: '1px', fontSize: '14px' }}>Contact Phone<span className="star-color">*</span></label>
        <input
          type="text"
          placeholder="Enter Contact Phone"
          value={nfpData.contact_person_details[0].contact_phone}
          onChange={(e) => handleContactChange('contact_phone', e.target.value)}
          style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <div style={{ width: '100%' }}>
        {programs.length > 0 && (
          <div className="program-list" style={{ padding: '0px 20px 20px 0px' }}>
            <h6 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>Added Programs</h6>
            {programs.map((program, index) => (
              <div key={index} style={{ marginBottom: '2px', borderBottom: '1px solid #ddd', paddingBottom: '5px' }}>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Program Name:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{program.program_name}</span>
                </div>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Latitude:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{program.latitude}</span>
                </div>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Longitude:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{program.longitude}</span>
                </div>
                <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                  <span style={{ fontWeight: 'bold' }}>Program Main Activity:</span>
                  <span style={{ marginLeft: '5px', color: '#555' }}>{program.nfp_activities}</span>
                </div>
                {program.services_list.length > 0 && (
                  <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                    <span style={{ fontWeight: 'bold' }}>Services:</span>
                    <span style={{ marginLeft: '5px', color: '#555' }}>{program.services_list.join(', ')}</span>
                  </div>
                )}
                {program.operating_hrs_list.length > 0 && (
                  <div style={{ marginBottom: '10px', fontSize: '14px', color: '#007bff' }}>
                    <span style={{ fontWeight: 'bold' }}>Operating Hours:</span>
                    <div style={{ color: '#555' }}>
                      {program.operating_hrs_list.map((hour, hourIndex) => (
                        <div key={hourIndex} style={{ marginBottom: '5px' }}>
                          {hour.day}: {hour.start_time} - {hour.end_time}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {program.contact_person_details.length > 0 && (
                  <div style={{ fontSize: '14px', color: '#007bff' }}>
                    <span style={{ fontWeight: 'bold' }}>Contact Person Details:</span>
                    <div style={{ color: '#555' }}>
                      {program.contact_person_details.map((contact, contactIndex) => (
                        <div key={contactIndex} style={{ marginBottom: '5px' }}>
                          Name: {contact.contact_name}, Email: {contact.contact_email}, Phone: {contact.contact_phone}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          marginRight: '-19px',
          whiteSpace: 'nowrap',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => handleAddProgram(selectedLocId)}
      >
        Add Program
      </button>
    </div>
    <hr style={{ borderTop: '1px solid #ccc', marginBottom: '5px' }} />
    {error && <p className="error-text">{error}</p>}
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
      <span
        style={{
          fontSize: '16px',
          color: '#000',
          cursor: 'pointer',
          marginRight: '15px',
          alignSelf: 'center',
        }}
        onClick={handleBackStep}
      >
        Back
      </span>
      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '-19px',
        }}
        onClick={handleProgramNextStep}
      >
        Next Step
        <img 
          src={arrowRightWhite}
          alt="arrow" 
          style={{
            width: '16px',
            height: '16px',
            marginLeft: '10px',
            color: '#fff'
          }}
        />
      </button>

    </div>
  </div>
)}


      {/* Section 4: Summary */}
      {currentStep === 4 && (
  <div style={{ marginBottom: '30px' }}>
    {/* <h3 style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 'bold', color: "#007bff", textAlign: 'center' }}>Summary</h3> */}

    {/* NFP Details Section */}
    <div style={{ marginBottom: '10px', padding: '0px 15px', border: '1px solid #ddd', borderRadius: '4px',maxWidth:'500px' }}>
      <h4 style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '15px', color: '#007bff', textAlign: 'center' }}>NFP Details</h4>
      <div style={{ lineHeight: '1.5',fontSize: '14px' }}>
  <p style={{ margin: '5px 0' }}><strong>NFP Name:</strong> {nfpData.organization_name}</p>
  <p style={{ margin: '5px 0' }}><strong>Phone:</strong> {nfpData.nfp_phone}</p>
  <p style={{ margin: '5px 0' }}><strong>Zip Code:</strong> {nfpData.zipCode}</p>
  <p style={{ margin: '5px 0' }}><strong>Donation Link:</strong> {nfpData.donation_link}</p>
  <p style={{ margin: '5px 0' }}><strong>Registered By:</strong> {nfpData.register_by}</p>
</div>

    </div>

    {/* Location Details Section */}
    <div style={{ marginBottom: '10px', padding: '0px 15px', border: '1px solid #ddd', borderRadius: '4px',maxWidth:'500px' }}>
  <h4 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', color: '#007bff', textAlign: 'center' }}>Location Details</h4>
  {nfpData.nfp_loc_list.map((location, index) => (
    <div key={index} style={{ marginBottom: '10px' }}>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Location {index + 1}</strong>
      </p>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Name:</strong> {location.location_name}, 
        <strong> Address:</strong> {location.address}, 
        <strong> City:</strong> {location.city}
      </p>
    </div>
  ))}
</div>


    {/* Program Details Section */}
    <div style={{ padding: '0px 15px', border: '1px solid #ddd', borderRadius: '4px',maxWidth:'500px' }}>
  <h4 style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '10px', color: '#007bff', textAlign: 'center' }}>
    Program & Contact Details
  </h4>
  {programs.map((program, index) => (
    <div key={index} style={{ marginBottom: '10px' }}>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Program {index + 1}</strong>
      </p>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Program Name:</strong> {program.program_name}
      </p>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Latitude:</strong> {program.latitude}, 
        <strong> Longitude:</strong> {program.longitude}
      </p>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Main Function:</strong> {program.nfp_activities}, 
        <strong> Services:</strong> {program.services_list.join(', ')}
      </p>
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Operating Hours:</strong>
      </p>
      {program.operating_hrs_list.map((hour, hourIndex) => (
        <p key={hourIndex} style={{ fontSize: '14px', margin: '5px 0' }}>
          {hour.day}: {hour.start_time} - {hour.end_time}
        </p>
      ))}
      <p style={{ fontSize: '14px', margin: '5px 0' }}>
        <strong>Contact Person Details:</strong>
      </p>
      {program.contact_person_details.map((contact, contactIndex) => (
        <p key={contactIndex} style={{ fontSize: '14px', margin: '5px 0' }}>
          Name: {contact.contact_name}, Email: {contact.contact_email}, Phone: {contact.contact_phone}
        </p>
      ))}
    </div>
  ))}
</div>


    {/* Navigation Buttons */}
    {success && <p className="success-text">{success}</p>}
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px',maxWidth:'532px' }}>
      <span
        style={{
          fontSize: '16px',
          color: loading ? '#ccc' : '#000',
          cursor: loading ? 'not-allowed' : 'pointer',
          marginRight: '15px',
          alignSelf: 'center',
          pointerEvents: loading ? 'none' : 'auto',
        }}
        onClick={!loading ? handleBackStep : null}
      >
        Back
      </span>

      <button
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          handleSubmit(selectedLocId);
        }}
        disabled={loading}
      >
        {loading ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>Registering...</span>
            <div className="inline-spinner"></div>
          </div>
        ) : (
          'Register'
        )}
      </button>
    </div>
  </div>
)}


       <Footer></Footer>
    </div>
    </>
  );
};

export default NFPRegistrationViewNew;
