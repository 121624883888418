import { useState, useEffect, useContext } from 'react';
import { NFP_STATUS_API, NFP_STATUS_UPDATE,BANNER_API } from '../../ApiServices/BaseURL';
import { postDataLogin, getData } from '../../ApiServices/ApiService';
import { NFPBannerRegistrationModel } from '../Models/NFPBannerRegistrationModel';
import { useNavigate } from 'react-router-dom';

const useNFPBannerRegistrationController = (history) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeNFPs, setActiveNFPs] = useState([]);
  const screenName = "NFPBannerRegistration";
  const navigate = useNavigate();
//   const { cityName } = useContext(GlobalContext);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    setActiveNFPs([]);
    const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        console.log("admindetailsParsed", admindetailsParsed)
        const email = admindetailsParsed?.email_id;
        const city = admindetailsParsed?.user_city
    try {
      const payload = { email_id: email, city:city};
      console.log("payload",payload)
      const response = await postDataLogin(NFP_STATUS_API, payload);
    //   const response = responseData?.data;

      if (response.statusCode === 200) {
        if (response.message.toLowerCase() === 'success') {
          const data = response.dataJ[0];
          console.log("data",data)
          const activeNFPs = response.dataJ[0].active;
          setActiveNFPs(activeNFPs);
        } else if (response.message.toLowerCase() === 'fail') {
          setError(response.info || 'Response fail');
        } else {
          setError('Unknown Exception');
        //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
        }
      } else if (response.statusCode === 500) {
        setError(response.info || 'Server error');
        // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
      } else {
        setError('Error While Getting Response,Please Contact Admin');
        // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
      }
    } catch (error) {
      setError('No Internet Connection Detected Or Connection Is Slow !');
      const err = String(error);
    //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBannerSubmit = async (bannerData,setBannerData) => {
    setLoading(true);
    setError('');
    const selectedNFP = activeNFPs.find(nfp => nfp.nfp_id === bannerData.nfp);
    if (!selectedNFP || !selectedNFP.loc_id || selectedNFP.loc_id === 'NA') {
        setError('Location ID is missing');
        setLoading(false);
        return;
    }

    // Individual Validation for program_id
    if (!selectedNFP || !selectedNFP.program_id || selectedNFP.program_id === 'NA') {
        setError('Program ID is missing');
        setLoading(false);
        return;
    }
    const banner = new NFPBannerRegistrationModel({
        ...bannerData,
        loc_id: selectedNFP.loc_id,
        program_id: selectedNFP.program_id,
      });
      const payload = banner.toJSON();
    console.log("palyoad in add banner",payload);
    try {
        console.log("payload",payload);
        const response = await postDataLogin(BANNER_API, payload);
      //   const response = responseData?.data;
  
        if (response.statusCode === 200) {
          if (response.message.toLowerCase() === 'success') {
            setSuccess(response.info)
            setBannerData({
                nfp_id: '',
                title: '',
                description: '',
                loc_id: '',
                program_id: '',
                city: 'hyderabad',
              });
              setTimeout(() => {
                navigate('/banner-list');
              }, 2000);
          } else if (response.message.toLowerCase() === 'fail') {
            setError(response.info || 'Response fail');
          } else {
            setError('Unknown Exception');
          //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
          }
        } else if (response.statusCode === 500) {
          setError(response.info || 'Server error');
          // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
        } else {
          setError('Error While Getting Response,Please Contact Admin');
          // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
        }
      } catch (error) {
        setError('No Internet Connection Detected Or Connection Is Slow !');
        const err = String(error);
      //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
      } finally {
        setLoading(false);
      }
  };

  const handleBannerUpdate = async (bannerData,setBannerData) => {
    setLoading(true);
    setError('');

    if (!bannerData.loc_id || bannerData.loc_id === 'NA') {
        setError('Location ID is Missing');
        setLoading(false);
        return;
    }

    if (!bannerData.program_id || bannerData.program_id === 'NA') {
        setError('Program ID is Missing');
        setLoading(false);
        return;
    }
    
    const payload = {
        op: "bannerUpdate",
        banner_id: bannerData.banner_id,
        title: bannerData.title,
        description: bannerData.description,
        city: bannerData.city,
        nfp_id: bannerData.nfp_id,
        loc_id: bannerData.loc_id,
        program_id: bannerData.program_id
    };
    try {
        console.log("payload",payload);
        const response = await postDataLogin(BANNER_API, payload);
      //   const response = responseData?.data;
  
        if (response.statusCode === 200) {
          if (response.message.toLowerCase() === 'success') {
            setSuccess(response.info)
            setBannerData({
                nfp_id: '',
                title: '',
                description: '',
                loc_id: '',
                program_id: '',
                city: 'hyderabad',
              });
              setTimeout(() => {
                navigate('/banner-list');
              }, 2000);
          } else if (response.message.toLowerCase() === 'fail') {
            setError(response.info || 'Response fail');
          } else {
            setError('Unknown Exception');
          //   ExceptionSendService(screenName, 'NFPSuperAdmin-101', response.info || 'Failed to fetch organization data. Please try again.');
          }
        } else if (response.statusCode === 500) {
          setError(response.info || 'Server error');
          // ExceptionSendService(screenName, 'NFPSuperAdmin-102', response.info || 'Internal server error. Please try again later.');
        } else {
          setError('Error While Getting Response,Please Contact Admin');
          // ExceptionSendService(screenName, 'NFPSuperAdmin-103', response.info || 'Error While Getting Response,Please Contact Admin');
        }
      } catch (error) {
        setError('No Internet Connection Detected Or Connection Is Slow !');
        const err = String(error);
      //   ExceptionSendService(screenName, 'NFPSuperAdmin-104', err || 'No Internet Connection Detected Or Connection Is Slow !');
      } finally {
        setLoading(false);
      }
  };

  return {
    loading,
    error,
    setActiveNFPs,
    activeNFPs,
    handleBannerSubmit,
    success,
    handleBannerUpdate
    // handleDetailNFP
  };
};

export default useNFPBannerRegistrationController;


