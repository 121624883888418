import React, { useState } from 'react';
import '../App.css'; // Import the CSS file for styling
import downIcon from './../Assets/upload-2.png'

const CustomDropdown = ({ placeholder, options, selectedValue, onValueChange, type }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value) => {
    onValueChange(value);
    setIsOpen(false);
  };

  const selectedOption = options.find(option => option.value === selectedValue || option.service_id === selectedValue);

  return (
    <div className="dropdown-container" style={{ width: '100%', height: '38px', border: '1px solid #ccc', borderRadius: '4px', boxSizing: 'border-box', padding: '10px', cursor: 'pointer' }}>
      <div className="dropdown" onClick={() => setIsOpen(!isOpen)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
        <span className="dropdown-text" style={{ fontSize: '14px' }}>
          {selectedOption ? selectedOption.label || selectedOption.service_name : placeholder}
        </span>
        <span className="dropdown-icon">
          <img src={downIcon} alt="" className="down-arrow" />
        </span>
      </div>
      {isOpen && (
        <div className={type === 'time' ? 'dropdown-options-time' : "dropdown-options"}>
          {options.map((option) => (
            <div
              key={option.value || option.service_id}
              className="dropdown-option"
              onClick={() => handleSelect(option.value || option.service_id)}
              style={{ padding: '8px', fontSize: '14px' }}
            >
              {option.label || option.service_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
