// export const API_URL = "https://la7q9ktly4.execute-api.us-east-1.amazonaws.com/dev"
export const API_URL = 'https://vaiyv9uk75.execute-api.us-east-1.amazonaws.com/prod'; //prod

// this is for Prod NFP Registration only used Prod nfp registration dont use this url for other
export const PROD_URL ="https://vaiyv9uk75.execute-api.us-east-1.amazonaws.com/prod"


export const SIGNUP_API = "/auth/login" // POST METHOD
export const REGISTRATION_API =  "/auth/registration-v2" // POST METHOD
export const CHANGE_PWD_API =  "/auth/chng-pwd" // POST METHOD
export const FORGOT_PWD_API =  "/auth/forget-password" // POST METHOD
export const RESET_PASSWORD_API =  '/auth/reset-password' //POST METHOD
export const User_REGISTRATION_API =  "/auth/registration"

export const DELETE_ACCOUNT =  '/auth/delete-user' //POST METHOD

export const USER_PROFILE =  '/user-profile/usr-lst' //GET METHOD
export const SEARCH_SERVICES =  '/services/search'; // GET METHOD
export const OPPORTUNITIES_API =  '/fr-lst' //GET METHOD
export const FAR_OPTIONS_LIST ='/fr-list'
export const APPOINTMENT_FIX_API = '/service/appoint-fix';
export const VOLUNTEER_OPPORTUNITIES =  '/auth/voln_reg' //POST METHOD
export const SERVICE_REGISTRATION_API =  '/inventory/invtry1-add-v2';
export const SERVICES_LIST_API =  '/service/srv-lst' //GET METHOD
export const RESULT_API =  '/service/loc-search'; //POST METHOD
export const AUDIO_TRANX =  '/ai-search'; //POST METHOD
export const STRIPE_PAYMENT =  '/stripe-pay'; //POST METHOD
export const DONATIONS_ADD =  '/donation/donation-add'; //POST METHOD 
export const INVENTORY_UPDATE =  '/inventory/inventory-upt-v2'  //GET METHOD
export const INVENTORY_LIST =  '/inventory/inventory-lst-v2' //GET METHOD
export const REQUEST_LIST =  '/service/subcat-srv-reqst-list' //POST METHOD


export const EXCEPTION_STORE =  '/exception-store'; //POST METHOD
export const NFP_DETAILS =  '/nfp-details'; //GET METHOD
export const SERVICE_UPDATE_API =  '/service/srv-upd'; //GET METHOD
export const NFP_DETAILS_API = '/NFP/cat-list' //POST METHOD
export const NFP_LIST_API = '/NFP/nfp-list' //POST METHOD
export const NFP_STATUS_API =  '/NFP/status-list-v2' // POST METHOD
export const NFP_STATUS_UPDATE =  '/NFP/status-upd' //POST METHOD
export const HELP_SOMEONE =  '/inventory/invtry1-add' //POST METHOD
export const GOODS_DONATION_API =  '/goods-donation/donation-add'; //POST METHOD
export const DONATION_LIST_API = '/goods-donation/donation-lst'; //POST METHOD
export const FEEDBACK_API_URL ='/feedback/fb-list'

export const BULK_NFPS_ADD ='/json/data-uploading' ;//POST METHOD
export const BANNER_API = '/banner/banner'