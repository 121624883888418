
import React from 'react';
import '../App.css';

const Support = () => {
  return (
    <div className="support-container">
      <div className="support-content">
        <h1>Support</h1>

        <div className="support-info">
          <h2>Address:</h2>
          <p>8837 Whitney Dr</p>
          <p>Lewis Center, OH 43035</p>
        </div>

        <div className="support-info">
          <h2>Phone:</h2>
          <p>(+1) 614 218 8735</p>
        </div>

        <div className="support-info">
          <h2>Email:</h2>
          <p><a href="mailto:info@datalake-solutions.com">info@datalake-solutions.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default Support;
