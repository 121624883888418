import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validatePassword} from '../../../utils/validation';
import { CHANGE_PWD_API } from '../../../ApiServices/BaseURL';
import { postDataLogin } from '../../../ApiServices/ApiService';
// import ExceptionSendService from '../../ApiServices/ExceptionSendService';

const useChangePasswordController = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, tempPassword } = location.state || {};
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const screenName = 'ChangePassword';

  const handleNewPasswordChange = (text) => {
    setNewPassword(text);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changePassword = async () => {
    try {
      setError('');
      if (!validatePassword(newPassword)) {
        setError('Password must be at least 8 characters long and include a number, an uppercase letter, and a special character.');
        return;
      }
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      const payLoad = {
        email_id: email,
        new_password: newPassword,
        temp_password: tempPassword,
      };
      setLoading(true);
      const response = await postDataLogin(CHANGE_PWD_API, payLoad);
    //   const response = responseData.data;
      setLoading(false);
      if (response.statusCode === 200) {
        if (response.message === 'success') {
            navigate('/login');
        } else {
          setError(response.info);
        //   ExceptionSendService(screenName, 'ChangePass-101', response.info);
        }
      } else if (response.statusCode === 500) {
        setError(response.info);
        // ExceptionSendService(screenName, 'ChangePass-102', response.info);
      } else {
        setError('Error While Getting Response, Please Contact Admin');
        // ExceptionSendService(screenName, 'ChangePass-103', 'Error While Getting Response, Please Contact Admin');
      }
    } catch (error) {
      setLoading(false);
      const err = String(error);
    //   ExceptionSendService(screenName, 'ChangePass-104', err);
      setError('No Internet Connection Detected Or Connection Is Slow!');
    }
  };

  return {
    email,
    tempPassword,
    newPassword,
    confirmPassword,
    error,
    showPassword,
    showConfirmPassword,
    loading,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    toggleShowPassword,
    toggleShowConfirmPassword,
    changePassword,
  };
};

export default useChangePasswordController;
