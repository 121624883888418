// src/LoginScreen.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextArea from '../../../Components/LoginInput';
import Loader from '../../../Components/Loader';
import LoginController from '../../Controllers/AuthenticationPages/LoginController';
import './../../../App.css'
import FareverImg from '../../../Assets/farever.png'

import HeaderNavBarLogin from '../../../Components/HeaderNavBarLogin';
import Footer from '../../../Components/Footer';

const LoginScreen = () => {
    // const history = useHistory();
    const navigate = useNavigate();
    const {
        email,
        password,
        error,
        showPassword,
        loading,
        handleEmailChange,
        handlePasswordChange,
        toggleShowPassword,
        login,
        emailError
    } = LoginController();

    useEffect(() => {
        const backAction = (e) => {
            e.preventDefault();
        };

        window.addEventListener('popstate', backAction);

        return () => window.removeEventListener('popstate', backAction);
    }, []);

    return (
        <>
            <HeaderNavBarLogin />
            <div className="login-container">



                <div className='loginDiv'>
                    <img src={FareverImg} alt="Farever Logo" className="login-image" />
                    <h2 className="login-title">Farever</h2>
                    <p className="bold-text">© UpCorps LLC</p>
                    <p className="bold-text">Powered By Datalake-Solutions</p>
                    <div className="input-container">
                        <TextArea
                            placeholder="Email"
                            value={email || ''} // Ensure value is a string
                            onChange={(e) => handleEmailChange(e.target.value)}
                            style={{ marginBottom: '1rem' }}
                        />
                        <TextArea
                            placeholder="Password"
                            value={password || ''} // Ensure value is a string
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            right={
                                <button onClick={toggleShowPassword} className="toggle-button">
                                    {showPassword ? (
                                        <img src={require('../../../Assets/remove-eye.png')} alt="Hide " />
                                    ) : (
                                        <img src={require('../../../Assets/eye.png')} alt="Show " />
                                    )}
                                </button>
                            }
                        />
                    </div>
                    <div className="action-container">
                        <button onClick={() => navigate('/forgot-password')} className="link-button">Forgot your Password?</button>
                        <button onClick={() => navigate('/registration')} className="link-button"> Sign Up</button>
                        {/* <button onClick={() => navigate('/prod-registration')} className="link-button"> Prod Sign Up</button> */}
                    </div>
                    {error && <p className="error-text">{error}</p>}
                    {emailError && <p className="error-text">{emailError}</p>}
                    <button onClick={login} className="sign-in-button"> Sign In</button>
                    <Loader loading={loading} color={'#FFFFFF'} />
                </div>
                <Footer></Footer>
            </div>
        </>
    );
};

export default LoginScreen;
