export class FeedBackModel {
    constructor(data) {
      this.city = data.city || '';
      this.feedbackDate = data.feedback_date || '';
      this.ratings = data.rantings || 0;
      this.comments = data.comments || '';
      this.email = data.email || '';
      this.name = data.name || '';
      this.nfpId = data.nfp_id || '';
    }
  }
  