// Controllers/NFPDashboardController.js
import { useState, useEffect } from 'react';
import { NFPDashboardModel } from '../Models/NFPDashboardModel';
import { NFP_DETAILS } from '../../ApiServices/BaseURL';
import { postDataLogin, getData,getParamsData } from '../../ApiServices/ApiService';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';


const useNFPDashboardController = () => {
  const [organization, setOrganization] = useState(new NFPDashboardModel());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const screenName = 'NFPDashboardScreen';

  useEffect(() => {
    const fetchOrganizationData = async () => {
      setError('');
      setSuccess('');
      setLoading(true);

      try {
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        console.log("admindetailsParsed DC", admindetailsParsed)
        const NFP_ID = admindetailsParsed?.nfp_id;
        const programId =admindetailsParsed?.program_id || '';
        const locId=admindetailsParsed?.loc_id || '';

        const userIDAuth = admindetailsParsed?.user_id || '';

        const params = { nfp_id: NFP_ID,program_id:programId,loc_id:locId ,user_id_auth:userIDAuth };
        //const params = { user_id_auth:userIDAuth };

        // console.log("NFP payload", params);

        const response = await getParamsData(NFP_DETAILS, params );
        // console.log("NFP Response", response.data);

        const responseData = await response.json();

        if (response?.status === 200) {
          if (responseData?.message === 'success') {
            const organizationData = responseData.dataJ[0];
            const fetchedOrganization = new NFPDashboardModel(
              organizationData?.organization_name,
              organizationData?.address,
              organizationData?.nfp_phone,
              organizationData?.nfp_email,
              `https://www.google.com/maps?q=${organizationData?.latitude || '17.4435'},${organizationData?.longitude || '78.3772'}`,
              organizationData?.availability,
              organizationData?.operating_hrs_list,
              organizationData?.latitude,
              organizationData?.longitude
            );
            setOrganization(fetchedOrganization);
            setSuccess('Organization data fetched successfully!');
          } else {
            setError(responseData?.info || 'Failed to fetch organization data. Please try again.');
            // ExceptionSendService(screenName, 'NFPDash-101', responseData?.info || 'Failed to fetch organization data. Please try again.')
          }
        } else if (response?.status === 400) {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        //   ExceptionSendService(screenName, 'NFPDash-105', responseData?.info || 'Bad request. Please check the request parameters.')
        } else if (response?.status === 500) {
          setError(responseData?.info || 'Internal server error. Please try again later.');
        //   ExceptionSendService(screenName, 'NFPDash-102', responseData?.info || 'Internal server error. Please try again later.')
        } else {
          setError('No Internet Connection Detected Or Connection Is Slow');
        //   ExceptionSendService(screenName, 'NFPDash-103', 'No Internet Connection Detected Or Connection Is Slow')
        }
      } catch (error) {
        let err = String(error)
        // ExceptionSendService(screenName, 'NFPDash-104', err)
        setError('Failed to fetch organization data. Please try again later.');
        console.error('Error fetching organization data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, []);



  return { organization, loading, error };
};

export default useNFPDashboardController;