// src/views/NFPPage/NFPDashboardScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useNFPDashboardController from '../Controllers/NFPDashboardController';
import HeaderNavBar from '../../Components/HeaderNavBar';
import Footer from '../../Components/Footer';
import Loader from '../../Components/Loader';
import './../../App.css';
import addUserIcon from '../../Assets/add-user.png';
import recycleIcon from '../../Assets/recycle.png';
import customerIcon from '../../Assets/customer-support.png';
import resourceIcon from '../../Assets/resource.png';
import donationIcon from '../../Assets/food-donation.png';
import callIcon from '../../Assets/call.png';
import phoneIcon from '../../Assets/icons/phone-call-icon.png';
import emailIcon from '../../Assets/email-black.png';
import calendarIcon from '../../Assets/google-calendar.png';
import houseIcon from "../../Assets/icons/house-blue-icon.png";

const NFPDashboardScreen = () => {
  const { organization, loading, error } = useNFPDashboardController();
  const navigate = useNavigate();

  const buttonData = [
    { title: 'Inventory Management', image: recycleIcon, navigateTo: 'NFPInventoryListScreen' },
    { title: 'User Management', image: addUserIcon, navigateTo: 'Registration' },
    { title: 'Donation List', image: donationIcon, navigateTo: 'DonationList' }
  ];

  const handleNavigation = async (navigateTo) => {
    try {
      const admindetails = localStorage.getItem('_FAREVER_ADMIN_');
      const admindetailsParsed = JSON.parse(admindetails);
      const nfp_id = admindetailsParsed?.nfp_id;
      const user = "admin"; // admindetailsParsed?.type;

      if (navigateTo) {
        if (navigateTo === 'Registration') {
          if (user === 'admin') {
            navigate('/user-registration', { state: { nfp_id: nfp_id } });
          } else {
            // navigate('Only admin can access User Management');
          }
        } else if (navigateTo === 'NFPInventoryListScreen') {
          navigate('/NFPInventoryList', { state: { organizationName: organization.name, latitude: organization.latitude, longitude: organization.longitude } });
        } else if (navigateTo === 'NFPRequestListScreen') {
          if (user === 'admin') {
            navigate(`/requests/${organization.name}`);
          } else {
            console.log('Only admin can access User Management');
          }
        } else if (navigateTo === 'DonationList') {
          if (user === 'admin') {
            navigate('/DonationList');
          } else {
            console.log('Only admin can access User Management');
          }
        } else {
          if (user === 'admin') {
            navigate(`/${navigateTo}`);
          } else {
            console.log('Only admin can access this feature');
          }
        }
      }
    } catch (error) {
      console.error('Failed to navigate:', error);
    }
  };

  return (
    <>
      <HeaderNavBar />
      <div className="safe-area">
        <div className="DL-container">
          {loading ? (
            <Loader color="#fff" loading={loading} />
          ) : (
            <>
              {!error && (
                <>
                  <div className="welcome-container" style={{ marginBottom: '10px' }}>
                    <h1 className="welcome-text" style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginBottom: '5px' }}>
                      {organization.name}
                    </h1>
                    <div className="contact-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <img src={phoneIcon} className="contact-icon" alt="Phone" style={{ marginRight: '5px' }} />
                      <p className="text" style={{ margin: '0', fontSize: '16px', color: '#rgb(119 117 117)' }}>
                        {organization.nfp_phone}
                      </p>
                    </div>

                    <div className="contact-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <img src={houseIcon} className="contact-icon" alt="Address" style={{ marginRight: '5px' }} />
                      <p className="text" style={{ margin: '0', fontSize: '16px', color: '#rgb(119 117 117)' }}>
                        {organization.address}
                      </p>
                    </div>
                  </div>

                  <div className="section" style={{ marginBottom: '10px' }}>
                    <h2 className="label" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', color: '#333', paddingTop: '20px' }}>
                      Available Timings
                    </h2>
                    <div className="card" style={{ padding: '10px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                      <div className="availability-container" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {organization.operating_hrs_list.map((hour, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <img
                              src={calendarIcon}
                              alt="Calendar"
                              style={{ marginRight: '8px', width: '20px', height: '20px' }}
                            />
                            <p className="text" style={{ margin: '0', fontSize: '16px', color: '#555' }}>
                              {hour.day}: {hour.start_time} - {hour.end_time}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {error && <p className="error-text">{error}</p>}
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default NFPDashboardScreen;
