import { useState } from 'react';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { FORGOT_PWD_API } from '../../../ApiServices/BaseURL';
import { ForgotModel } from '../../Models/AuthenticationPages/ForgotPasswordModel';
// import ExceptionSendService from '../../ApiServices/ExceptionSendService';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordController = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const screenName = 'ForgotPasswordScreen';
  const navigate = useNavigate();

  const handleResetPassword = () => {
    navigate('/reset-password', { state:{email:email} });
  };

  const handleSubmit = async () => {
    setError('');
    if (!email) {
      setError('Please Enter Email');
      return;
    }
    if (!new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
      setError('Please Enter Valid Email');
      return;
    }
    console.log("EMAIL", email);
    try {
      setLoading(true);
      const payload = new ForgotModel(email);
      console.log('payload', payload);
      const response = await postDataLogin(FORGOT_PWD_API, payload);
    //   console.log("responseData responseURL", responseData.responseURL);
    //   if (!responseData) {
    //     throw new Error('Request failed');
    //   }
    //   const response = responseData.data;
      console.log("response", response);
      if (response.statusCode === 200) {
        if (response?.message.toLowerCase() === 'success') {
          setLoading(false);
          setSuccess(true);
          console.log("Success", response?.info);
        } else if (response?.message.toLowerCase() === 'fail') {
          setLoading(false);
          setError(response?.info);
          console.log("Fail", response?.info);
        //   ExceptionSendService(screenName, 'ForgotPass-101', response?.info);
        } else if (response.statusCode === 500) {
          setLoading(false);
          setError(response?.info);
          console.log("Server error", response?.info);
        //   ExceptionSendService(screenName, 'ForgotPass-102', response?.info);
        }
      } else {
        setError(response?.info || 'Error While Getting Response,Please Contact Admin');
        setLoading(false);
        console.log("Error response", response?.info);
        // ExceptionSendService(screenName, 'ForgotPass-103', response?.info);
      }
    } catch (error) {
      setLoading(false);
      let err = String(error);
    //   ExceptionSendService(screenName, 'ForgotPass-104', err);
      setError('No Internet Connection Detected Or Connection Is Slow');
      console.error('Request failed catch', error);
    }
  };

  return {
    email,
    error,
    success,
    loading,
    setEmail,
    handleSubmit,
    handleResetPassword
  };
};

export default ForgotPasswordController;
