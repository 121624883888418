import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNFPBannerController from '../Controllers/NFPBannerListController';
import Loader from '../../Components/Loader';
import SAHeaderNavBar from '../../Components/SAHeaderNavBar';
import Footer from '../../Components/Footer';

const NFPBannerListScreen = () => {
    const { loading, error, bannerList, handleBannerUpdate } = useNFPBannerController();
    const [expandedBannerId, setExpandedBannerId] = useState(null);
    const navigate = useNavigate();
    const handleEdit = (banner) => {
        navigate('/banner-registration', { state: { banner } });
    };

    const handleToggle = (banner) => {
        const updatedBanner = {
            ...banner,
            active: banner.active === 1 ? 0 : 1, // Toggle active status
        };

        handleBannerUpdate(updatedBanner); // Call the update function with the toggled banner
    };

    const toggleDescription = (bannerId) => {
        setExpandedBannerId(expandedBannerId === bannerId ? null : bannerId);
    };

    const truncateDescription = (description, bannerId) => {
        if (expandedBannerId === bannerId) {
            return (
                <>
                    {description}
                    <span
                        onClick={() => toggleDescription(bannerId)}
                        style={{ color: '#007BFF', cursor: 'pointer', marginLeft: '5px' }}
                    >
                        ...less
                    </span>
                </>
            );
        } else {
            return (
                <>
                    {description.slice(0, 50)}
                    {description.length > 50 && (
                        <span
                            onClick={() => toggleDescription(bannerId)}
                            style={{ color: '#007BFF', cursor: 'pointer', marginLeft: '5px' }}
                        >
                            ...more
                        </span>
                    )}
                </>
            );
        }
    };

    return (
        <div>
            {/* Fixed HeaderNavBar */}
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
                <SAHeaderNavBar />
            </div>

            {/* Content with padding to avoid overlap with fixed header */}
            <div style={{ paddingTop: '70px', padding: '20px' }}>
                {/* Heading and "+" button */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', marginTop: '40px' }}>
    <div style={{ flex: 1, textAlign: 'center' }}>
        <h1 style={{ fontSize: '24px', margin: 0 }}>Manage Banners</h1>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px',marginRight:'12px' }}>
    <span 
      style={{ 
        color: '#1e90ff', 
        fontSize: '16px', 
        fontWeight: 'bold', 
        cursor: 'pointer',
        marginRight: '1px',
        textDecoration: 'underline',
      }} 
      onClick={() => navigate('/banner-registration')}
    >
      Add Banner
    </span>
        <button
            style={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                fontSize: '24px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={() => navigate('/banner-registration')}
        >
            +
        </button>
    </div>
</div>


                {loading && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}>
                        <Loader loading={loading} />
                    </div>
                )}

                {!loading && !error && (
                    <div className="banner-list-container" style={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: '20px', 
                        justifyContent: 'flex-start', // Ensure all rows start from the left
                        alignItems: 'flex-start' // Align items at the start to avoid any gaps
                    }}>
                        {bannerList.length === 0 ? (
                            <p>No banners available.</p>
                        ) : (
                            bannerList.map((banner) => (
                                <div key={banner.banner_id} className="banner-item" style={{
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    padding: '15px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    backgroundColor: '#fff',
                                    width: 'calc(30% - 20px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    marginLeft:'20px'
                                }}>
                                    <div>
                                    <h3 style={{ marginBottom: '10px', marginTop: '20px' }}>
                                        <strong>Title: </strong> 
                                        <span style={{ color: '#177fda' }}>{banner.title}</span>
                                    </h3>
                                        <p style={{ marginBottom: '10px' }}>
                                            <strong>Description:</strong> {truncateDescription(banner.description, banner.banner_id)}
                                        </p>
                                        <p style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span><strong>City:</strong> {banner.city}</span>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <button
                                                    style={{
                                                        padding: '4px 8px',
                                                        backgroundColor: '#007BFF',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleEdit(banner)}
                                                >
                                                    Edit
                                                </button>
                                                <label className="switch">
                                                    <input
                                                        type="checkbox"
                                                        checked={banner.active === 1} // Toggle automatically reflects banner.active
                                                        onChange={() => handleToggle(banner)} // Trigger the toggle action
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}

                {error && <p className="error-text">{error}</p>}
            </div>

            <Footer />
        </div>
    );
};

export default NFPBannerListScreen;
