export class userRegistrationModel {
    constructor(
      country_code,
      email_id,
      // gender_id,
      user_address,
      user_address2,
      user_city,
      user_name,
      user_phone,
      user_role,
      zip_code,
      type,
      nfp_id,
      loc_id,
      program_id
    ) {
      this.action = "register_user";
      this.country_code = "+1";
      this.email_id = email_id;
      // this.gender = gender_id;
      this.user_address = user_address;
      this.user_address2 = user_address2;
      this.user_city = user_city;
      this.user_name = user_name;
      this.user_phone = user_phone;
      this.user_role = user_role;
      this.zip_code = zip_code;
      this.type = type;
      this.nfp_id = nfp_id;
      this.loc_id = loc_id;
      this.program_id = program_id
    }
  }
  
  