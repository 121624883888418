import { API_URL,PROD_URL } from "./BaseURL";

async function postDataLogin(endpoint, data) {
    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            // body: data,
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error posting data:', error);
    }
}
async function getData(endpoint, data ) {
    try {
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data,
      });
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function postData(endpoint, data) {
    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            // body: data,
        });

        // const responseData = await response.json();
        return response;
    } catch (error) {
        console.error('Error posting data:', error);
    }
}

async function getParamsData(endpoint, params) {
    try {
        // Convert params object to a query string
        const queryString = new URLSearchParams(params).toString();
        
        const response = await fetch(`${API_URL}${endpoint}?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


async function prodDataLogin(endpoint, data) {
  try {
      const response = await fetch(`${PROD_URL}${endpoint}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
          // body: data,
      });

      const responseData = await response.json();
      return responseData;
  } catch (error) {
      console.error('Error posting data:', error);
  }
}
export { postDataLogin, getData, postData,prodDataLogin,getParamsData};