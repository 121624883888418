import { useState, useEffect,useContext } from 'react';
import { prodDataLogin,postData } from '../../../ApiServices/ApiService';
import { REGISTRATION_API, FAR_OPTIONS_LIST, BULK_NFPS_ADD } from '../../../ApiServices/BaseURL';
import { validateEmail, TimeValidation } from '../../../utils/validation';
import { ProdNFPRegistrationModel } from '../../Models/AuthenticationPages/ProdNFPRegistrationModel';
import { useNavigate } from 'react-router-dom';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';
import { LocationContext } from '../../../Components/LocationContext';

const parseTime = (time) => {
  const [timePart, meridiem] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);
  if (meridiem === 'PM' && hours < 12) hours += 12;
  if (meridiem === 'AM' && hours === 12) hours = 0;
  return hours * 60 + minutes;
};

const validateAndFormatTime = (time, meridiem) => {
  const timeWithMeridiem = `${time} ${meridiem}`;
  const fullTimeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/i;

  console.log("timeWithMeridiem:", timeWithMeridiem); // Added logging
  console.log("fullTimeRegex:", fullTimeRegex); // Added logging

  if (fullTimeRegex.test(timeWithMeridiem)) {
    return timeWithMeridiem.toUpperCase();
  } else {
    console.log("Time is invalid:", timeWithMeridiem); // Added logging
    return false;
  }
};

const checkOverlap = (start1, end1, start2, end2) => {
  const startTime1 = parseTime(start1);
  const endTime1 = parseTime(end1);
  const startTime2 = parseTime(start2);
  const endTime2 = parseTime(end2);

  console.log(`Checking overlap between ${start1} - ${end1} and ${start2} - ${end2}`); // Added logging

  return startTime1 < endTime2 && startTime2 < endTime1;
};

const convertTo24HourFormat = (hour, minute, meridiem) => {
  let formattedHour = parseInt(hour, 10);
  const formattedMinute = parseInt(minute, 10);
  
  if (meridiem === 'PM' && formattedHour !== 12) {
    formattedHour += 12;
  } else if (meridiem === 'AM' && formattedHour === 12) {
    formattedHour = 0;
  }
  
  return formattedHour * 60 + formattedMinute; // Return total minutes since midnight
};

const useProdNFPRegistrationController = (setIsDropdownOpen) => {

    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categories, setCategories] = useState({});
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [operatingDay, setOperatingDay] = useState('');
  const [operatingStartTime, setOperatingStartTime] = useState('');
  const [operatingEndTime, setOperatingEndTime] = useState('');
  const [donatingDay, setDonatingDay] = useState('');
  const [donatingStartTime, setDonatingStartTime] = useState('');
  const [donatingEndTime, setDonatingEndTime] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [nfp_country_code, setNfpCountryCode] = useState('+1');
  const [contact_country_code, setContactCountryCode] = useState('+1');
  const [timeError, setTimeError] = useState('');
  const [dtimeErr, setdtimeError] = useState('');
  const [frOptions,setFrOptions] =useState({})
  const [operatingStartHour, setOperatingStartHour] = useState('');
  const [operatingStartMinute, setOperatingStartMinute] = useState('');
  const [operatingStartMeridiem, setOperatingStartMeridiem] = useState('AM');
  const [operatingEndHour, setOperatingEndHour] = useState('');
  const [operatingEndMinute, setOperatingEndMinute] = useState('');
  const [operatingEndMeridiem, setOperatingEndMeridiem] = useState('PM');
  const [programs, setPrograms] = useState([]);
  const [programName, setProgramName] = useState('');
  const [programLatitude, setProgramLatitude] = useState('');
  const [programLongitude, setProgramLongitude] = useState('');
  const [nfpData, setNfpData] = useState({
    organization_name: '',
    address: '',
    city: '',
    zipCode: '',
    contact_person_details: [{ contact_name: '', contact_email: '', contact_phone: '' }],
    nfp_phone: '',
    nfp_email: '',
    services_list: [],
    nfp_activites: '',
    operating_hrs_list: [],
    donating_hrs_list: [],
    volunteer_pos: '',
    volunteer_des: '',
    donation_link: '',
    bank_name: '',
    account_no: '',
    routing_no: '',
    registration_date: '',
    register_by: '',
    last_updated_at: '',
    fr_member_since: '',
    nfp_tz: "",
    latitude:'',
    longitude:'',
    nfp_loc_list: []
  });
  const { location } = useContext(LocationContext);
  const [excelFileData, setExcelFileData] = useState([]);
  const [uploadingExcelData, setUploadingExcelData] = useState([]);
  const [bulkMenuDivLoader, setBulkMenuDivLoader] = useState(false);


  const handleAddProgram = (loc_id) => {
    if (!loc_id) {
      setError('Please select a location before adding a program.');
      return; 
    }
  
    if (
      !programName ||
      !programLatitude ||
      !programLongitude ||
      !nfpData.services_list.length ||
      !nfpData.operating_hrs_list.length ||
      !nfpData.contact_person_details[0].contact_name ||
      !nfpData.contact_person_details[0].contact_email ||
      !nfpData.contact_person_details[0].contact_phone ||
      !nfpData.nfp_activites
    ) {
      setError('All fields (Program Name, Latitude, Longitude, Services, Operating Hours, Contact Person Details, Main Function Specifics) are required.');
      return;
    }
  
    const timestamp = Date.now();
    const newProgramId = `prog-${timestamp}`;
    console.log("newProgramId", newProgramId);
  
    const newProgram = {
      id: newProgramId, 
      program_name: programName,
      latitude: programLatitude,
      longitude: programLongitude,
      loc_id,
      nfp_activities: nfpData.nfp_activites, 
      services_list: [...nfpData.services_list],
      operating_hrs_list: [...nfpData.operating_hrs_list], 
      contact_person_details: [...nfpData.contact_person_details],
    };
  
    setPrograms((prevPrograms) => [...prevPrograms, newProgram]);
  
    // Clear the inputs after adding
    setProgramName('');
    setProgramLatitude('');
    setProgramLongitude('');
    handleChange('services_list', []);
    handleChange('operating_hrs_list', []);
    handleChange('contact_person_details', [{ contact_name: '', contact_email: '', contact_phone: '' }]);
    setError(''); 
  };
  
  

  const handleAddLocation = () => {
    const { location_name, address, city, nfp_loc_list } = nfpData;
    if (!location_name || !address || !city) {
      setError('Please provide location name, address, and city.');
      return;
    }
  
    if (location_name && address && city) {
      const timestamp = Date.now();
  
      const newLocation = {
        id: `loc-${timestamp}`,
        location_name,
        address,
        city,
        nfp_id: '', 
        donating_hrs_list: []
      };
      
      setNfpData(prevData => ({
        ...prevData,
        nfp_loc_list: [...prevData.nfp_loc_list, newLocation],
        location_name: '', 
        address: '',
        city: ''
      }));
    } else {
      setError('Please provide location name, address, and city.');
    }
  };


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // console.log('fetchCategories res get Data:');return
        const payload ={
          op: "far_opt_list"
        }
        setLoading(true);
        console.log("url",FAR_OPTIONS_LIST)
        await prodDataLogin(FAR_OPTIONS_LIST,payload).then
            (respData => {
                // console.log('fetchCategories res Data:',respData);return
                const data = respData?.dataJ[0];
                delete data?.Roles; // Remove Roles category
                delete data?.Emergency;
                setCategories(data);
                setFrOptions(data);
                setLoading(false);
            });

        
       
      } catch (error) {
        setError('Failed to fetch categories');
        setLoading(false);
      }
    };
    // const fetchLocation = async () => {
    //   try {
    //     const lat = 17.4482647 //await localStorage.getItem('latitude');
    //     const lon = 78.3879964 //await localStorage.getItem('longitude');
    //     setLatitude(lat);
    //     setLongitude(lon);
    //   } catch (error) {
    //     console.error('Failed to fetch location:', error);
    //   }
    // };

    fetchCategories();
    // fetchLocation();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [category]: prevSelectedCategories[category] || [],
    }));
    setSelectedCategory(category); // Show the newly selected category's items
  };

  const handleItemChange = (category, item) => {
    setSelectedCategories((prevSelectedCategories) => {
      const categoryItems = prevSelectedCategories[category] || [];
      const itemExists = categoryItems.some((i) => i.id === item.service_id);
      const updatedItems = itemExists
        ? categoryItems.filter((i) => i.id !== item.service_id)
        : [...categoryItems, { id: item.service_id, name: item.service_name }]; // Include service_name
      return { ...prevSelectedCategories, [category]: updatedItems };
    });
  };

  const handleChange = (field, value) => {
    setNfpData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  
    if (field === 'nfp_activites') {
      setPrograms((prevPrograms) => prevPrograms.map((program) => ({
        ...program,
        nfp_activities: value,
      })));
    }
  };

  const handleContactChange = (field, value) => {
    setNfpData((prevData) => ({
      ...prevData,
      contact_person_details: [{
        ...prevData.contact_person_details[0],
        [field]: value,
      }],
    }));
  };

  const handleServiceChange = (service) => {
    console.log('servie:',service)
    setNfpData((prevData) => {
      const services = prevData.services_list.includes(service)
        ? prevData.services_list.filter((s) => s !== service)
        : [...prevData.services_list, service];
      return { ...prevData, services_list: services };
    });
  };

  const handleAddOperatingHour = () => {
    console.log("time", operatingStartTime)
    setTimeError('')
    const formattedStartTime = `${operatingStartHour}:${operatingStartMinute} ${operatingStartMeridiem}`;
    const formattedEndTime = `${operatingEndHour}:${operatingEndMinute} ${operatingEndMeridiem}`;

    if (!operatingDay || !operatingStartHour || !operatingStartMinute || !operatingEndHour || !operatingEndMinute) {
      setTimeError('Please provide valid day and timings.');
      return;
    }
  
    const start = convertTo24HourFormat(operatingStartHour, operatingStartMinute, operatingStartMeridiem);
    const end = convertTo24HourFormat(operatingEndHour, operatingEndMinute, operatingEndMeridiem);
  
    if (end <= start) {
      setTimeError('Start time must be earlier than end time.');
      return;
    }

    const isDuplicate = nfpData.operating_hrs_list.some(
      (hour) =>
        hour.day === operatingDay &&
        checkOverlap(hour.start_time, hour.end_time, formattedStartTime, formattedEndTime)
    );

    if (isDuplicate) {
      setTimeError('Duplicate entry.');
      return;
    }

    setNfpData((prevData) => ({
      ...prevData,
      operating_hrs_list: [
        ...prevData.operating_hrs_list,
        { day: operatingDay, start_time: formattedStartTime, end_time: formattedEndTime },
      ],
    }));

    setIsDropdownOpen(false)
  };

  const handleAddDonationHour = () => {
    setdtimeError('')
    if (donatingStartTime === "" || donatingEndTime === "" || donatingDay === "") {
      setdtimeError('Please provide the day, start time, and end time');
      return
    }
    if (!TimeValidation(donatingStartTime, donatingEndTime)) {
      setdtimeError('Ensure times are within 24 hours and end time is after start time');
      return;
    }
    setNfpData((prevData) => ({
      ...prevData,
      donating_hrs_list: [
        ...prevData.donating_hrs_list,
        { day: donatingDay, start_time: donatingStartTime, end_time: donatingEndTime },
      ],
    }));
  };

  const validateInputs = () => {
    if (!nfpData.organization_name) {
      setError('NFP Name is required.');
      return false;
    }
  
    if (!nfpData.zipCode) {
      setError('ZIP Code is required.');
      return false;
    }
  
    if (!nfpData.nfp_phone) {
      setError('NFP Phone Number is required.');
      return false;
    }
  
    if (!nfpData.register_by) {
      setError('Register By is required.');
      return false;
    }
  
    // Ensure at least one location is added
    if (nfpData.nfp_loc_list.length === 0) {
      setError('At least one location must be added.');
      return false;
    }
  
    // Validate each location's fields
    for (const location of nfpData.nfp_loc_list) {
      if (!location.address) {
        setError('Location Address is required.');
        return false;
      }
  
      if (!location.city) {
        setError('Location City is required.');
        return false;
      }

    }
  
    // Ensure at least one program is added
    if (programs.length === 0) {
      setError('At least one program must be added.');
      return false;
    }
  
    // Validate each program's fields
    for (const program of programs) {
      // if (!program.loc_id) {
      //   setError('Location ID is required for each program.');
      //   return false;
      // }
  
      if (!program.program_name) {
        setError('Program Name is required.');
        return false;
      }
  
      if (!program.latitude || !program.longitude) {
        setError('Program Latitude and Longitude are required.');
        return false;
      }
  
      if (!program.contact_person_details[0].contact_name || 
          !program.contact_person_details[0].contact_email || 
          !program.contact_person_details[0].contact_phone) {
        setError('Contact Person details (Name, Email, Phone) are required.');
        return false;
      }
  
      if (!program.services_list.length) {
        setError('At least one service must be selected.');
        return false;
      }
  
      if (!program.operating_hrs_list.length) {
        setError('Operating Hours are required.');
        return false;
      }
  
      if (!program.nfp_activities) {
        setError('Main Function Specifics are required.');
        return false;
      }
    }
  
    return true;
  };
  

  const handleSubmit = async (selectedLocId) => {
    console.log('Submitting form'); // Add log here
    setError('');
    setSuccess('');
    if (!validateInputs()) {
      return;
    }

    const programsWithIds = programs.map((program) => {
      const prog_id = program.id; // Use the 'id' from the program object as 'prog_id
      
      return {
        ...program,
        loc_id: selectedLocId, // Include the selected loc_id in the program
        prog_id: prog_id // Pass the stored prog_id to the payload
      };
    });


    const nfpRegistrationModel = new ProdNFPRegistrationModel(
      nfpData.organization_name,
      nfpData.nfp_phone,
      nfpData.donation_link,
      nfpData.fr_member_since,
      nfpData.register_by,
      nfpData.registration_date,
      nfpData.zipCode,
      nfpData.nfp_loc_list, // Pass the list of locations here
      programsWithIds
    );
  
    const payload = nfpRegistrationModel.toNFPRegistrationPayload();
  
    console.log('Payload:', JSON.stringify(payload, null, 2));

    console.log('Validating inputs'); // Add log here
    console.log('payload:', payload); // Add log here
    console.log("selectedLocId", selectedLocId)
    // return

    setLoading(true);
    try {
      const response = await prodDataLogin(REGISTRATION_API, payload);
      const responseData = await response;
      console.log("response",response)
      if (responseData.statusCode === 200) {
        if (responseData.message === 'success') {
          setSuccess('Registration successful');
          setTimeout(() => {
            navigate('/Login');
          }, 1000);
        } else if (responseData.message === 'fail') {
          setError(responseData.info || 'NFP registration failed');
        } else {
          setError('Unknown error occurred');
        //   ExceptionSendService('NFPReg', 'nfpReg-101', responseData.info);
        }
      } else if (responseData.statusCode === 500) {
        setError(responseData.info || 'Internal server error');
        // ExceptionSendService('NFPReg', 'nfpReg-102', responseData.info);
      } else {
        console.log("no response", response.info)
        setError('Network Slow');
        // ExceptionSendService('NFPReg', 'nfpReg-103', 'No Response');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Network Error:', error);
      setError('Network Slow or Internet Connection Problem');
    //   ExceptionSendService('NFPReg', 'nfpReg-104', String(error));
    }

  };

  const handleFileUpload = async (file) => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Append the file to FormData

    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
  }

    return

    setLoading(true);
    try {
      const response = await prodDataLogin('/api/nfp/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });

      const responseData = await response;

      if (responseData.statusCode === 200) {
        if (responseData.message === 'success') {
          setSuccess('File uploaded successfully!');
        } else {
          setError('File upload failed.');
        }
      } else if (responseData.statusCode === 500) {
        setError('Internal server error');
      } else {
        setError('Unexpected error occurred');
      }
    } catch (error) {
      setError('Network error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const DataFromEventEmitter = async (data) => {
    console.log("DataFromEventEmitter", data);

    if (data.length === 0) {
      alert("Unable to read the Excel, Please check the format and data.");
      return;
    }
    if (data.length > 500) {
      alert("Maximum 500 records allowed.");
      return;
    }if(data.loc_id === undefined){
      setError("loc_id is mandatory ,example loc-1");
      return
    }if(data.program_id === undefined){
      setError("program_id is mandatory ,example prog-1");
      return
    }
    if(data.organization_name === undefined){
      setError("please give organization name");
      return
    }
    if(data.contact_email === undefined){
      setError("please give contact email");
      return
    }
  

    const payload = {
      "nfpjson_list": data,
    };

    console.log("Payload:", JSON.stringify(payload));

    // setBulkMenuDivLoader(true);
    await uploadExcelData((payload));
  };


  const uploadExcelData = async (payload) => {
    // return
    setBulkMenuDivLoader(true);
    console.log("upload excel payload",payload)

    try {
      const responseData = await prodDataLogin(BULK_NFPS_ADD, payload);

      setBulkMenuDivLoader(false);
      // return
    if (responseData.statusCode === 200) {
      if (responseData.message === 'success') {
        setSuccess('Registration successful');
        setTimeout(() => {
          navigate('/Login');
        }, 1000);
      } else if (responseData.message === 'fail') {
        setError(responseData.info || 'NFP registration failed');
      } else {
        setError('Unknown error occurred');
      //   ExceptionSendService('NFPReg', 'nfpReg-101', responseData.info);
      }
    } else if (responseData.statusCode === 500) {
      setError(responseData.info || 'Internal server error');
      // ExceptionSendService('NFPReg', 'nfpReg-102', responseData.info);
    } else {
      console.log("no response", responseData.info)
      setError('Network Slow');
      // ExceptionSendService('NFPReg', 'nfpReg-103', 'No Response');
    }
    setLoading(false);
  } catch (error) {
    setBulkMenuDivLoader(false);
    console.error('Network Error:', error);
    setError('Network Slow or Internet Connection Problem');
  //   ExceptionSendService('NFPReg', 'nfpReg-104', String(error));
  }
  };

  return {
    loading,
    error,
    success,
    nfpData,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedCategory,
    setSelectedCategory,
    handleCategoryChange,
    handleItemChange,
    handleChange,
    handleContactChange,
    handleServiceChange,
    handleAddOperatingHour,
    handleAddDonationHour,
    handleSubmit,
    setOperatingDay,
    setOperatingStartTime,
    setOperatingEndTime,
    setDonatingDay,
    setDonatingStartTime,
    setDonatingEndTime,
    operatingDay,
    operatingStartTime,
    operatingEndTime,
    donatingDay,
    donatingStartTime,
    donatingEndTime,
    setNfpCountryCode,
    setContactCountryCode,
    nfp_country_code,
    contact_country_code,
    timeError,
    dtimeErr,
    frOptions,
    operatingStartHour,
    setOperatingStartHour,
    operatingStartMinute,
    setOperatingStartMinute,
    operatingStartMeridiem,
    setOperatingStartMeridiem,
    operatingEndHour,
    setOperatingEndHour,
    operatingEndMinute,
    setOperatingEndMinute,
    operatingEndMeridiem,
    setOperatingEndMeridiem,
    
    programs,
    programName,
    setProgramName,
    programLatitude,
    setProgramLatitude,
    programLongitude,
    setProgramLongitude,
    handleAddProgram,

    handleAddLocation,
    handleFileUpload,
    excelFileData,
    uploadingExcelData,
    bulkMenuDivLoader,
    DataFromEventEmitter,
  };
};

export default useProdNFPRegistrationController;
