// Models/OrganizationDetailsModel.js
export class NFPDashboardModel {
    constructor(
      name = '',
      address = '',
      nfp_phone = '',
      nfp_email = '',
      locationImage = '',
      availabilityStatus = '',
      operating_hrs_list = [],
      latitude = '',
      longitude = ''
    ) {
      this.name = name;
      this.address = address;
      this.nfp_phone = nfp_phone;
      this.nfp_email = nfp_email;
      this.locationImage = locationImage;
      this.availabilityStatus = availabilityStatus;
      this.operating_hrs_list = operating_hrs_list;
      this.latitude = latitude;
      this.longitude = longitude;
    }
  }
  