import { useState, useEffect } from 'react';
import { postDataLogin } from '../../ApiServices/ApiService';
import { INVENTORY_UPDATE } from '../../ApiServices/BaseURL';
import { NFPInventoryUpdateModel } from '../Models/NFPInventoryUpdateModel';

const useNFPInventoryUpdateController = (organizationName, navigate, item, setIsDropdownOpen, category) => {
    const parseAvailabilityTime = (availabilityTime) => {
        if (typeof availabilityTime === 'string') {
            try {
                return JSON.parse(availabilityTime.replace(/'/g, '"'));
            } catch (error) {
                console.error('Error parsing availability_time:', error);
                return [];
            }
        }
        return availabilityTime || [];
    };

    const initialData = {
        ...item,
        availability_time: parseAvailabilityTime(item?.availability_time),
    };

    const [nfpData, setNfpData] = useState(initialData);

    // Properly initialize formData based on category
    const [formData, setFormData] = useState(() => {
        if (category?.toLowerCase() === 'medical' || category?.toLowerCase() === 'jobs & training') {
            return {
                programName: item?.program_name || '',
                description: item?.description || '',
                address: item?.address || '',
                link: item?.link || '',
            };
        } else {
            return {
                itemName: item?.item_name || '',
                itemQuantity: item?.qty ? item.qty.toString() : '',
            };
        }
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [operatingStartHour, setOperatingStartHour] = useState('');
    const [operatingStartMinute, setOperatingStartMinute] = useState('');
    const [operatingStartMeridiem, setOperatingStartMeridiem] = useState('AM');
    const [operatingEndHour, setOperatingEndHour] = useState('');
    const [operatingEndMinute, setOperatingEndMinute] = useState('');
    const [operatingEndMeridiem, setOperatingEndMeridiem] = useState('PM');
    const [operatingDay, setOperatingDay] = useState('');

    const [editIndex, setEditIndex] = useState(null);

    // Ensure formData is updated whenever item or category changes
    useEffect(() => {
        if (item) {
            if (category?.toLowerCase() === 'medical' || category?.toLowerCase() === 'jobs & training') {
                setFormData({
                    programName: item.program_name || '',
                    description: item.description || '',
                    address: item.address || '',
                    link: item.link || '',
                });
            } else {
                setFormData({
                    itemName: item.item_name || '',
                    itemQuantity: item.qty ? item.qty.toString() : '',
                });
            }
        }
    }, [item, category]);

    const convertTo24HourFormat = (hour, minute, meridiem) => {
        let formattedHour = parseInt(hour, 10);
        const formattedMinute = parseInt(minute, 10);

        if (meridiem === 'PM' && formattedHour !== 12) {
            formattedHour += 12;
        } else if (meridiem === 'AM' && formattedHour === 12) {
            formattedHour = 0;
        }

        return formattedHour * 60 + formattedMinute; // Return total minutes since midnight
    };

    const parseTime = (time) => {
        const [timePart, meridiem] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);
        if (meridiem === 'PM' && hours < 12) hours += 12;
        if (meridiem === 'AM' && hours === 12) hours = 0;
        return hours * 60 + minutes;
    };

    const checkOverlap = (start1, end1, start2, end2) => {
        const startTime1 = parseTime(start1);
        const endTime1 = parseTime(end1);
        const startTime2 = parseTime(start2);
        const endTime2 = parseTime(end2);

        console.log(`Checking overlap between ${start1} - ${end1} and ${start2} - ${end2}`);

        return startTime1 < endTime2 && startTime2 < endTime1;
    };

    const handleAddOperatingHour = () => {
        setError('');
        const formattedStartTime = `${operatingStartHour}:${operatingStartMinute} ${operatingStartMeridiem}`;
        const formattedEndTime = `${operatingEndHour}:${operatingEndMinute} ${operatingEndMeridiem}`;

        if (!operatingDay || !operatingStartHour || !operatingStartMinute || !operatingEndHour || !operatingEndMinute) {
            setError('Please provide valid day and timings.');
            return;
        }

        const start = convertTo24HourFormat(operatingStartHour, operatingStartMinute, operatingStartMeridiem);
        const end = convertTo24HourFormat(operatingEndHour, operatingEndMinute, operatingEndMeridiem);

        if (end <= start) {
            setError('Start time must be earlier than end time.');
            return;
        }

        const isDuplicate = nfpData.availability_time.some((hour, index) => {
            if (editIndex !== null && index === editIndex) return false; // Skip the check for the currently edited item
            return hour.day === operatingDay && checkOverlap(hour.start_time, hour.end_time, formattedStartTime, formattedEndTime);
        });

        if (isDuplicate) {
            setError('Duplicate entry.');
            return;
        }

        const newOperatingHours = {
            day: operatingDay,
            start_time: formattedStartTime,
            end_time: formattedEndTime,
        };

        setNfpData((prevData) => {
            const updatedAvailabilityTimes = [...prevData.availability_time];
            if (editIndex !== null) {
                updatedAvailabilityTimes[editIndex] = newOperatingHours;
            } else {
                updatedAvailabilityTimes.push(newOperatingHours);
            }
            return { ...prevData, availability_time: updatedAvailabilityTimes };
        });

        // Reset states after adding/editing
        setOperatingDay('');
        setOperatingStartHour('');
        setOperatingStartMinute('');
        setOperatingEndHour('');
        setOperatingEndMinute('');
        setOperatingStartMeridiem('AM');
        setOperatingEndMeridiem('PM');
        setIsDropdownOpen(false);
        setEditIndex(null); // Reset edit index
    };

    const updateInventoryItem = async () => {
        console.log("formData", formData)
        setError('');
        setSuccess('');

        try {
            const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
            const admindetailsParsed = JSON.parse(admindetails);
            const NFP_ID = admindetailsParsed?.nfp_id;
            const email = admindetailsParsed?.email_id;
            const locationId = admindetailsParsed?.loc_id;
            const programId = admindetailsParsed?.program_id  

            const formatDateToYYYYMMDDHHMMSS = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };

            const now = new Date();
            const formattedDate = formatDateToYYYYMMDDHHMMSS(now);

            // Validation
            if (category?.toLowerCase() === 'medical' || category?.toLowerCase() === 'jobs & training') {
                if (!formData.programName) {
                    setError('Program Name Is Required');
                    return;
                }
                if (!formData.description) {
                    setError('Description Is Required');
                    return;
                }
            } else {
                if (!formData.itemName) {
                    setError('Item Name Is Required');
                    return;
                }
                if (!formData.itemQuantity) {
                    setError('Item Quantity Is Required');
                    return;
                }
            }

            setLoading(true);
            const payload = {
                "nfp_id": NFP_ID,
                "item_name": category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? "NA" : formData.itemName,
                "qty": category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' ? "0" : formData.itemQuantity,
                "last_updated": formattedDate,
                "last_updated_by": email,
                "category": category,
                "loc_id":locationId,
                "program_id":programId,
                ...(category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training') && {
                    "program_name": formData.programName,
                    "description": formData.description,
                    "availability_time": nfpData.availability_time,
                    "address": formData.address,
                    "link": formData.link || "",
                }
            };
            console.log("Inventory Update payload", payload);
            // return
            const response = await postDataLogin(INVENTORY_UPDATE, payload);
            const responseData =  response;

            if (responseData.statusCode === 200) {
                if (responseData.message === 'success') {
                    setSuccess(responseData.info);
                    setTimeout(() => {
                        navigate('/NFPInventoryList');  // Replace with your actual route
                    }, 2000);
                } else {
                    setError(responseData.info || 'Bad request. Please check the request parameters.');
                    // ExceptionSendService('NFPInventoryUpdateScreen', 'EditInv-101', response.data.info);
                }
            } else if (responseData.statusCode === 400) {
                setError(responseData.info || 'Bad request. Please check the request parameters.');
                // ExceptionSendService('NFPInventoryUpdateScreen', 'EditInv-105', response.data.info);
            } else if (responseData.statusCode === 500) {
                setError(responseData.info || 'Internal server error. Please try again later.');
                // ExceptionSendService('NFPInventoryUpdateScreen', 'EditInv-102', response.data.info);
            } else {
                setError('Error While Getting Response, Please Contact Admin');
                // ExceptionSendService('NFPInventoryUpdateScreen', 'EditInv-103', 'Error While Getting Response, Please Contact Admin');
            }
        } catch (error) {
            setError(error.message || 'Failed to update item. Please try again later.');
            console.error('Error updating item', error);
            // ExceptionSendService('NFPInventoryUpdateScreen', 'EditInv-104', String(error));
        } finally {
            setLoading(false);
        }
    };

    const handleEditOperatingHour = (index) => {
        const hour = nfpData.availability_time[index];
        const [startTime, startMeridiem] = hour.start_time.split(' ');
        const [endTime, endMeridiem] = hour.end_time.split(' ');

        const [startHour, startMinute] = startTime.split(':');
        const [endHour, endMinute] = endTime.split(':');

        setOperatingDay(hour.day);
        setOperatingStartHour(startHour);
        setOperatingStartMinute(startMinute);
        setOperatingStartMeridiem(startMeridiem);
        setOperatingEndHour(endHour);
        setOperatingEndMinute(endMinute);
        setOperatingEndMeridiem(endMeridiem);
        setIsDropdownOpen(true); // Open the dropdown
        setEditIndex(index);
    };

    const handleRemoveOperatingHour = (index) => {
        setNfpData((prevData) => ({
            ...prevData,
            availability_time: prevData.availability_time.filter((_, i) => i !== index),
        }));
    };

    const handleInputChange = (name, value) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    return {
        formData,
        handleInputChange,
        updateInventoryItem,
        loading,
        error,
        success,
        filteredData,
        searchText,
        setSearchText,
        setFormData,
        handleAddOperatingHour,
        setOperatingStartHour,
        operatingStartMinute,
        setOperatingStartMinute,
        operatingStartMeridiem,
        setOperatingStartMeridiem,
        operatingEndHour,
        setOperatingEndHour,
        operatingEndMinute,
        setOperatingEndMinute,
        operatingDay,
        setOperatingDay,
        setOperatingEndMeridiem,
        operatingEndMeridiem,
        operatingStartHour,
        nfpData,
        setNfpData,
        handleEditOperatingHour,
        handleRemoveOperatingHour
    };
};

export default useNFPInventoryUpdateController;
