import './App.css';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import LoginView from './MVCPages/Views/AuthenticationPages/LoginView';
import ForgotPasswordScreen from './MVCPages/Views/AuthenticationPages/ForgotPasswordScreen';
import ResetPasswordScreen from './MVCPages/Views/AuthenticationPages/ResetPasswordScreen';
import ChangePasswordScreen from './MVCPages/Views/AuthenticationPages/ChangePasswordScreen';

import NFPRegistrationScreen from './MVCPages/Views/AuthenticationPages/NFPRegistrationView';
import NFPRegistrationViewNew from './MVCPages/Views/AuthenticationPages/NFPRegistrationViewNew';

import NFPDashboardScreen from './MVCPages/Views/NFPDashboardScreen';
import NFPInventoryListScreen from './MVCPages/Views/NFPInventoryListScreen';
import NFPInventoryAddScreen from './MVCPages/Views/NFPInventoryAddScreen';
import NFPInventoryUpdateScreen from './MVCPages/Views/NFPInventoryUpdateScreen';
import DonationListScreen from './MVCPages/Views/DonationListScreen';
import NFPSuperAdminScreen from './MVCPages/Views/NFPSuperAdminScreen';
import FeedBackScreen from './MVCPages/Views/FeedBackScreen';
import UserRegistrationScreen from './MVCPages/Views/UserRegistrationScreen';
import Privacy from './Components/Privacy';
import Support from './Components/Support';
import ProdNFPRegistrationScreen from './MVCPages/Views/AuthenticationPages/ProdNFPRegistrationScreen';
import NFPBaneerRegistrationScreen from './MVCPages/Views/NFPBaneerRegistrationScreen';
import NFPBannerListScreen from'./MVCPages/Views/NFPBannersListScreen';
import NFPBulkRegistration from './MVCPages/Views/NFPBulkRegistrationNew';


import Protected from './utils/Protected';


function App() {
  return (
   <HashRouter>
    <Routes>
    <Route path="/" element={<Protected Component={LoginView} />} />
    <Route path="/login" element={<LoginView />} />
    <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
    <Route path="/reset-password" element={<ResetPasswordScreen />} />
    <Route path="/change-password" element={<ChangePasswordScreen />} />

    <Route path="/registration" element={<NFPRegistrationViewNew />} />

    <Route path="/dashboard" element={<Protected Component={NFPDashboardScreen} />} />
    <Route path="/NFPInventoryList" element={<NFPInventoryListScreen />} />
    <Route path="/NFPInventoryAdd" element={<NFPInventoryAddScreen />} />
    <Route path="/NFPInventoryUpdate" element={<NFPInventoryUpdateScreen />} />
    <Route path="/DonationList" element={<DonationListScreen />} />
    <Route path="/superadmin-dashboard" element={<NFPSuperAdminScreen />} />
    <Route path="/feedback-list" element={<FeedBackScreen />} />
    <Route path="/user-registration" element={<UserRegistrationScreen />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/support" element={<Support />} />
    <Route path="/prod-registration" element={<ProdNFPRegistrationScreen/>} />
    <Route path="/banner-registration" element={<NFPBaneerRegistrationScreen/>} />
    <Route path="/banner-list" element={<NFPBannerListScreen/>} />
    <Route path="/bulk-upload" element={<NFPBulkRegistration/>} />
    </Routes>

   </HashRouter>
  );
}

export default App;
