// src/Components/Inputs/TextArea.js
import React from 'react';
import '../App.css';

const TextArea = ({ placeholder, style, right, value, onChange, ...props }) => {
  return (
    <div className="input-container2" style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        className="input-field"
        placeholder={placeholder}
        style={{ ...style }}
        value={value || ''} // Ensure value is a string or an empty string
        onChange={onChange}
        {...props}
      />
      {right && <div className="right-element">{right}</div>}
    </div>
  );
};

export default TextArea;
