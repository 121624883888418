
import React from 'react';
import '../App.css';

const Privacy = () => {
  return (
    <div className='nfp-registration-container'>
    <div className="privacy-container">
      <h2>Privacy Policy for Farever App</h2>
      <p><strong>Effective Date:</strong> 08-21-2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to the Farever App. We respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app.</p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> When you create an account, we may collect personal information such as your name, email address, phone number, and location.</li>
        <li><strong>Usage Data:</strong> We may collect information about your interactions with the app, including IP addresses, browser type, app usage patterns, and other diagnostic data.</li>
        <li><strong>Cookies:</strong> We use cookies and similar tracking technologies to monitor activity on our app and store certain information.</li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>To provide, maintain, and improve the Farever App.</li>
        <li>To manage your account and provide customer support.</li>
        <li>To process donations and facilitate interactions between users and NFPs.</li>
        <li>To send you notifications, updates, and promotional materials.</li>
        <li>To monitor and analyze usage and trends to improve the user experience.</li>
        <li>To comply with legal obligations and protect our rights.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <ul>
        <li><strong>NFPs:</strong> To facilitate the services provided through the app, such as donating goods or finding shelters.</li>
        <li><strong>Service Providers:</strong> Third-party companies that help us operate the app, such as payment processors and hosting providers.</li>
        <li><strong>Legal Authorities:</strong> If required by law or to protect our rights, we may disclose your information to law enforcement or other government agencies.</li>
      </ul>

      <h2>5. Data Security</h2>
      <p>We implement appropriate security measures to protect your personal information. However, please be aware that no method of transmission over the internet or method of electronic storage is completely secure.</p>

      <h2>6. Your Rights</h2>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of receiving promotional communications.</li>
        <li>Request a copy of the data we have about you.</li>
      </ul>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Effective Date" will be updated accordingly.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:don@freshrevenues.com">don@freshrevenues.com</a>.</p>

      <h2>Terms & Conditions for Farever App</h2>
      <p><strong>Effective Date:</strong> 08-21-2024</p>

      <h2>1. Introduction</h2>
      <p>Welcome to the Farever App. By downloading or using the Farever App, you agree to comply with these Terms & Conditions. If you do not agree with these terms, please do not use the app.</p>

      <h2>2. Use of the App</h2>
      <ul>
        <li><strong>Eligibility:</strong> You must be at least 18 years old to use the Farever App.</li>
        <li><strong>Account Registration:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
        <li><strong>Prohibited Activities:</strong> You agree not to use the app for any unlawful or unauthorized purpose, including but not limited to the distribution of harmful content or the violation of intellectual property rights.</li>
      </ul>

      <h2>3. Donations</h2>
      <ul>
        <li><strong>NFPs:</strong> The app facilitates donations to registered NFPs. We do not control or endorse any NFPs and are not responsible for the use of your donations.</li>
        <li><strong>Payment Processing:</strong> All payments made through the app are processed by third-party payment processors. We do not store your payment information.</li>
      </ul>

      <h2>4. User Content</h2>
      <ul>
        <li><strong>Content Submission:</strong> By submitting content through the app, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, and distribute your content.</li>
        <li><strong>Prohibited Content:</strong> You agree not to submit content that is illegal, offensive, or infringes on the rights of others.</li>
      </ul>

      <h2>5. Intellectual Property</h2>
      <ul>
        <li><strong>Ownership:</strong> The Farever App, including its content, features, and functionality, is owned by Farever Company and is protected by intellectual property laws.</li>
        <li><strong>Trademarks:</strong> The Farever name and logo are trademarks of Farever Company. You may not use these trademarks without our prior written consent.</li>
      </ul>

      <h2>6. Disclaimers and Limitation of Liability</h2>
      <ul>
        <li><strong>No Warranty:</strong> The app is provided "as is" and "as available" without warranties of any kind, either express or implied.</li>
        <li><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Farever Company shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the app.</li>
      </ul>

      <h2>7. Termination</h2>
      <p>We reserve the right to terminate or suspend your account at any time, without notice, for conduct that we believe violates these Terms & Conditions or is harmful to other users or the app.</p>

      <h2>8. Governing Law</h2>
      <p>These Terms & Conditions are governed by the laws of Memphis,TN,USA. Any disputes arising out of or in connection with these terms shall be resolved exclusively in the courts of Memphis,TN,USA.</p>

      <h2>9. Changes to These Terms</h2>
      <p>We may update these Terms & Conditions from time to time. Any changes will be posted on this page, and the "Effective Date" will be updated accordingly.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions about these Terms & Conditions, please contact us at <a href="mailto:don@freshrevenues.com">don@freshrevenues.com</a>.</p>
    </div>
    </div>
  );
};

export default Privacy;
