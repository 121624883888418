import { useState, useEffect} from 'react';
// import ExceptionSendService from '../../ApiServices/ExceptionSendService';
import NFPInventoryListModel from '../Models/NFPInventoryListModel';
import { INVENTORY_LIST } from '../../ApiServices/BaseURL';
import { postDataLogin, getData } from '../../ApiServices/ApiService';

const useNFPInventoryListController = () => {
    const [groupedItems, setGroupedItems] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchInventoryData = async () => {
        setError('');
        setLoading(true);

        try {
            const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
            const admindetailsParsed = JSON.parse(admindetails);
            const NFP_ID = admindetailsParsed?.nfp_id;

            const userIDAuth = admindetailsParsed?.user_id || '';

            const response = await getData(`${INVENTORY_LIST}?nfp_id=${NFP_ID}&user_id_auth=${userIDAuth}`);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.message === 'success') {
                    if (responseData?.dataJ?.length > 0) {
                        const items = responseData.dataJ.map(item => new NFPInventoryListModel(
                            item.item_name, 
                            item.category, 
                            item.qty, 
                            item.program_name, 
                            item.description, 
                            item.address, 
                            item.link,
                            item.availability_time,
                            item.sub_category                            
                        ));
                        const groupedData = items.reduce((acc, item) => {
                            if (!acc[item.category]) acc[item.category] = [];
                            acc[item.category].push(item);
                            return acc;
                        }, {});
                        setGroupedItems(groupedData);
                    } else {
                        setError('No Inventory Data Found.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-101', responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
            } else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
                // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-500', responseData?.info || 'Internal Server Error');
            } else {
                setError('Unexpected error occurred. Please try again later.');
                // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-999', `Unexpected error: ${response?.status}`);
            }
        } catch (error) {
            if (error.response) {
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch inventory data. Please try again.'}`);
                // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-104', `Error: ${error.response.status}. ${String(error)}`);
            } else if (error.request) {
                setError('No response received from the server. Please check your network connection and try again.');
                // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-103', 'No response received from the server.');
            } else {
                // Something happened in setting up the request
                setError(`Failed to fetch inventory data: ${String(error)}`);
                // ExceptionSendService('NFPInventoryListScreen', 'NFPInvenList-102', `Failed to fetch inventory data: ${String(error)}`);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInventoryData();
    }, []);

    return { groupedItems, loading, error };
};

export default useNFPInventoryListController;
