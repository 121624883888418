import { useState, useEffect } from 'react';
import { postDataLogin } from '../../ApiServices/ApiService';
import { DONATION_LIST_API } from '../../ApiServices/BaseURL';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';

const useDonationListController = (nfpId, dateType, fromDate, toDate) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [donations, setDonations] = useState({});

  useEffect(() => {
    if (nfpId) {
      fetchDonations(nfpId, dateType, fromDate, toDate);
    }
  }, [nfpId, fromDate, toDate]);

  const fetchDonations = async (nfpId, dateType, fromDate, toDate) => {
    try {
      const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
      const admindetailsParsed = JSON.parse(admindetails);
      const userIDAuth = admindetailsParsed?.user_id || '';

      setLoading(true);
      setError('');
      setDonations({});
      const payload = {
        nfp_id: nfpId,
        date_type: dateType,
        from_date: fromDate,
        to_date: toDate,
        user_id_auth : userIDAuth
      };
      // console.log("payload", payload)
      const response = await postDataLogin(DONATION_LIST_API, payload);
    //   const response = responseData?.data;
      if (response.statusCode === 200) {
        if (response.message.toLowerCase() === 'success') {
          const donationList = response.dataJ[0];
          setDonations(donationList);
        } else {
          setError(response.info);
        //   ExceptionSendService('DonationListScreen', 'fetchDonations-101', response.info);
        }
      } else if (response.statusCode === 500) {
        setError(response.info);
        // ExceptionSendService('DonationListScreen', 'fetchDonations-102', response.info);
      } else {
        setError('Error While Getting Response, Please Contact Admin');
        // ExceptionSendService('DonationListScreen', 'fetchDonations-103', 'Error While Getting Response, Please Contact Admin');
      }
    } catch (error) {
      setError('No Internet Connection Detected Or Connection Is Slow !');
    //   ExceptionSendService('DonationListScreen', 'fetchDonations-104', String(error));
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    donations,
    fetchDonations,
    setError,
  };
};

export default useDonationListController;
