// models/BannerModel.js

export class NFPBannerRegistrationModel {
    constructor(bannerData) {
      this.op = "bannerAdd";
      this.banner_id = this.generateBannerId();
      this.nfp_id = bannerData.nfp;
      this.loc_id = bannerData.loc_id || "";
      this.program_id = bannerData.program_id || "";
      this.city = bannerData.city || "hyderabad";
      this.title = bannerData.title;
      this.description = bannerData.description;
      this.active = 1;
    }
  
    // Method to generate a unique banner ID
    generateBannerId() {
      return `br-${Date.now()}`;
    }
  
    toJSON() {
      return {
        op: this.op,
        banner_id: this.banner_id,
        nfp_id: this.nfp_id,
        loc_id: this.loc_id,
        program_id: this.program_id,
        city: this.city,
        title: this.title,
        description: this.description,
        active :this.active

      };
    }
  }
  