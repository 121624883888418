import React, { useState, useMemo } from 'react';
import '../App.css';

const Calendar = ({ onSelectDate, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];

  const isScreenSmall = window.innerWidth < 620;

  const totalDays = useMemo(() => daysInMonth(currentMonth, currentYear), [currentMonth, currentYear]);
  const firstDay = useMemo(() => firstDayOfMonth(currentMonth, currentYear), [currentMonth, currentYear]);

  function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  function firstDayOfMonth(month, year) {
    return new Date(year, month, 1).getDay();
  }

  const daysArray = [];
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Render day names
  const dayHeaders = dayNames.map((dayName, index) => (
    <div key={index} className="day-header">{dayName}</div>
  ));

  // Render days
  for (let i = 1; i <= totalDays + firstDay; i++) {
    if (i <= firstDay) {
      daysArray.push(null);
    } else {
      daysArray.push(i - firstDay);
    }
  }

  const calendarDays = daysArray.map((day, index) => {
    const isSelected = selectedDate.getDate() === day && selectedDate.getMonth() === currentMonth && selectedDate.getFullYear() === currentYear;
    return (
      <div
        key={index}
        className={`day ${isSelected ? 'selected-day' : ''}`}
        onClick={() => handleDateSelection(day)}
      >
        {day}
      </div>
    );
  });

  const handleDateSelection = async (day) => {
    const selected = new Date(currentYear, currentMonth, day);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // if (selected < currentDate) return;

    setSelectedDate(selected);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedFormatted = selected.toLocaleDateString('en-US', {
      timeZone: userTimeZone,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const formattedDate = await formatDate(selectedFormatted);
    onSelectDate(formattedDate);
  };

  const formatDate = (dateString) => {
    return new Promise((resolve, reject) => {
      try {
        const parts = dateString.split('/');
        if (parts.length === 3) {
          const [month, day, year] = parts;
          const formattedDate = `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year}`;
          resolve(formattedDate);
        } else {
          reject(new Error("Invalid date format"));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(prevMonth => (prevMonth === 0 ? 11 : prevMonth - 1));
    setCurrentYear(prevYear => currentMonth === 0 ? prevYear - 1 : prevYear);
  };

  const goToNextMonth = () => {
    setCurrentMonth(prevMonth => (prevMonth === 11 ? 0 : prevMonth + 1));
    setCurrentYear(prevYear => currentMonth === 11 ? prevYear + 1 : prevYear);
  };

  return (
    <div className={`container-calender ${isScreenSmall ? 'small' : 'large'}`}>
      <div className="header">
        <div className="month-control">
          <div className="arrow-button" onClick={goToPreviousMonth}>
            <img className="arrow-icon" src={require('../Assets/arrow-left.png')} alt="Previous Month" />
          </div>
          <div className="header-text">{`${monthNames[currentMonth]} ${currentYear}`}</div>
          <div className="arrow-button" onClick={goToNextMonth}>
            <img className="arrow-icon" src={require('../Assets/arrow-right.png')} alt="Next Month" style={{height:'130%',width:'130%'}} />
          </div>
        </div>
        <div className="close-button" onClick={onClose}>
          <img className="close-icon" src={require('../Assets/close.png')} alt="Close" />
        </div>
      </div>
      <div className="day-headers">{dayHeaders}</div>
      <div className="calendar">{calendarDays}</div>
    </div>
  );
};

export default Calendar;
