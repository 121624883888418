import React from 'react';
import useChangePasswordController from '../../Controllers/AuthenticationPages/ChangePasswordController';
import Loader from '../../../Components/Loader';
import TextArea from '../../../Components/LoginInput';
// import './ChangePasswordScreen.css';

const ChangePasswordScreen = () => {
  const {
    email,
    tempPassword,
    newPassword,
    confirmPassword,
    error,
    showPassword,
    showConfirmPassword,
    loading,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    toggleShowPassword,
    toggleShowConfirmPassword,
    changePassword,
  } = useChangePasswordController();

  return (
    <div className="safe-area2">
      <div className="container">
        <img
          src="https://assets.api.uizard.io/api/cdn/stream/ade0f9fc-17ba-4731-a16a-724ba2bbb048.png"
          alt="Logo"
          className="image"
          style={{ height: 200, width: 200 }}
        />
        <h3 className="modal-title">Change Password</h3>
        <div className="input-container">
          <TextArea
            placeholder="Email"
            value={email}
            readOnly
            className="input"
            style={{marginBottom: '10px',maxWidth:'350px'}}
          />
          <TextArea
            placeholder="Temporary Password"
            value={tempPassword}
            readOnly
            className="input"
            style={{marginBottom: '10px',maxWidth:'350px'}}
          />
          <TextArea
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => handleNewPasswordChange(e.target.value)}
            type={showPassword ? "text" : "password"}
            className="input"
            style={{marginBottom: '10px',maxWidth:'350px'}}
            right={
              <button onClick={toggleShowPassword} className='toggle-button'>
                {showPassword ? (
                  <img src={require('../../../Assets/remove-eye.png')} alt="Hide " style={{backgroundColor:'white'}}/>
                ) : (
                  <img src={require('../../../Assets/eye.png')} alt="Show " />
                )}
              </button>
            }
          />
          <TextArea
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            type={showConfirmPassword ? "text" : "password"}
            className="input"
            style={{marginBottom: '10px',maxWidth:'350px'}}
            right={
              <button onClick={toggleShowConfirmPassword} className='toggle-button'>
              {showConfirmPassword ? (
                <img src={require('../../../Assets/remove-eye.png')} alt="Hide " />
              ) : (
                <img src={require('../../../Assets/eye.png')} alt="Show " />
              )}
            </button>
            }
          />
          {error && <p className="error-text">{error}</p>}
        </div>
        <button className="submit-button" onClick={changePassword} style={{ maxWidth: 50, marginLeft: 10,background:'#4474b1', }}>
          {loading ? "Loading..." : "Submit"}
        </button>
        <Loader loading={loading}  color={'#FFFFFF'}/>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
