import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useNFPInventoryUpdateController from '../Controllers/NFPInventoryUpdateController';
import HeaderNavBar from '../../Components/HeaderNavBar';
import TimeDropdown from '../../Components/TimeDropdown';
import CustomDropdown from '../../Components/CustomDropdown';
import Loader from '../../Components/Loader';
import arrowRight from '../../Assets/right-arrow.png';
import circularArrow from '../../Assets/icons/left-arrow-in-circular-icon.png';

const NFPInventoryUpdateScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { item, organizationName, category } = location.state || {}; // Access passed state here

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    formData,
    handleInputChange,
    updateInventoryItem,
    loading,
    error,
    success,
    filteredData,
    searchText,
    setSearchText,
    setFormData,
    operatingStartHour,
    setOperatingStartHour,
    operatingStartMinute,
    setOperatingStartMinute,
    operatingEndHour,
    setOperatingEndHour,
    operatingEndMinute,
    setOperatingEndMinute,
    setOperatingStartMeridiem,
    setOperatingEndMeridiem,
    operatingStartMeridiem,
    operatingEndMeridiem,
    operatingDay,
    setOperatingDay,
    handleAddOperatingHour,
    nfpData,
    handleEditOperatingHour,
    handleRemoveOperatingHour
  } = useNFPInventoryUpdateController(organizationName, navigate, item, setIsDropdownOpen, category);

  useEffect(() => {
    if (item) {
      if (category.toLowerCase() === 'medical' || category.toLowerCase() === 'jobs & training' || category.toLowerCase() === 'services') {
        setFormData({
          programName: item.program_name,
          description: item.description,
          address: item.address,
          link: item.link
        });
      } else {
        setFormData({ itemName: item.item_name, itemQuantity: item.qty.toString() });
      }
    }
  }, [item, category, setFormData]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const displayText = operatingStartHour && operatingEndHour
    ? `${operatingDay || 'Day'}: ${operatingStartHour || 'Start Time'}:${operatingStartMinute || '00'} ${operatingStartMeridiem || 'AM'} - ${operatingEndHour || 'End Time'}:${operatingEndMinute || '00'} ${operatingEndMeridiem || 'PM'}`
    : 'Select Day and Time';

  const renderInputItemsRow = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '45px' }}>
        <div style={{ flexBasis: '40.66%', marginRight: '10px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Item Name</label>
          <input
            style={{
              width: '100%',
              height: '40px',
              borderRadius: '8px',
              padding: '0 20px',
              backgroundColor: '#fff',
              marginTop: '5px'  // Add margin-top to align with the button
            }}
            placeholder="Search Item Name"
            value={formData.itemName}
            onChange={(e) => {
              handleInputChange('itemName', e.target.value);
              setSearchText(e.target.value);
            }}
            type="text"
          />
        </div>
        <div style={{ flexBasis: '13.66%', marginRight: '10px' }}>
          <label style={{ display: 'block', marginBottom: '5px' }}>Quantity</label>
          <input
            style={{
              width: '100%',
              height: '40px',
              borderRadius: '8px',
              padding: '0 20px',
              backgroundColor: '#fff',
              marginTop: '5px'  // Add margin-top to align with the button
            }}
            placeholder="Item Quantity"
            value={formData.itemQuantity}
            onChange={(e) => handleInputChange('itemQuantity', e.target.value)}
            type="number"
          />
        </div>
        <div style={{ flexBasis: '16.66%', marginTop: '32px' }}>
          <button
            onClick={updateInventoryItem}
            disabled={loading}
            style={{
              width: '100%',
              padding: '12px',
              fontSize: '14px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <span>Update Item</span>
            {loading && <Loader color="#fff" loading={loading} style={{ marginLeft: '8px' }} />}
          </button>
        </div>
      </div>
    );
  };

  const renderInputItem = (item) => {
    if (item.type === 'availability') {
      return (
        <div className="input-container" key="availability">
          <label className="label">Availability Hours</label>
          <div className="dropdown-header-ci" onClick={toggleDropdown} style={{ width: '90%' }}>
            <span className="selected-time-text-ci">
              {displayText}
            </span>
            <img
              src={require('../../Assets/arrow-up.png')}
              className={`arrow-icon-ci ${isDropdownOpen ? 'rotate-icon-ci' : ''}`}
              alt="Toggle Dropdown"
            />
          </div>
          {isDropdownOpen && (
            <div className="dropdown-content-ci" style={{ width: '80%' }}>
              <div className="dropdown-item-ci">
                <label className="label">Day</label>
                <CustomDropdown
                  selectedValue={operatingDay}
                  onValueChange={setOperatingDay}
                  options={[
                    { id: 'Mon', label: 'Mon', value: 'Mon' },
                    { id: 'Tue', label: 'Tue', value: 'Tue' },
                    { id: 'Wed', label: 'Wed', value: 'Wed' },
                    { id: 'Thu', label: 'Thu', value: 'Thu' },
                    { id: 'Fri', label: 'Fri', value: 'Fri' },
                    { id: 'Sat', label: 'Sat', value: 'Sat' },
                    { id: 'Sun', label: 'Sun', value: 'Sun' },
                  ]}
                  placeholder="Select Day"
                />
              </div>
              <div className="dropdown-item-ci">
                <label className="label">Start Time</label>
                <TimeDropdown
                  selectedHour={operatingStartHour}
                  selectedMinute={operatingStartMinute}
                  selectedMeridiem={operatingStartMeridiem}
                  onHourChange={setOperatingStartHour}
                  onMinuteChange={setOperatingStartMinute}
                  onMeridiemChange={setOperatingStartMeridiem}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="dropdown-item-ci">
                <label className="label">End Time</label>
                <TimeDropdown
                  selectedHour={operatingEndHour}
                  selectedMinute={operatingEndMinute}
                  selectedMeridiem={operatingEndMeridiem}
                  onHourChange={setOperatingEndHour}
                  onMinuteChange={setOperatingEndMinute}
                  onMeridiemChange={setOperatingEndMeridiem}
                  style={{ width: '100%' }}
                />
              </div>
              <button className="add-button" onClick={handleAddOperatingHour}>Add Day</button>
            </div>
          )}
          {nfpData?.availability_time.length > 0 && (
            <div>
              {nfpData.availability_time.map((item, index) => (
                <div key={index} className="hour-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <span className="hour-text">{item.day} : {item.start_time} To {item.end_time}</span>
                  <div className="hour-buttons">
                    <button onClick={() => handleEditOperatingHour(index)} className="edit-button">
                      <img src={require('../../Assets/edit.png')} alt="Edit" className="close-icon" />
                    </button>
                    <button onClick={() => handleRemoveOperatingHour(index)} className="remove-button">
                      <img src={require('../../Assets/close.png')} alt="Remove" className="close-icon" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else if (item.type === 'input') {
      return (
        <div className="input-container" key={item.name}>
          <label className="label">{item.label}:</label>
          <input
            className="input-field"
            type={item.keyboardType || 'text'}
            placeholder={item.placeholder}
            value={formData[item.name] || ''}
            onChange={(e) => handleInputChange(item.name, e.target.value)}
            style={{ width: '100%', height: '40px', borderRadius: '8px', padding: '0 20px', marginBottom: '10px' }}
          />
        </div>
      );
    }
    return null;
  };

  const data = [
    { type: 'input', name: 'itemName', placeholder: 'Search Item Name', label: 'Item Name' },
    { type: 'input', name: 'itemQuantity', placeholder: 'Item Quantity', keyboardType: 'numeric', label: 'Item Quantity' }
  ];

  const medicalData = [
    { type: 'input', name: 'programName', placeholder: 'Program Name', label: 'Program Name' },
    { type: 'input', name: 'description', placeholder: 'Description', label: 'Description' },
    { type: 'input', name: 'address', placeholder: 'Address', label: 'Address' },
    { type: 'input', name: 'link', placeholder: 'Link', label: 'Link' },
    { type: 'availability' }
  ];

  return (
    <>
      <HeaderNavBar />
      <div className="safe-area">
        <div className="container">
          <div className="header-container" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <img
              src={circularArrow}
              alt="Back"
              style={{
                cursor: 'pointer',
                height: '25px',
                width: '25px',
                marginRight: '15px',
              }}
              onClick={() => navigate('/NFPInventoryList')}
            />
            <h2 className="category-title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Inventory Item Update</h2>
          </div>

          {(category?.toLowerCase() === 'medical' || category?.toLowerCase() === 'jobs & training' || category?.toLowerCase() === 'services') ? (
            <>
              {medicalData.map((item, index) => (
                <React.Fragment key={index}>
                  {renderInputItem(item)}
                </React.Fragment>
              ))}
              {/* Add the Update button here for medical/jobs & training/services */}
              <div style={{ marginTop: '20px' }}>
                <button
                  onClick={updateInventoryItem}
                  disabled={loading}
                  style={{
                    width: '100%',
                    padding: '12px',
                    fontSize: '14px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span>Update Item</span>
                  {loading && <Loader color="#fff" loading={loading} style={{ marginLeft: '8px' }} />}
                </button>
              </div>
            </>
          ) : (
            <>
              {renderInputItemsRow()}
            </>
          )}
          <div className="footer">
            {error && <span className="error-text">{error}</span>}
            {success && <span className="success-text">{success}</span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default NFPInventoryUpdateScreen;
