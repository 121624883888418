// views/NFPPage/NFPBannerRegistrationScreen.js
import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import useNFPBannerRegistrationController from '../Controllers/NFPBannerRegistrationController';
import Loader from '../../Components/Loader';
// import HeaderNavBar from '../../Components/HeaderNavBar';
import SAHeaderNavBar from '../../Components/SAHeaderNavBar';
import Footer from '../../Components/Footer';
import circularArrow from '../../Assets/icons/left-arrow-in-circular-icon.png';

const NFPBaneerRegistrationScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    loading,
    error,
    activeNFPs,
    handleBannerSubmit,
    handleBannerUpdate,
    success
  } = useNFPBannerRegistrationController();

  const [isEditMode, setIsEditMode] = useState(false);
  const [bannerData, setBannerData] = useState({
    nfp_id: '',
    title: '',
    description: '',
    loc_id: '',
    program_id: '',
    city: 'hyderabad',
  });

  useEffect(() => {
    if (state?.banner) {
      setBannerData(state.banner);
      setIsEditMode(true);
    }
  }, [state]);

  const handleBannerChange = (field, value) => {
    setBannerData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const onSubmit = () => {
    if (isEditMode) {
      handleBannerUpdate(bannerData, setBannerData);
    } else {
      handleBannerSubmit(bannerData, setBannerData);
    }
  };

  return (
    <div>
      <SAHeaderNavBar />
      <div >
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '80vh',
          position: 'relative'
        }}>
          {loading && (
            <div style={{ 
              position: 'absolute', 
              top: 0, 
              left: 0, 
              right: 0, 
              bottom: 0, 
              display: 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10
            }}>
              <Loader loading={loading}  />
            </div>
          )}
          <div className="form-container" style={{ maxWidth: 400, width: '100%' }} >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', position: 'relative' }}>
                <img 
                src={circularArrow} 
                alt="Back" 
                style={{ 
                    width: '25px', 
                    height: '25px', 
                    position: 'absolute', 
                    left: 0, 
                    cursor: 'pointer' 
                }}
                onClick={() => navigate('/banner-list')}// Go back to the previous page
                />
                <h3 style={{ margin: 0 }}>
                {isEditMode ? 'Edit Banner' : 'Add Banner'}
                </h3>
            </div>
            <div className='flex-auto'>
              <label className="form-label">Select NFP :</label>
              <div className="input-group form-input">
                <select
                  value={bannerData.nfp_id}
                  onChange={(e) => handleBannerChange('nfp_id', e.target.value)}
                  className="form-select"
                  disabled={isEditMode} // Disable if in edit mode
                >
                  <option value="" disabled>Select an NFP</option>
                  {activeNFPs.map((nfp) => (
                    <option key={nfp.nfp_id} value={nfp.nfp_id}>
                      {nfp.organization_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row-display-flex">
              <div className="flex-auto">
                <label className="form-label">Title:</label>
                <input
                  className="form-input"
                  type="text"
                  placeholder="Enter Title"
                  value={bannerData.title}
                  onChange={(e) => handleBannerChange('title', e.target.value)}
                />
              </div>
            </div>
            <div className="row-display-flex">
              <div className="flex-auto">
                <label className="form-label">Description:</label>
                <input
                  className="form-input"
                  type="text"
                  placeholder="Enter Description"
                  value={bannerData.description}
                  onChange={(e) => handleBannerChange('description', e.target.value)}
                />
              </div>
            </div>
            <div className="form-buttons" style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className="submit-button"
                onClick={onSubmit}
                style={{ width: '150px', padding: '10px', fontSize: '16px', textAlign: 'center', borderRadius: '4px' }}
              >
                {isEditMode ? 'Update' : 'Submit'}
              </button>
            </div>
            {error && <p className="error-text">{error}</p>}
            {success && <p className="success-text">{success}</p>}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default NFPBaneerRegistrationScreen;
