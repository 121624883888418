export class NFPStatusModel {
    constructor(data) {
      this.organization_name = data.organization_name || '';
      this.address = data.address || '';
      this.nfp_id = data.nfp_id || '';
      this.loc_id = data.loc_id ||'';
      this.program_id = data.program_id
      // Add other fields as necessary
    }
  }
  