import { useState, useEffect,useContext } from 'react';
import { postDataLogin ,getData,postData } from '../../../ApiServices/ApiService';
import { REGISTRATION_API, FAR_OPTIONS_LIST, BULK_NFPS_ADD } from '../../../ApiServices/BaseURL';
import { validateEmail, TimeValidation } from '../../../utils/validation';
import { NFPRegistrationModel } from '../../Models/AuthenticationPages/NFPRegistrationModel';
import { useNavigate } from 'react-router-dom';
// import { ExceptionSendService } from '../../ApiServices/ExceptionSendService';
import { LocationContext } from '../../../Components/LocationContext';

const parseTime = (time) => {
  const [timePart, meridiem] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);
  if (meridiem === 'PM' && hours < 12) hours += 12;
  if (meridiem === 'AM' && hours === 12) hours = 0;
  return hours * 60 + minutes;
};

const validateAndFormatTime = (time, meridiem) => {
  const timeWithMeridiem = `${time} ${meridiem}`;
  const fullTimeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)$/i;

  console.log("timeWithMeridiem:", timeWithMeridiem); // Added logging
  console.log("fullTimeRegex:", fullTimeRegex); // Added logging

  if (fullTimeRegex.test(timeWithMeridiem)) {
    return timeWithMeridiem.toUpperCase();
  } else {
    console.log("Time is invalid:", timeWithMeridiem); // Added logging
    return false;
  }
};

const checkOverlap = (start1, end1, start2, end2) => {
  const startTime1 = parseTime(start1);
  const endTime1 = parseTime(end1);
  const startTime2 = parseTime(start2);
  const endTime2 = parseTime(end2);

  console.log(`Checking overlap between ${start1} - ${end1} and ${start2} - ${end2}`); // Added logging

  return startTime1 < endTime2 && startTime2 < endTime1;
};

const convertTo24HourFormat = (hour, minute, meridiem) => {
  let formattedHour = parseInt(hour, 10);
  const formattedMinute = parseInt(minute, 10);
  
  if (meridiem === 'PM' && formattedHour !== 12) {
    formattedHour += 12;
  } else if (meridiem === 'AM' && formattedHour === 12) {
    formattedHour = 0;
  }
  
  return formattedHour * 60 + formattedMinute; // Return total minutes since midnight
};

const useNFPRegistrationController = (setIsDropdownOpen, setIsFoodDropdownOpen,setSelectedLocId) => {
  
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categories, setCategories] = useState({});
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [operatingDay, setOperatingDay] = useState('');
  const [operatingStartTime, setOperatingStartTime] = useState('');
  const [operatingEndTime, setOperatingEndTime] = useState('');
  const [donatingDay, setDonatingDay] = useState('');
  const [donatingStartTime, setDonatingStartTime] = useState('');
  const [donatingEndTime, setDonatingEndTime] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [nfp_country_code, setNfpCountryCode] = useState('+1');
  const [contact_country_code, setContactCountryCode] = useState('+1');
  const [timeError, setTimeError] = useState('');
  const [dtimeErr, setdtimeError] = useState('');
  const [frOptions,setFrOptions] =useState({})
  const [operatingStartHour, setOperatingStartHour] = useState('');
  const [operatingStartMinute, setOperatingStartMinute] = useState('');
  const [operatingStartMeridiem, setOperatingStartMeridiem] = useState('AM');
  const [operatingEndHour, setOperatingEndHour] = useState('');
  const [operatingEndMinute, setOperatingEndMinute] = useState('');
  const [operatingEndMeridiem, setOperatingEndMeridiem] = useState('PM');
  const [programs, setPrograms] = useState([]);
  const [programName, setProgramName] = useState('');
  const [programLatitude, setProgramLatitude] = useState('');
  const [programLongitude, setProgramLongitude] = useState('');
  const [programActivities, setProgramActivities] = useState('');

  const [servingDay, setServingDay] = useState('');
  const [servingStartHour, setServingStartHour] = useState('');
  const [servingStartMinute, setServingStartMinute] = useState('');
  const [servingStartMeridiem, setServingStartMeridiem] = useState('AM');
  const [servingEndHour, setServingEndHour] = useState('');
  const [servingEndMinute, setServingEndMinute] = useState('');
  const [servingEndMeridiem, setServingEndMeridiem] = useState('PM');

  const [nfpData, setNfpData] = useState({
    organization_name: '',
    address: '',
    city: '',
    zipCode: '',
    contact_person_details: [{ contact_name: '', contact_email: '', contact_phone: '' }],
    nfp_phone: '',
    nfp_email: '',
    services_list: [],
    nfp_activites: '',
    operating_hrs_list: [],
    donating_hrs_list: [],
    volunteer_pos: '',
    volunteer_des: '',
    donation_link: '',
    bank_name: '',
    account_no: '',
    routing_no: '',
    registration_date: '',
    register_by: '',
    last_updated_at: '',
    fr_member_since: '',
    nfp_tz: "",
    latitude:'',
    longitude:'',
    nfp_loc_list: [],
    food_serving_hours:[]
  });
  const { location } = useContext(LocationContext);
  const [excelFileData, setExcelFileData] = useState([]);
  const [uploadingExcelData, setUploadingExcelData] = useState([]);
  const [bulkMenuDivLoader, setBulkMenuDivLoader] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({
    nfpName: '',
    zipCode: '',
    nfpPhone: '',
    registerBy: '',
    location: '',
    program: '',
    address: '',
    city: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    donationLink: '',
    programLatitude: '',
    programLongitude: '',
    mainFunctionSpecifics: '',
    operatingHours: '',
    servingHours: '',
    locationName:'',
    locationAddress:'',
    locationCity:'',
    programNameRef:'',
    programLatRef:'',
    programLongRef:'',
    programOperatingHoursRef:'',
    programMainSpecificRef:'',
    programContactName:'',
    programContactEmail:'',
    programContactCellRef:'',
    programServicesRef:'',
    selectLocationRef:''
    // Add other specific field errors as needed...
});

  
  const handleAddProgram = (loc_id) => {
    console.log("programActivities in add program", programActivities);
    

    if (!loc_id) {
      setError("Please Select A Location Before Adding A Program.")
        return false; // Stop further validation
    }

    if (!programName) {
      setError("Program Name Is Required.")
        return false; // Stop further validation
    }

    if (!programLatitude) {
      setError('Program Latitude Is Required.')
        return false; // Stop further validation
    }

    if (!programLongitude) {
      setError('Program Longitude Is Required.')
        return false; // Stop further validation
    }

    // if (!nfpData.services_list.length) {
    //     errors.programServices = 'At least one service must be selected.';
    //     setFieldErrors(errors);
    //     programServicesRef.current.scrollIntoView({ behavior: 'smooth',block: 'center' });
    //     return false; // Stop further validation
    // }

    // if (!nfpData.operating_hrs_list.length) {
    //     errors.programOperatingHours = 'Operating Hours are required.';
    //     setFieldErrors(errors);
    //     programOperatingHoursRef.current.scrollIntoView({ behavior: 'smooth',block: 'center' });
    //     return false; // Stop further validation
    // }

    if (!nfpData.contact_person_details[0].contact_name) {
      setError('Contact Person Name Is Required.')
        return false; // Stop further validation
    }

    if (!nfpData.contact_person_details[0].contact_email) {
      setError('Contact Person Email Is Required.')
        return false; // Stop further validation
    }

    if (!nfpData.contact_person_details[0].contact_phone) {
      setError('Contact Person Phone Is Required.')
        return false; // Stop further validation
    }

    // if (!programActivities) {
    //     errors.programMainSpecific = 'Main Function Specifics are required.';
    //     setFieldErrors(errors);
    //     programMainSpecificRef.current.scrollIntoView({ behavior: 'smooth',block: 'center' });
    //     return false; // Stop further validation
    // }

    // If no errors, clear the error messages
  
    const timestamp = Date.now();
    const newProgramId = `prog-${timestamp}`;
    
    const newProgram = {
      id: newProgramId,
      program_name: programName,
      latitude: programLatitude,
      longitude: programLongitude,
      loc_id,
      nfp_activities: programActivities, 
      services_list: [...nfpData.services_list],
      operating_hrs_list: [...nfpData.operating_hrs_list], 
      contact_person_details: [...nfpData.contact_person_details],
    };
  
    setPrograms((prevPrograms) => {
      const updatedPrograms = [...prevPrograms, newProgram];
      console.log("programs after addition", updatedPrograms); // Confirm that programActivities is correctly added
      return updatedPrograms;
    });
  
    // Clear the inputs after adding
    setProgramName('');
    setProgramLatitude('');
    setProgramLongitude('');
    handleChange('services_list', []);
    handleChange('operating_hrs_list', []);
    handleChange('contact_person_details', [{ contact_name: '', contact_email: '', contact_phone: '' }]);
    handleChange('nfp_activites', '');

  
    // Delay resetting programActivities to ensure it's used in the payload
      setProgramActivities('');
    
  
    setSelectedLocId('');
    setOperatingDay('');
    setOperatingStartHour('');
    setOperatingStartMinute('');
    setOperatingEndHour('');
    setOperatingEndMinute('');
    setOperatingEndMeridiem('PM');
    setOperatingStartMeridiem('AM')
    setError('');
  };
  
  
  

  const handleAddLocation = () => {
    const { location_name, address, city, nfp_loc_list } = nfpData;
    if (!nfpData.location_name) {
      setError("Location Name Is required")
      return false; // Stop further validation
  }

  if (!nfpData.city) {
    setError("City Name Is Required");
      return false; // Stop further validation
  }

  if (!nfpData.address) {
        setError("Address Name Is Required");
      return false; // Stop further validation
  }
  
    if (location_name && address && city) {
      const timestamp = Date.now();
      const loc_id = `loc-${timestamp}`;
  
      const newLocation = {
        id: loc_id,
        loc_id,
        location_name,
        address,
        city,
        nfp_id: '', 
        donating_hrs_list: []
      };
      
      setNfpData(prevData => ({
        ...prevData,
        nfp_loc_list: [...prevData.nfp_loc_list, newLocation],
        location_name: '', 
        address: '',
        city: ''
      }));
    } 
    setError('')
    // else {
    //   setError('Please provide location name, address, and city.');
    // }
  };


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // console.log('fetchCategories res get Data:');return
        const payload ={
          op: "far_opt_list"
        }
        setLoading(true);
        await postData(FAR_OPTIONS_LIST,payload).then(resp =>{
            resp.json().then(respData => {
                // console.log('fetchCategories res Data:',respData);return
                const data = respData?.dataJ[0];
                delete data?.Roles; // Remove Roles category
                delete data?.Emergency;
                setCategories(data);
                setFrOptions(data);
                setLoading(false);
            })

        });
       
      } catch (error) {
        setError('Failed to fetch categories');
        setLoading(false);
      }
    };
    // const fetchLocation = async () => {
    //   try {
    //     const lat = 17.4482647 //await localStorage.getItem('latitude');
    //     const lon = 78.3879964 //await localStorage.getItem('longitude');
    //     setLatitude(lat);
    //     setLongitude(lon);
    //   } catch (error) {
    //     console.error('Failed to fetch location:', error);
    //   }
    // };

    fetchCategories();
    // fetchLocation();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [category]: prevSelectedCategories[category] || [],
    }));
    setSelectedCategory(category); // Show the newly selected category's items
  };

  const handleItemChange = (category, item) => {
    setSelectedCategories((prevSelectedCategories) => {
      const categoryItems = prevSelectedCategories[category] || [];
      const itemExists = categoryItems.some((i) => i.id === item.service_id);
      const updatedItems = itemExists
        ? categoryItems.filter((i) => i.id !== item.service_id)
        : [...categoryItems, { id: item.service_id, name: item.service_name }]; // Include service_name
      return { ...prevSelectedCategories, [category]: updatedItems };
    });
  };

  const handleChange = (field, value) => {
    setNfpData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  
    // if (field === 'nfp_activites') {
    //   setPrograms((prevPrograms) => prevPrograms.map((program) => ({
    //     ...program,
    //     nfp_activities: value,
    //   })));
    // }
  };

  const handleContactChange = (field, value) => {
    setNfpData((prevData) => ({
      ...prevData,
      contact_person_details: [{
        ...prevData.contact_person_details[0],
        [field]: value,
      }],
    }));
  };

  const handleServiceChange = (service) => {
    console.log('servie:',service)
    setNfpData((prevData) => {
      const services = prevData.services_list.includes(service)
        ? prevData.services_list.filter((s) => s !== service)
        : [...prevData.services_list, service];
      return { ...prevData, services_list: services };
    });
  };

  // const [operatingDay, setOperatingDay] = useState([]); // Now an array to hold multiple selected days

  const handleDaySelection = (day, checked) => {
    if (day === 'All') {
      if (checked) {
        setOperatingDay(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
      } else {
        setOperatingDay([]);
      }
    } else {
      if (checked) {
        setOperatingDay(prev => [...prev, day]);
      } else {
        setOperatingDay(prev => prev.filter(d => d !== day));
      }
    }
  };
  
  const handleAddOperatingHour = () => {
    setTimeError('');
    
    const formattedStartTime = `${operatingStartHour}:${operatingStartMinute} ${operatingStartMeridiem}`;
    const formattedEndTime = `${operatingEndHour}:${operatingEndMinute} ${operatingEndMeridiem}`;
  
    if (!operatingDay.length || !operatingStartHour || !operatingStartMinute || !operatingEndHour || !operatingEndMinute) {
      setTimeError('Please provide valid day(s) and timings.');
      return;
    }
  
    const start = convertTo24HourFormat(operatingStartHour, operatingStartMinute, operatingStartMeridiem);
    const end = convertTo24HourFormat(operatingEndHour, operatingEndMinute, operatingEndMeridiem);
  
    if (end <= start) {
      setTimeError('Start time must be earlier than end time.');
      return;
    }
  
    const newOperatingHours = operatingDay.map(day => ({
      day,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
    }));
  
    setNfpData((prevData) => ({
      ...prevData,
      operating_hrs_list: [
        ...prevData.operating_hrs_list,
        ...newOperatingHours,
      ],
    }));
  
    setIsDropdownOpen(false);
    setOperatingDay([]); // Clear selected days
  };
  

  const handleDaySelectionForFood = (day, checked) => {
    if (day === 'All') {
      if (checked) {
        setServingDay(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);
      } else {
        setServingDay([]);
      }
    } else {
      if (checked) {
        setServingDay(prev => [...prev, day]);
      } else {
        setServingDay(prev => prev.filter(d => d !== day));
      }
    }
  };
  
  const handleAddServingHour = () => {
    setTimeError('');
    
    const formattedStartTime = `${servingStartHour}:${servingStartMinute} ${servingStartMeridiem}`;
    const formattedEndTime = `${servingEndHour}:${servingEndMinute} ${servingEndMeridiem}`;
  
    if (!servingDay.length || !servingStartHour || !servingStartMinute || !servingEndHour || !servingEndMinute) {
      setTimeError('Please provide valid day(s) and timings.');
      return;
    }
  
    const start = convertTo24HourFormat(servingStartHour, servingStartMinute, servingStartMeridiem);
    const end = convertTo24HourFormat(servingEndHour, servingEndMinute, servingEndMeridiem);
  
    if (end <= start) {
      setTimeError('Start time must be earlier than end time.');
      return;
    }
  
    const newServingHours = servingDay.map(day => ({
      day,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
    }));
  
    setNfpData((prevData) => ({
      ...prevData,
      food_serving_hours: [
        ...prevData.food_serving_hours,
        ...newServingHours,
      ],
    }));
  
    setIsFoodDropdownOpen(false);
    setServingDay([]); // Clear selected days
  };
  

  const handleAddDonationHour = () => {
    setdtimeError('')
    if (donatingStartTime === "" || donatingEndTime === "" || donatingDay === "") {
      setdtimeError('Please provide the day, start time, and end time');
      return
    }
    if (!TimeValidation(donatingStartTime, donatingEndTime)) {
      setdtimeError('Ensure times are within 24 hours and end time is after start time');
      return;
    }
    setNfpData((prevData) => ({
      ...prevData,
      donating_hrs_list: [
        ...prevData.donating_hrs_list,
        { day: donatingDay, start_time: donatingStartTime, end_time: donatingEndTime },
      ],
    }));
  };

  const validateNFPDetailsInputs = () => {

    if (!nfpData.organization_name) {
        setError("NFP Name Is Required")
        return false; // Stop further validation
    }

    if (!nfpData.zipCode) {
      setError("NFP ZipCode Is Required");
        return false; // Stop further validation
    }

    if (!nfpData.nfp_phone) {
          setError("NFP Phone Is Required");
        return false; // Stop further validation
    }

    if (!nfpData.register_by) {
          setError("NFP Registered By Is Required");
        return false; // Stop further validation
    }

    // // Ensure at least one location is added
    // if (nfpData.nfp_loc_list.length === 0) {
    //   setError('At Least One Location Must Be Added.');
    //     return false; // Stop further validation
    // }

    // // Ensure at least one program is added
    // if (programs.length === 0) {
    //   setError('At Least One Program Must Be Added.');
    //     return false; // Stop further validation
    // }
    return true
};

const validateLocationInputs = () => {

  // Ensure at least one location is added
  if (nfpData.nfp_loc_list.length === 0) {
    setError('At Least One Location Must Be Added.');
      return false; // Stop further validation
  }
  return true
};

const validateProgramInputs = () => {

  // Ensure at least one program is added
  if (programs.length === 0) {
    setError('At Least One Program Must Be Added.');
      return false; // Stop further validation
  }
  return true
};


  

  const handleSubmit = async (selectedLocId) => {
    console.log('Submitting form'); // Add log here
    setError('');
    setSuccess('');
    if (!validateNFPDetailsInputs()) {
      return;
    }

    const programsWithIds = programs.map((program) => {
      console.log("program before payload", program); // This should now show the correct nfp_activities
      const program_id = program.id;
      return {
        ...program,
        loc_id: program.loc_id,
        program_id: program_id,
      };
    });


    const nfpRegistrationModel = new NFPRegistrationModel(
      nfpData.organization_name,
      nfpData.nfp_phone,
      nfpData.donation_link,
      nfpData.fr_member_since,
      nfpData.register_by,
      nfpData.registration_date,
      nfpData.zipCode,
      nfpData.nfp_loc_list, // Pass the list of locations here
      programsWithIds,
      nfpData.food_serving_hours
    );
  
    const payload = nfpRegistrationModel.toNFPRegistrationPayload();
  
    // console.log('Payload:', JSON.stringify(payload, null, 2));

    console.log('Validating inputs'); // Add log here
    console.log('payload:', payload); // Add log here
    console.log("selectedLocId", selectedLocId)
    // return

    setLoading(true);
    try {
      const response = await postDataLogin(REGISTRATION_API, payload);
      const responseData = await response;
      console.log("response",response)
      if (responseData.statusCode === 200) {
        if (responseData.message === 'success') {
          setSuccess('Registration successful');
          setTimeout(() => {
            navigate('/Login');
          }, 3000);
        } else if (responseData.message === 'fail') {
          setError(responseData.info || 'NFP registration failed');
        } else {
          setError('Unknown error occurred');
        //   ExceptionSendService('NFPReg', 'nfpReg-101', responseData.info);
        }
      } else if (responseData.statusCode === 500) {
        setError(responseData.info || 'Internal server error');
        // ExceptionSendService('NFPReg', 'nfpReg-102', responseData.info);
      } else {
        console.log("no response", response.info)
        setError('Network Slow');
        // ExceptionSendService('NFPReg', 'nfpReg-103', 'No Response');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Network Error:', error);
      setError('Network Slow or Internet Connection Problem');
    //   ExceptionSendService('NFPReg', 'nfpReg-104', String(error));
    }

  };

  const handleFileUpload = async (file) => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Append the file to FormData

    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
  }

    return

    setLoading(true);
    try {
      const response = await postDataLogin('/api/nfp/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });

      const responseData = await response;

      if (responseData.statusCode === 200) {
        if (responseData.message === 'success') {
          setSuccess('File uploaded successfully!');
        } else {
          setError('File upload failed.');
        }
      } else if (responseData.statusCode === 500) {
        setError('Internal server error');
      } else {
        setError('Unexpected error occurred');
      }
    } catch (error) {
      setError('Network error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const DataFromEventEmitter = async (data) => {
    console.log("DataFromEventEmitter", data);

    if (data.length === 0) {
      alert("Unable to read the Excel, Please check the format and data.");
      return;
    }
    if (data.length > 500) {
      alert("Maximum 500 records allowed.");
      return;
    }
    // if(data.loc_id === undefined){
    //   setError("loc_id is mandatory ,example loc-1");
    //   return
    // }if(data.program_id === undefined){
    //   setError("program_id is mandatory ,example prog-1");
    //   return
    // }
    for (let i = 0; i < data.length; i++) {
      if (data[i].organization_name === undefined || data[i].organization_name.trim() === '') {
        setError(`Row ${i + 1}: Please provide organization name`);
        return;
      }
      if (data[i].contact_email === undefined || data[i].contact_email.trim() === '') {
        setError(`Row ${i + 1}: Please provide contact email`);
        return;
      }
    }
  

    const payload = {
      "nfpjson_list": data,
    };

    console.log("Payload:", JSON.stringify(payload));

    // setBulkMenuDivLoader(true);
    await uploadExcelData((payload));
  };


  const uploadExcelData = async (payload) => {
    // return
    setBulkMenuDivLoader(true);
    console.log("upload excel payload",payload)

    try {
      const responseData = await postDataLogin(BULK_NFPS_ADD, payload);

      setBulkMenuDivLoader(false);
      // return
    if (responseData.statusCode === 200) {
      if (responseData.message === 'success') {
        setSuccess('Registration successful');
        setTimeout(() => {
          navigate('/Login');
        }, 1000);
      } else if (responseData.message === 'fail') {
        setError(responseData.info || 'NFP registration failed');
      } else {
        setError('Unknown error occurred');
      //   ExceptionSendService('NFPReg', 'nfpReg-101', responseData.info);
      }
    } else if (responseData.statusCode === 500) {
      setError(responseData.info || 'Internal server error');
      // ExceptionSendService('NFPReg', 'nfpReg-102', responseData.info);
    } else {
      console.log("no response", responseData.info)
      setError('Network Slow');
      // ExceptionSendService('NFPReg', 'nfpReg-103', 'No Response');
    }
    setLoading(false);
  } catch (error) {
    setBulkMenuDivLoader(false);
    console.error('Network Error:', error);
    setError('Network Slow or Internet Connection Problem');
  //   ExceptionSendService('NFPReg', 'nfpReg-104', String(error));
  }
  };

  return {
    loading,
    setError,
    error,
    success,
    nfpData,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedCategory,
    setSelectedCategory,
    handleCategoryChange,
    handleItemChange,
    handleChange,
    handleContactChange,
    handleServiceChange,
    handleAddOperatingHour,
    handleAddDonationHour,
    handleSubmit,
    setOperatingDay,
    setOperatingStartTime,
    setOperatingEndTime,
    setDonatingDay,
    setDonatingStartTime,
    setDonatingEndTime,
    operatingDay,
    operatingStartTime,
    operatingEndTime,
    donatingDay,
    donatingStartTime,
    donatingEndTime,
    setNfpCountryCode,
    setContactCountryCode,
    nfp_country_code,
    contact_country_code,
    timeError,
    dtimeErr,
    frOptions,
    operatingStartHour,
    setOperatingStartHour,
    operatingStartMinute,
    setOperatingStartMinute,
    operatingStartMeridiem,
    setOperatingStartMeridiem,
    operatingEndHour,
    setOperatingEndHour,
    operatingEndMinute,
    setOperatingEndMinute,
    operatingEndMeridiem,
    setOperatingEndMeridiem,
    
    programs,
    programName,
    setProgramName,
    programLatitude,
    setProgramLatitude,
    programLongitude,
    setProgramLongitude,
    handleAddProgram,
    setProgramActivities,
    programActivities,

    handleAddLocation,
    handleFileUpload,
    excelFileData,
    uploadingExcelData,
    bulkMenuDivLoader,
    DataFromEventEmitter,

    handleAddServingHour,
    servingDay,
    setServingDay,
    servingStartHour,
    setServingStartHour,
    servingStartMinute,
    setServingStartMinute,
    servingStartMeridiem,
    setServingStartMeridiem,
    servingEndHour,
    setServingEndHour,
    servingEndMinute,
    setServingEndMinute,
    servingEndMeridiem,
    setServingEndMeridiem,

    handleDaySelection,
    handleDaySelectionForFood,

    fieldErrors,
    setFieldErrors,
    validateNFPDetailsInputs,
    validateLocationInputs,
    validateProgramInputs
  };
};

export default useNFPRegistrationController;
