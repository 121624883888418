// models/NFPInventoryListModel.js
class NFPInventoryListModel{
    constructor(item_name, category, qty,program_name,description,address,info_link,availability_time,sub_category
        ) {
        this.item_name = item_name;
        this.category = category;
        this.qty = qty;
        this.program_name=program_name;
        this.description= description;
        this.address = address;
        this.info_link = info_link;
        this.availability_time = availability_time
        this.sub_category = sub_category
        
        
    }
}
export default NFPInventoryListModel;
