import React from 'react';
import '../App.css';

const Loader = (value) => {
  // console.log('color:', value)
  return (
    <>
    {value.loading &&
      <div className="loader-fixed">
        <div className="spinner" style={{ borderTopColor: value.color ? value.color : '#007bff' }}></div>
      </div>
      }
    </>
  );
};

export default Loader;
