import React from 'react';
import Loader from '../../Components/Loader';
import useUserRegistrationController from '../Controllers/UserRegistrationController';
import HeaderNavBar from '../../Components/HeaderNavBar';
import Footer from '../../Components/Footer';

const UserRegistrationScreen = ({ navigation }) => {
  const {
    loading,
    error,
    success,
    userData,
    handleChange,
    handleRolesOption,
    handleSubmit,
  } = useUserRegistrationController(navigation);

  const handleNumber = (event) => {
    if (event.target.value?.length === 10) {
      event.preventDefault();
    }
  }

  const useRoleOptions = [
    { id: '1', label: 'Admin', value: 'admin' },
    { id: '2', label: 'User', value: 'user' },
  ];

  return (
    <>
      <HeaderNavBar />
      <div className="form-container-user" style={{marginTop:'15px'}}>
        <div>
          <h4 className="title-headings">User Registration</h4>
        </div>

        <div style={{ marginBottom: '10px' }}>
          {/* User Name and Email in a Single Row */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>User Name <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Enter User Name"
                value={userData.user_name}
                onChange={(e) => handleChange('user_name', e.target.value)}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>

            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Email ID <span style={{ color: 'red' }}>*</span></label>
              <input
                type="email"
                placeholder="Enter Email ID"
                value={userData.email_id}
                onChange={(e) => handleChange('email_id', e.target.value)}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
          </div>

          {/* Phone and User Role in a Single Row */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>User Phone <span style={{ color: 'red' }}>*</span></label>
              <input
                type="number"
                placeholder="Enter User Phone"
                value={userData.user_phone}
                onChange={(e) => handleChange('user_phone', e.target.value)}
                maxLength={10}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>

            <div style={{ width: '51%',marginRight:'-20px' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>User Role</label>
              <select
                value={userData.type}
                onChange={handleRolesOption}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              >
                <option value="" disabled>Select a role</option>
                {useRoleOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Address 1 <span style={{ color: 'red' }}>*</span></label>
            <input
              type="text"
              placeholder="Enter Address Line 1"
              value={userData.user_address}
              onChange={(e) => handleChange('user_address', e.target.value)}
              style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Address 2</label>
            <input
              type="text"
              placeholder="Enter Address Line 2"
              value={userData.user_address2}
              onChange={(e) => handleChange('user_address2', e.target.value)}
              style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>City <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Enter City"
                value={userData.user_city}
                onChange={(e) => handleChange('user_city', e.target.value)}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>

            <div style={{ width: '48%' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontSize: '14px' }}>Zip Code <span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Zip Code"
                value={userData.zip_code}
                onChange={(e) => handleChange('zip_code', e.target.value)}
                maxLength={6}
                style={{ width: '100%', padding: '10px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc' }}
              />
            </div>
          </div>

          {loading && <Loader loading={loading} color={'#FFFFFF'} />}
          {error && <p style={{ color: 'red', fontSize: '14px', textAlign: 'center' }}>{error}</p>}
          {success && <p style={{ color: 'green', fontSize: '14px', textAlign: 'center' }}>{success}</p>}

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserRegistrationScreen;
