import { useState, useEffect,useContext } from 'react';
import { postDataLogin, getData, postData } from '../../ApiServices/ApiService';
import { SERVICE_REGISTRATION_API, OPPORTUNITIES_API, INVENTORY_LIST, NFP_DETAILS_API } from '../../ApiServices/BaseURL';
import NFPInventoryAddModel from '../Models/NFPInventoryAddModel';
// import ExceptionSendService from '../../ApiServices/ExceptionSendService';
import { LocationContext } from '../../Components/LocationContext';

const useNFPInventoryAddController = (latitude, longitude) => {
  const { location } = useContext(LocationContext);
  console.log('loc :',location)
  const [formData, setFormData] = useState({
    itemName: '',
    itemQuantity: '',
    category: '',
    subCategory: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categories, setCategories] = useState({});
  const [existingItems, setExistingItems] = useState([]);
  const [cityName, setCityName] = useState('');
  const screenName = 'NFPInventoryAddScreen';

  const handleInputChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const formatDateToYYYYMMDDHHMMSS = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        const NFP_ID = admindetailsParsed?.nfp_id;
        const payload ={
          "nfp_id":NFP_ID
        }        
        const response = await postData(NFP_DETAILS_API,payload);
        // const response = await getData(OPPORTUNITIES_API);
        const res = await response.json();
        const data = res.dataJ[0];
        delete data.Others; // Remove Roles category
        console.log("categories", data);
        setCategories(data);
        setLoading(false);
      } catch (error) {
        setError('Failed To Fetch Categories');
        setLoading(false);
      }
    };

    const fetchExistingItems = async () => {
      setError('');
      setLoading(true);

      try {
        const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
        const admindetailsParsed = JSON.parse(admindetails);
        const NFP_ID = admindetailsParsed?.nfp_id;
        
        const response = await getData(`${INVENTORY_LIST}?nfp_id=${NFP_ID}`);
        const responseData = await response.json();

        if (response?.status === 200) {
          if (responseData?.message === 'success') {
            setExistingItems(responseData.dataJ);
          } else {
            // setError(responseData?.info || 'Failed to fetch existing items. Please try again.');
            // ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-101', responseData?.info || 'Failed to fetch existing items. Please try again.');
          }
        } else if (response?.status === 500) {
          setError(responseData?.info);
        //   ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-102', responseData?.info);
        } else {
          setError('Error While Getting Response,Please Contact Admin');
        //   ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-103', 'Error While Getting Response,Please Contact Admin');
        }
      } catch (error) {
        console.log('fatch error:',error)
        if (error.response) {
          setError('No Internet Connection Detected Or Connection Is Slow !');
        //   ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-104', `Error: ${error.response.status}. ${String(error)}`);
        } else if (error.request) {
          setError('No Internet Connection Detected Or Connection Is Slow !!');
        //   ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-104-1', 'No response received from the server.');
        } else {
          // Something happened in setting up the request
          setError('No Internet Connection Detected Or Connection Is Slow !!!');
        //   ExceptionSendService('NFPInventoryAddScreen', 'NFPInvenAdd-104-2', `Failed to fetch existing items: ${String(error)}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchExistingItems();
  }, []);

  const addItemToInventory = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    // Check for required fields and set errors if missing
    const isSpecialCategory = formData.category === 'Medical' || formData.category === 'Jobs & Training';
    if (isSpecialCategory) {
      if (!formData.program_name) {
        setError('Program Name Is Required.');
        setLoading(false);
        return;
      }
      if (!formData.availability_time) {
        setError('Availability Time Is Required.');
        setLoading(false);
        return;
      }
      if (!formData.description) {
        setError('Description Is Required.');
        setLoading(false);
        return;
      }
      if (!formData.address) {
        setError('Address Is Required.');
        setLoading(false);
        return;
      }
    } else {
      if (!formData.itemName) {
        setError('Item Name Is Required.');
        setLoading(false);
        return;
      }
      if (!formData.itemQuantity) {
        setError('Item Quantity Is Required.');
        setLoading(false);
        return;
      }
    }

  if (!formData.category) {
    setError('Category Is Required.');
    setLoading(false);
    return;
  }

  // if (!formData.subCategory) {
  //   setError('Subcategory Is Required.');
  //   setLoading(false);
  //   return;
  // }

  // Check for duplicate items
  if (!isSpecialCategory) {
    const isDuplicate = existingItems.some(item =>
      item.item_name.toLowerCase() === formData.itemName.toLowerCase() &&
      item.category.toLowerCase() === formData.category.toLowerCase() &&
      item.sub_category.toLowerCase() === formData.subCategory.toLowerCase()
    );

    if (isDuplicate) {
      setError('Item already exists in the inventory.');
      setLoading(false);
      return;
    }
  }
    try {
      const admindetails = localStorage.getItem('_FAREVER_ADMIN_',{});
      const admindetailsParsed = JSON.parse(admindetails);
      const email = admindetailsParsed?.email_id;
      const NFP_ID = admindetailsParsed?.nfp_id;
      const latitudeNumber = admindetailsParsed?.latitude;
      const longitudeNumber = admindetailsParsed?.longitude;
      const locationId = admindetailsParsed?.loc_id;
      const programId = admindetailsParsed?.program_id     

      const payload = new NFPInventoryAddModel(
        "putitem",
        NFP_ID,
        formData.itemName,
        formData.itemQuantity,
        'NA', // size
        formData.category, // category
        formData.subCategory, // sub_category
        formatDateToYYYYMMDDHHMMSS(new Date()), // last_updated
        1, // availability
        email, // last_updated_by
        'short_term', // options
        latitudeNumber, // latitude
        longitudeNumber ,// longitude
        formData.program_name,
        formData.availability_time,
        formData.address,
        formData.description,
        1, // active camp
        formData.info_link,
        locationId,
        programId
      );
      console.log("Add Inventory Payload", payload);
      // return

      const response = await postDataLogin(SERVICE_REGISTRATION_API, payload);
      const responseData =  response;
      console.log("Add Inventory responseData", responseData);
      if (response.statusCode === 200) {
        if (responseData.message === 'success') {
          console.log("Add inventory response", responseData);
          setSuccess(responseData.info);
          setExistingItems([...existingItems, {
            item_name: formData.itemName,
            category: formData.category,
            sub_category: formData.subCategory
          }]);
          // Only reset itemName and item Quantity after successful addition
          setFormData(prevState => ({
            ...prevState,
            itemName: '',
            itemQuantity: ''
          }));
        } else {
          setError(responseData.info);
        //   ExceptionSendService(screenName, 'NFPInvenAdd-101', responseData.info);
        }
      } else if (response.status === 400) {
        setError(responseData?.info || 'Bad request. Please check the request parameters.');
        // ExceptionSendService(screenName, 'NFPInvenAdd-105', responseData?.info || 'Bad request. Please check the request parameters.');
      } else if (response.status === 500) {
        setError(responseData?.info || 'Internal server error. Please try again later.');
        // ExceptionSendService(screenName, 'NFPInvenAdd-102', responseData?.info || 'Internal server error. Please try again later.');
      } else {
        setError('Error While Getting Response,Please Contact Admin');
        // ExceptionSendService(screenName, 'NFPInvenAdd-103', 'Error While Getting Response,Please Contact Admin');
      }
    } catch (error) {
      let err = String(error);
    //   ExceptionSendService(screenName, 'NFPInvenAdd-104', err);
      setError('No Internet Connection Detected Or Connection Is Slow !');
      console.error('No Internet Connection Detected Or Connection Is Slow !', error);
    } finally {
      setLoading(false);
    }
  };

  const updateLocation = (latitude, longitude) => {
    console.log('Updating location in Controller:', latitude, longitude);
  };

  const handleLocationError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleCityNameUpdate = (cityName) => {
    console.log(`City name updated: ${cityName}`);
    setCityName(cityName);
  };

  return {
    formData,
    handleInputChange,
    addItemToInventory,
    loading,
    error,
    success,
    setError,
    setSuccess,
    categories,
    updateLocation,
    handleLocationError,
    handleCityNameUpdate,
    cityName,
    setFormData
  };
};

export default useNFPInventoryAddController;
